import React from 'react'
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
const DownloadButton = ({ name, path, applink }) => {
const navigate = useNavigate();
  return (
    <Button className='font700 flexrow'
      sx={{
        backgroundColor: "#D12E29",
        color: '#FFFFFF',
        textTransform: 'none',
        height: { xs: '48px', sm: "36px", md: "48px" },
        width: { xs: '175px', sm: "140px", md: "175px" },
        
        lineHeight: '1.25',
        fontSize: { xs: '14px', sm: "12px", md: "16px", lg:"16px" },
        justifyContent: 'center',
        alignItems: 'cenyer',
        '&:hover': {
          backgroundColor: "#D12E29",
          borderColor: '#349F6E',
          color: '#349F6E',
          border: '1px'
        },
      }}
      onClick={() => {
        (path ? navigate(path) :
          window.open(`${applink}`, '_blank')
          // window.open(`https://play.google.com/store/apps/details?id=com.indialegal.user`, '_blank')
        )
      }
      }
    >
      {name}
    </Button>
  )
}

export default DownloadButton;
import React from 'react'
import { Box, Typography } from '@mui/material';
import Download from "./downloadButton";
import downArrow from "../assets/logo/arrow-circle-down.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation, } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
const heroSlider = ({heroContent}) => {
  return (
   <Box className="container">
      <Swiper
        spaceBetween={30}
        // loop={false}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay, ]}
        // navigation={true}
        className='mySwiper'
        autoplay={{ delay: 5000, disableOnInteraction: false, }}
        >
        {heroContent.map((el,i) => 
        <SwiperSlide key={`slide-${i}`}>
          <Box sx={{ maxWidth: '100%', mt: { lg: "40px", md: "32px", sm: '28px', xs: '24px' }, }}>
         
              <Box className='flexcol'
                sx={{
                  maxWidth: '100%',
                  justifyContent: 'center',
                  width: '100%',
                  minHeight: { lg: "570px", md: "484px", sm: '363px', xs: '363px' },
                  backgroundImage: `url(${el.url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: {lg:'center',md:'center',sm:'center',xs:'40% center',},
                  backgroundSize: 'cover',
                  borderRadius: '10px',
                  position: "relative"
                }}>
                {/* <Box className="downArrow"
                  sx={{
                    width: { lg: "64px", md: '48px', sm: '42px' },
                    height: { lg: "64px", md: '48px', sm: '42px' },
                    position: 'absolute',
                    left:'50%',
                    transform: 'translateX(-50%)',
                    top: '80%',
                    display: { xs: 'none', sm: 'block' }
                  }}>
                  <img className="imageSize" src={downArrow} alt='DownArrow' />
                </Box> */}
                <Box className='flexcol'
                  sx={{
                    // pt: '104px',
                    justifyItems: 'center',
                    maxWidth: { lg: '500px', md: '425px', sm: '320px', xs: '300px' },
                    pl: { lg: '38px', md: '28px', sm: '24px', xs: '20px' },
                    rowGap: { lg: '56px', md: '42px', sm: '28px', xs: '24px' },
                  }}>
                  <Box className="flexcol"
                    sx={{
                      rowGap: '16px',
                    }}>
                    <a href={el.link} style={{textDecoration:"none"}}>
                    <Typography className='font700 bannerHeading'> {el.hf} <span style={{ color: 'rgba(209, 46, 41, 1)' }}>
                    {el.hs} 
                    </span>.</Typography>
                    </a>
                    <Typography className='font400 p1'>
                     {el.p}</Typography>
                  </Box> 
                  <Box>
                     <Download name={'Download App'} 
                     applink= {'https://play.google.com/store/apps/details?id=com.indialegal.user'}
                     />
                  </Box>
                </Box>
              </Box>
            </Box>
       
        </SwiperSlide>)}
      </Swiper>
      </Box>
  )
}

export default heroSlider
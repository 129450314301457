import React from 'react'
import { Box, Typography } from '@mui/material';
import heroImage from "../../assets/image/SurakshaKavachHeroImg.webp";
import heroImageMob from "../../assets/image/SurakshaKavachHeroImgMob.webp";
import Download from '../../component/downloadButton';
import tick from "../../assets/logo/tick.svg";
import CTA from '../../component/cta';
import FAQ from '../../component/Faq';
import Testimonials from '../../component/testimonials';
import { faqQues, reviewSuraksha, benefits } from "./constant";

import Benefits from './component/benefits';
const SurakshaKavach = () => {
    return (
        <>
            {/* Hero Section */}
            < Box sx={{
                maxWidth: '100%',
                mt: { md: '150px', sm: '144px', xs: '72px' },
                mb: { lg: '232px', md: '180px', sm: '90px', xs: '32px' }
            }}>
                <Box className="container" sx={{ position: 'relative' }} >
                    <Box sx={{
                        position: 'absolute', right: '0px',
                        top: { lg: '-242px', md: '-172px', sm: "-129px" },
                        height: { lg: '1120px', md: '858px', sm: '653px' },
                        width: { lg: '800px', md: '568px', sm: '466px' },
                        display: { xs: 'none', sm: 'block' }
                    }}>
                        <img className="imageSize" src={heroImage} alt="hero-image" />
                    </Box>
                    <Box className="flexrow" sx={{
                        justifyContent: 'space-between',
                        flexDirection: {
                            lg: "row",
                            md: "row",
                            sm: "row",
                            xs: "column-reverse",
                        },
                        gap: { sm: "40px", xs: "32px" },
                    }}>
                        <Box className="flexcol"
                            sx={{
                                rowGap: '40px',
                                alignItems: 'flex-start',
                            }}>
                            <Box className="flexcol" sx={{
                                rowGap: '16px',
                                maxWidth: { md: '560px', sm: '400px' },
                                pt: { md: '40px', sm: '28px', xs: '0px' },
                                alignItems: 'flex-start',
                            }}>
                                <Box sx={{
                                    height: { lg: '622px', md: '467px', sm: '350px', xs: 'auto', },
                                    width: { sm: 'auto', xs: '100%' },
                                    maxWidth: "100%",
                                    display: { xs: "block", sm: 'none' },
                                    mx: 'auto'
                                }}>
                                    <img className='imageSize' src={heroImageMob} alt="Suraksha Kavach" />
                                </Box>
                                <Typography variant="h1" className='font700 bannerHeading'
                                >IL Suraksha Kavach</Typography>
                                <Typography className='font400 p1'>
                                    We are your protection! In addition to helping you understand basic legal concepts, we are there for you when life throws you a legal surprise. Your not the only one who has to deal with the legal issues that life throws you. We are here to assist you in making sure you are safe and ready. Sit back and relax and concentrate on your life and we can help protect you
                                </Typography>
                            </Box>
                            <Box className="flexcol"
                                sx={{
                                    rowGap: '80px',
                                    alignItems: 'flex-start'
                                }}>
                                <Box className="flexcol"
                                    sx={{
                                        rowGap: "16px",
                                    }}>
                                    <Box className="flexrow" sx={{ columnGap: '40px', flexDirection: { xs: 'column', sm: 'row' }, rowGap: "18px", }}>
                                        <Box className="flexrow" sx={{ columnGap: "10px", width: '152px', alignItems: "center" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Legal consultation
                                            </Typography>
                                        </Box>
                                        <Box className="flexrow" sx={{ columnGap: "10px" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Realtime Reports
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className="flexrow" sx={{ columnGap: '40px', flexDirection: { xs: 'column', sm: 'row' }, rowGap: "18px", }} >
                                        <Box className="flexrow" sx={{ columnGap: "10px", width: '152px', alignItems: "center" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Easy To Use
                                            </Typography>
                                        </Box>
                                        <Box className="flexrow" sx={{ columnGap: "10px" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                24x7 Call Support
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Download name={"Download App"}
                                    applink={'https://play.google.com/store/apps/details?id=com.indialegal.user'} />
                            </Box>
                        </Box>
                        {/* <Box sx={{
                            width: { md: '510px', sm: '382px', xs: "100%" },
                            height: { md: '449px', sm: '336px', xs: "auto" },
                            backgroundImage: `url(${SurakshaKavachHero})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center'
                            
                        }}>
                            <Box sx={{ position: 'absolute', top: '50px', right: { md: '72px', sm: '56px' }, maxWidth: { xs: '324px' } }}>
                            <img className="imageSize" style={{objectFit:'contain'}} src={SurakshaKavachHero} alt="surakshaKavach"  />
                       
                            </Box>

                        </Box> */}
                    </Box>
                </Box>
            </Box>

            {/* Benefits */}
            < Box sx={{ maxWidth: '100%', mb: '80px' }}>
                <Box className="container" >
                    <Box className="flexcol" sx={{ rowGap: { lg: '72px', md: '64px', sm: '48px', xs: '32px' }, mb: '40px', alignItems: 'center' }}>
                        <Box className="flexcol"
                            sx={{
                                rowGap: "16px",
                                alignItems: 'center'
                            }}>
                            <Typography className='font700 heading'>
                                Benefits of IL Suraksha Kavach
                            </Typography>
                            <Typography className='font400 p1' sx={{ textAlign: { sm: 'center' } }}>
                                Our India Legal Suraksha Kavach membership program offers you exclusive access to a range of benefits designed to enhance your legal protection. Subscribe and let us handle them for you.
                            </Typography>
                        </Box>

                        <Box className='flexrow' sx={{
                            rowGap: { md: '80px', sm: '48px', xs: '32px' },
                            columnGap: "60px",
                            px: { lg: "140px", xs: "0px" },
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}>
                            <Benefits benefits={benefits} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* testimonial Section */}
            {/* <Testimonials review={reviewSuraksha} /> */}
            {/* Faq */}
            <FAQ ques={faqQues} />
            {/* cta section */}
            <CTA user={true} appLink={"https://play.google.com/store/apps/details?id=com.indialegal.user"} />
        </>
    )
}

export default SurakshaKavach
export const faqBlogs = JSON.stringify([
  {
    id: "1",
    ques: "What is India Legal App? ",
    ans: "India Legal App offers free legal aid to economically weaker section. Along with customisable legal services for others. ",
  },
  {
    id: "2",
    ques: "Is India Legal App FREE? ",
    ans: "Yes, it is free App you can download it from Play Store and App Store. ",
  },
  {
    id: "3",
    ques: "What services India Legal App provides? ",
    ans: "We offer legal service that helps you create legal documents and get legal advice as fast as possible. These are the most popular services - Talk to Lawyer, Business and Contracts,Real Estate,Startup, Family and Personal.",
  },
  {
    id: "4",
    ques: "What is IL Suraksha Kavach? ",
    ans: "As the name says, IL Suraksha Kavach is an affordable legal protection plan of India Legal App. In this legal protection plan, we combine various legal services such as Free Consultation for 30 mins, Rent Agreements, Affidavits, Study Material for Law Students, Legal Magazine Access, Exclusive Video content, Exclusive Legal Talk and access to group events.",
  },
  {
    id: "5",
    ques: "What is SOS Service? ",
    ans: "SOS service is FREE legal dedicated helpline, working 24x7. Whenever you require any kind of legal help, our dedicated team is always available to help you",
  },
  {
    id: "6",
    ques: "What is Talk to Lawyer? ",
    ans: "On phone instant audio, video legal consultation from experienced advocates. It’s a simple process - book your mins, select your advocate, and get legal advice.",
  },
]);

import Diamond from "../../assets/logo/Diamond.svg";

import Book from "../../assets/logo/book.svg";
import VideoPlay from "../../assets/logo/videoplay.svg";
import Judge from "../../assets/logo/judge.svg";
import Calendar from "../../assets/logo/calendar-tick.svg";
import People from "../../assets/logo/people.svg";
import Consultation from "../../assets/logo/consultation.svg";
import Documents from "../../assets/logo/note.svg";
import Magazine from "../../assets/logo/magazine.svg";

export const faqQues = JSON.stringify([{
    id: "1", ques: "What is India Legal App? ",
    ans: "India Legal App offers free legal aid to economically weaker section. Along with customisable legal services for others. "
},
{
    id: "2", ques: "Is India Legal App FREE? ",
    ans: "Yes, it is free App you can download it from Play Store and App Store. "
},
{
    id: "3", ques: "What services India Legal App provides? ",
    ans: "We offer legal service that helps you create legal documents and get legal advice as fast as possible. These are the most popular services - Talk to Lawyer, Business and Contracts,Real Estate,Startup, Family and Personal."
},
{
    id: "4", ques: "What is IL Suraksha Kavach? ",
    ans: "As the name says, IL Suraksha Kavach is an affordable legal protection plan of India Legal App. In this legal protection plan, we combine various legal services such as Free Consultation for 30 mins, Rent Agreements, Affidavits, Study Material for Law Students, Legal Magazine Access, Exclusive Video content, Exclusive Legal Talk and access to group events."
},
{
    id: "5", ques: "What is SOS Service? ",
    ans: "SOS service is FREE legal dedicated helpline, working 24x7. Whenever you require any kind of legal help, our dedicated team is always available to help you"
},
{
    id: "6", ques: "What is Talk to Lawyer? ",
    ans: "On phone instant audio, video legal consultation from experienced advocates. It’s a simple process - book your mins, select your advocate, and get legal advice."
}])

export const benefits = [
    {
        id: '15', image: People, heading: "Professional services",
        subHeading: 'Members can get upto 50% discount on our professional services'
    },
    {
        id: '12', image: Judge, heading: "Exclusive Legal Talk",
        subHeading: 'Members get FREE access to exclusive talks of distinguished legal fraternity'
    },
    {
        id: '13', image: Calendar, heading: "Event Passes",
        subHeading: 'Members get complimentary pass to our events'
    },
    {
        id: '14', image: Consultation, heading: "FREE Consultation",
        subHeading: 'Members get FREE legal advice on phone by our India Legal Network lawyer. First 30 minutes of consultation is FREE'
    },
    {
        id: '8', image: Documents, heading: "Documents",
        subHeading: 'Members get 2 FREE documentation service that fits your needs*'
    },
    {
        id: '9', image: Book, heading: "FREE Study Material for Law Students",
        subHeading: 'Members get FREE access to non-exhaustive reference materials'
    },
    {
        id: '7', image: People, heading: "India Legal network lawyers",
        subHeading: 'Members get upto 30% discount when hiring Indian Legal network lawyers'
    },
    {
        id: '10', image: Magazine, heading: "Legal Magazine Access",
        subHeading: 'Members get FREE access to India Legal magazine'
    },
    {
        id: '11', image: VideoPlay, heading: "Exclusive Video content",
        subHeading: 'Members get FREE access to videos on Law & Justice, and Crime based content.'
    },
 
]
import React from "react";
import logo from "../assets/logo/logo.svg";
import facebook from "../assets/logo/fb_logo.svg";
import instagram from "../assets/logo/Instagram_logo.svg";
import twitter from "../assets/logo/twitter_logo.svg";
import { Box, Typography } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Link } from "react-router-dom";
const footer = () => {
  const column = [
    {
      id: "1",
      name: "Overview",
      list: [
        { id: "1.1", name: "IL Suraksha kavach", path: "IL-suraksha-kavach" },
        { id: "1.2", name: "Talk to Lawyer ", path: "/talk-to-lawyer" },
        { id: "1.3", name: " Family and Personal", path:"/family-and-personal" },
        { id: "1.4", name: "Real Estate", path: "/real-estate" },
        { id: "1.5", name: "Business and Contracts ", path:"/buisness-and-contracts" },
        { id: "1.6", name: "Start Up",path:"/start-up" },
        { id: "1.7", name: "Lawyer Page", path: "/lawyer" },
      ],
    },
    {
      id: "2",
      name: "Support",
      list: [
        {
          id: "2.2",
          name: (
            <a
              className="font500"
              style={{
                textDecoration: "none",
                fontSize: { lg: "16px", md: "14px", xs: "12px" },
                lineHeight: "1.25",
                color: "#000000",
              }}
              href="mailto: legal@indialegalapp.com"
            >
              {" "}
              legal@indialegalapp.com
            </a>
          ),
        },
      ],
    },
    {
      id: "3",
      name: "Company",
      list: [
        { id: "3.1", name: "About", path: "/about-us" },
        { id: "3.2", name: "Contact Us", path: "/contact-us" },
        { id: "3.3", name: "Blogs", path: '/blogs' },
        { id: "3.4", name: "Career", path: "/careers" },
      ],
    },
    {
      id: "4",
      name: "Legal",
      list: [
        { id: "4.1", name: "Privacy Policy", path: "/privacy-policy" },
        { id: "4.2", name: "Terms of Use", path: "/terms-&-condition" },
      ],
    },
  ];
  return (
    <>
      <Box
        sx={{
          maxwidth: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          backgroundColor: "#FDF9F9",
        }}
      >
        <Box
          className="container"
          sx={{
            display: "flex",
            pt: "56px",
            pb: "80px",
            flexDirection: { xs: "column", sm: "row" },
            columnGap: { lg: "126px", md: "96px", sm: "84px" },
            rowGap: { xs: "32px" },
            px: { lg: "10px", md: "0px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: { md: "40px", sm: "32px", xs: "24px" },
              alignItems: { sm: "center", xs: "flex-start" },
            }}
          >
            <Box
              sx={{
                height: { lg: "58px", xs: "49px" },
                width: { lg: "160px", xs: "136px" },
              }}
            >
              <Link className="noDecoration" to="/">
                <img className="imageSize" src={logo} alt="logo" />
              </Link>
            </Box>
            <Box sx={{ display: "flex", columnGap: "24px" }}>
              <Box
                sx={{
                  height: { lg: "32px", md: "28px", xs: "24px" },
                  width: { lg: "32px", md: "28px", xs: "24px" },
                }}
              >
                <a
                  className="imageSize"
                  href="http://facebook.com/indialegalmedia"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="imageSize " src={facebook} alt="facebook" />
                </a>
              </Box>
              <Box
                sx={{
                  height: { lg: "32px", md: "28px", xs: "24px" },
                  width: { lg: "32px", md: "28px", xs: "24px" },
                }}
              >
                <a
                  className="imageSize"
                  href="https://www.instagram.com/indialegalmedia/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="imageSize " src={instagram} alt="instagram" />
                </a>
              </Box>
              <Box
                sx={{
                  height: { lg: "32px", md: "28px", xs: "24px" },
                  width: { lg: "32px", md: "28px", xs: "24px" },
                }}
              >
                <a
                  className="imageSize"
                  href="https://twitter.com/indialegalmedia"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="imageSize" src={twitter} alt="twitter" />
                </a>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: { lg: "72px", md: "64px", sm: "36px" },
              rowGap: "32px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            {column.map((index) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: { lg: "32px", md: "28px", sm: "24px", xs: "12px" },
                  }}
                  key={index.id}
                >
                  <Typography
                    className="font700"
                    sx={{
                      fontSize: { lg: "20px", md: "18px", xs: "14px" },
                      lineHeight: "1.25",
                      color: "#000000",
                    }}
                    key={index.id}
                  >
                    {index.name}
                  </Typography>
                  {index.list.map((list) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "24px",
                        }}
                        key={list.id}
                      >
                        {list.path ? (
                          <Link className="noDecoration" to={list.path}>
                            <Typography
                              className="font500"
                              sx={{
                                fontSize: {
                                  lg: "16px",
                                  md: "14px",
                                  xs: "12px",
                                },
                                lineHeight: "1.25",
                                color: "#000000",
                                cursor: "pointer",
                              }}
                              key={list.id}
                            >
                              {list.name}
                            </Typography>
                          </Link>
                        ) : (
                          <Typography
                            className="font500"
                            sx={{
                              fontSize: { lg: "16px", md: "14px", xs: "12px" },
                              lineHeight: "1.25",
                              color: "#000000",
                              cursor: "pointer",
                            }}
                            key={list.id}
                          >
                            {list.name}
                          </Typography>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            height: { md: "46px", lg: "54px", sm: "38px", xs: "48px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#D12E29",
            // px: '20px'
          }}
        >
          <Box
            sx={{
              height: { md: "20px", xs: "16px", sm: "14px" },
              width: { md: "20px", xs: "14px", sm: "18px" },
              marginRight: "8px",
              mb: { xs: "1px", sm: "0px", md: "2px" },
              minWidth: "14px",
            }}
          >
            <CopyrightIcon
              style={{ fill: "#fff", height: "100%", width: "100%" }}
            />
            {/* <img className='imageSize' src={CopyrightIcon} alt='copyright'/> */}
          </Box>
          <Typography
            className="font600"
            sx={{
              color: "#ffffff",
              fontSize: { sm: "14px", xs: "12px", md: "16px", lg: "16px" },
              lineHeight: "1.25",
            }}
          >
            2023 EN Communication Private Limited • All rights reserved
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default footer;

import Document from "../../assets/logo/ra-DocumentCollection.svg";
import Review from "../../assets/logo/ra-ReviewAnalysis.svg";
import Consultation from "../../assets/logo/ra-DetailedConsultation.svg";
import Service from "../../assets/logo/ra-ServiceDelivery.svg";
export const benefits = [{ id: '1', image: Document, heading: "Document Collection", subHeading: 'Fill the required fields,' },
{ id: '1', image: Review, heading: "Review Analysis", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
{ id: '1', image: Consultation, heading: "Detailed Consultation", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
{ id: '1', image: Service, heading: "Service Delivery", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
]

export const reviewProperty = [
    { review: 'App is very useful to help me in my legal advice and using the app is also very simple.', reviwerName: 'Shubham Sharma', reviwerType: 'Service that customer availed' },
    { review: 'I recently used a legal service on India Legal App and I am extremely satisfied with the experience.', reviwerName: 'Nivideta Singh', reviwerType: 'Service that customer availed' },
    { review: 'Definitely recommend using India Legal. The Lawyer I spoke with was very knowledgeable and suggested me with perfect solutions.', reviwerName: 'Vandana Tomar', reviwerType: 'Service that customer availed' },
    { review: 'App is very useful to help me in my legal advice and using the app is also very simple.', reviwerName: 'Shubham Sharma', reviwerType: 'Service that customer availed' },
    { review: 'I recently used a legal service on India Legal App and I am extremely satisfied with the experience.', reviwerName: 'Nivideta Singh', reviwerType: 'Service that customer availed' },
    { review: 'Definitely recommend using India Legal. The Lawyer I spoke with was very knowledgeable and suggested me with perfect solutions.', reviwerName: 'Vandana Tomar', reviwerType: 'Service that customer availed' },

]
export const faq = JSON.stringify([{
    id: "1", ques: "What is India Legal App? ",
    ans: "India Legal App offers free legal aid to economically weaker section. Along with customisable legal services for others. "
},
{
    id: "2", ques: `Is India Legal App FREE? `,
    ans: "Yes, it is free App you can download it from Play Store and App Store. "
},
{
    id: "3", ques: "What services India Legal App provides? ",
    ans: "We offer legal service that helps you create legal documents and get legal advice as fast as possible. These are the most popular services - Talk to Lawyer, Business and Contracts,Real Estate,Startup, Family and Personal."
},
{
    id: "4", ques: "What is IL Suraksha Kavach? ",
    ans: "As the name says, IL Suraksha Kavach is an affordable legal protection plan of India Legal App. In this legal protection plan, we combine various legal services such as Free Consultation for 30 mins, Rent Agreements, Affidavits, Study Material for Law Students, Legal Magazine Access, Exclusive Video content, Exclusive Legal Talk and access to group events."
},
{
    id: "5", ques: "What is SOS Service? ",
    ans: "SOS service is FREE legal dedicated helpline, working 24x7. Whenever you require any kind of legal help, our dedicated team is always available to help you"
},
{
    id: "6", ques: 'What is Talk to Lawyer?',
    ans: "On phone instant audio, video legal consultation from experienced advocates. It’s a simple process - book your mins, select your advocate, and get legal advice."
}]);
export const propertyServices = JSON.stringify([
    {
        heading: "Property Report", description: `A ‘Property Report’ is comprehensive document about your property prepared by an experienced legal professional.`,
        content: [{ subHeading: `Why Property Report?`, subDesc: `When the buyer decides to purchase a house or land, they need to make sure there are no flaws in the real estate before they decide to buy a house or a plot of land. While making this important investment decision, several factors must be taken into account. The legal verification of property papers is one of the process's key elements. This is the foundation for purchasing a real estate and the buyer must proceed with extra caution since even a minute error on their side might endanger their financial stability.` }, {
            subHeading: `List of essential Documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for a Property Report.
    1. Title Documents
    The title must be provided in full. One has every right to ask the seller for any records of prior transactions and to request document verification.
    2. Certificate of Encumbrance
    The presence of a mortgage or other debts on the provided property is verified by an Encumbrance Certificate.
    3. Property Tax invoices and accompanying paperwork.
    4. Sales Deed
    The ownership is transferred through the creation of a sales deed. Details on both the buyer and the vendor are included.
    5. Power of Attorney (if applicable)
    This land document verification aids in determining whether the property is being sold on behalf of the owner by an authorized party.
    6. Approved Plan
    This document is required to be determined if the construction is done in accordance with the authorized plan or not.
    7. Other Papers
    The  relinquishment  deed,  division  deed,  will  freehold,  and  mutation information are additional papers needed for property verification. (if any of them are appropriateorapplicable).` }]
    },

    {
        heading: "Sale Deed Drafting", description: "Sale deed is a legal document that must be signed by both the seller and the buyer in order to complete the sale of a property. An actualized sale deed demonstrates the handover of property ownership from the seller to the buyer.Technically, a sale agreement is the document that serves as legal evidence that a property transfer has taken place. It outlines all of the property's important features and guarantees the enforceability of each party's rights and obligations.The sale deed is written and printed on stamp paper with a value set by the state government",
        content: [{ subHeading: '', subDesc: '' },
        ]
    },

    {
        heading: `Property Registration`, description: ``, content: [{ subHeading: `What is Property Registration?`, subDesc: `According to the Registration Act of 1908, it is a requirement in India for all real estate transactions to be registered. To gain the rights to the property as of the deed's execution date, the transfer of immovable property must be registered.` },
        {
            subHeading: `List of essential Documents`,
            subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of Property.
     1. Estimation of the worth of the property.
     2. Deed of Sale
     3. Fees for registration in addition to stamp duty must be paid.
     4. Application to be processed by the Sub-Registrar.
     5. Submission of documents
     6. Additional documentation required locally.` }, {
            subHeading: `What is the Procedure for Property Registration?`, subDesc: `1. The office of the Sub-Registrar of Assurances should receive the property papers, within whose jurisdiction the property that is the subject of the transfer is located.
     2. For the paperwork to be registered, both the seller and the buyer's authorized signatures must be present, as well as of two witnesses’. (The signatories should have identification with them.) Any other kind of government-issued identification evidence, such as a PAN card or an Aadhaar card, is also acceptable for this purpose.
     3. If the signatories are acting on behalf of another person, they must additionally provide a power of attorney.
     4. A power of attorney or letter of authorization, as well as a copy of the decision of the business's board authorizing him to carry out the registration, must be carried by the person representing the company if the company is a party to the agreement.
     5. The sub-registrar must receive the property card, the original paperwork, and documentation of your stamp duty payment. The sub-registrar will confirm that sufficient stamp duty has been paid for the property in accordance with the stamp duty ready reckoner before registering the papers. The registrar will decline to register the documents if there is any shortfall in the stamp duty.
     6. The registration fee is the Tax to complete this legal formality in the government's records, whereas stamp duty is the price you pay to the government to acquire legal ownership of an object. State-to-state differences in stamp duty exist.
     7. Most states give refunds or exemptions on the payment of stamp duty to women. Take note that witnesses are crucial to the procedure overall.
     8. The two witnesses you plan to include with the registration will also need to prove their identification to the sub-registrar. They should also have documents of their identity and addresses for this purpose. During the procedure, their biometric identification will also be scanned.` }]
    },
    {
        heading: "Property Document Review", description: ``,
        content: [
            { subHeading: `What is document review?`, subDesc: `The Sale Agreement or Agreement to Sell (either one or all of them) must be signed when purchasing real estate. We lack the time and fundamental legal knowledge necessary to thoroughly study and comprehend these two important texts. With the aid of experienced real estate attorneys, India Legal App Document Review is resolving this issue by providing you with a Sale Agreement (Deed)/Agreement to Sell Review.` },
            { subHeading: `Why do I need a document review?`, subDesc: `We never have time in our hectic schedules to read a lot of paperwork, especially legal documents. There are occasions when we fail to read the tiny print of an agreement and discover it only later. It is usually advisable to have your legal documents thoroughly read and understood before signing them. If you are familiar with the entire content of the document, it will save you a lot of time, money, and mental suffering in the future.` }
        ]
    },
    {
        heading: "Will", description: ``,
        content: [{
            subHeading: 'What is a WILL?', subDesc: `In a will, the maker declares his or her desires for how their assets should be dispersed after death. The will also appoints one or more people as the executors, who are responsible for overseeing the estate's affairs until their ultimate distribution. 
    The Indian Succession Act, 1925, as well as other personal laws in India contain the legal provisions pertaining to the will. The will is "a formal declaration of a person's purpose with respect to his property, which he intends to take effect after his death," according to the Indian Succession Act of 1925.`},
        {
            subHeading: `Contents of a Will? `, subDesc: `Although the will need not be written in a particular style or structure. However, the following components are included in an effective will.
    1. Maker's information: Name, age, residence, and other information to identify the maker.
    2. Legal declaration: The maker's statements in the will must be true and correct.
    3. The maker's future intentions, or how he wants his property to be dispersed among the beneficiaries, must be clearly expressed in the will.
    4. Beneficial information: The information needed to identify the beneficiaries, such as name, age, and residence.
    5. Execution after death: The will must reflect the maker's desire for the document to be carried out after his or her passing.
    6. Minors: If the maker wishes to give a minor any property, he must also name a guardian to look after the minor's assets following the minor's death.
    7. Executor: The creator of the will should also name the person who will carry out its provisions after his or her passing.` }]
    },
    {
        heading: "Gift Deed", description: `When someone wants to donate their money or property to another individual, they employ this arrangement for both moveable and immovable property. The individual making the gift is referred to as the donor, and the recipient is referred to as the donee.`, content: [{
            subHeading: 'Contents of gift deed', subDesc: `Ideally, a Gift Deed would include the following: 
1. Location and date: The deed's execution location and date.
2. Donor and Donee information: Important details about the donor and the donee, including their names, addresses, relationships, dates of birth, and signatures. 
3. Property: Identifiable information about the property, including its location, address, plot number, etc. 
4. Liabilities: It is also important to highlight liabilities like liens and encumbrances. 
5. Delivery: A provision describing how the property will be transferred from the donor to the donee must be included. 
6. Revocation: The gift deed may additionally specify whether or not the donor has the authority to revoke it in specified situations.
7. Free will: It must be made clear that the donor is donating the property voluntarily, without compulsion, deception, or undue influence.`}]
    },
    {
        heading: "Power of Attorney", description: `Power of Attorney (POA) is a legal instrument used to grant another person the authority to carry out specific tasks. The person to whom such rights are delegated is known as the POA holder who can either be an agent, donee, or attorney. While the person who grants said powers is known as the principal, grantor, or donor. The POA outlines the duties and authorities that a POA Holder possesses in order for him to act on their behalf.
    Since the Principal would trust a close friend, family member, or acquaintance, any of whom can be a POA holder, to carry out certain tasks when he is unable to do so himself.`,
        content: [{
            subHeading: `Contents of Power of Attorney`, subDesc: `A well-written POA must include the following: -
     1. Information about the Principal.
     2. Information of the Attorney, the person to whom the authority is issued.
     3. The justification for issuing the POA.
     4. The time and location the POA was signed, as well as its Start Date.
     5. The POA's expiration date, if it is limited by time. However, if no duration is given, it should specify whether the POA is durable or not.` }]
    },
    {
        heading: "Rent Agreement", description: `A rent agreement lays down the terms and conditions agreed upon by the tenant and the landlord. The rent agreement process may change, depending on the rules applicable in a particular city or state. So, it becomes crucial to understand the principles associated with the rent agreement, as per the city and state where you propose to rent a property.`, content: [{
            subHeading: `Documents required for registration of rent agreement`, subDesc: `1. Ownership proof: You can use the original/copy of the sale/purchase deed.
    2. Tax receipt: Tax receipt or Index II is required, at the time of registration.
    Note: As per Section 55 of the Registration Act 1908, Index II or index 2 is a legal certificate that states all the details of property registration, along with the necessary documents submitted at the Sub-Registrar Office (SRO) for records.
    3. Address proof of the landlord and tenant: For example, copy of passport, Aadhaar, driving licence, etc.
    4. Photographs: Two passport-size photos of the landlord and tenant, each.
    5. Identity proof: Copy of PAN card or Aadhaar card.
    6. Agreement on paper: Rent agreement printed on stamp paper.` }]
    },
])
export const startUpServices = JSON.stringify([
    {
        heading: `Limited Liability Partnership Company`, description: `Limited Liability Partnership (LLP) was introduced in 2008, since then it has quickly become a well-liked legal structure for business. Restricted Liability Partnership Registration is ruled by the Liability Partnership Act, 2008.`, content: [
            {
                subHeading: `Benefits of Registering a Limited Liability Partnership Company`, subDesc: `Registering a Limited Liability Partnership Company offers several benefits:
        - liability of Partners - separate Legal Entity
        - continuity of Existence - least restrictive Compliance - transfer of possession` },
            {
                subHeading: `List of essential Documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Limited Liability Partnership Company:
        1. Copy of Pan Card of Partners (mandatory)
        2. Aadhaar Card or Driving license or Passport; Bank Statement or Electricity Bill or Mobile Bill (not older than 2 months) (any one)
        3. Passport Size Photograph of Partners
        4. Proof of Registered Office:
        5. Conveyance/ Lease deed/Rent Agreement etc. alongside rent receipts (any one)
        6. Copy of the utility bills (Telephone/Gas/Electricity bill) (not older than 2 months) (any one)
        7. NOC from landholder` },
            {
                subHeading: `Detailed Registration Process`, subDesc: `Following is a step by step guide of the registration procedure as per the governing rules of the Ministry of Corporate Affairs:
        1. Application for DSC & DIN for Partners
        2. Application filing for Name availability
        3. Drafting and Filing of LLP agreement
        4. Drafting and Filing of eMoA and eAoA
        5. Apply for PAN and TAN of the corporate
        6. Certificate of Incorporation issued by RoC along with PAN and TAN
        7. Open a Current checking account on the corporate name` },
        ],
    },
    {
        heading: `Partnership Firm`, description: `“Partnership” is the relation between 2 or more persons who have agreed to share the profits of a business carried on by all or any of them. Two or additional parties come together with a proper agreement (known as Partnership Deed) where the roles, duties and also the share of every partner is expressly outlined. Persons who have agreed to enter into partnership are referred to as “PARTNERS” and collectively a “FIRM”.`, content: [
            {
                subHeading: `Registration procedure`, subDesc: `Following is a step by step guide of the registration procedure as per the governing rules:
        1. Draft a Partnership Deed
        2. Payment of stamp tax on Deed
        3. Notarization of Partnership Deed
        4. Apply for PAN and TAN of the Firm
        5. Certificate of Registration from RoF
        6. Open a Current checking account on the Firm’s name` },
            {
                subHeading: `Required documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Private Limited Company:
        1. Identity & Address Proof of Partners
        2. Copy of Pan Card of Partners (mandatory)
        3. Aadhaar Card or citizen Id card or driver's license or Passport
        4. Anyone of: Bank Statement or Electricity Bill or Mobile Bill (not older than two months)
        5. Passport Size Photograph of Partners
        6. Proof of Registered workplace (any one): Conveyance/ Lease deed/Rent Agreement etc. together with rent receipts (any one) Copy of the utility bills (Telephone/Gas/Electricity bill) (not older than 2 months) (any one)
        7. Documents needed for Partnership Registration Form No. one (Application for registration underneath Partnership Act) Original copy of Partnership Deed, signed by all Partners Instrument declaring intention to become Partner Rental or lease agreement of the property/campus on that the business is established` },

        ],
    },

    {
        heading: `One Person Company`, description: `Registering a corporation is the start to begin a business in Republic of India. The One Person Company (OPC) is a new kind of company formation introduced within the Companies Act of 2013. In OPC, one promoter gains full authority over the corporate, he is going to be the only real shareholder and director. An OPC is a hybrid structure which combines most of the advantages of a sole proprietary and a corporation.`, content: [
            {
                subHeading: `Registration procedure`, subDesc: `Following is a step by step guide of the registration procedure as per the governing rules:
        1. Application for DSC (Digital Signature Certificate)
        2. Apply for DIN (Director Identification Number)
        3. Application filing for Name convenience
        4. Drafting and Filing of eMoA and eAoA
        5. Apply for PAN and TAN of the corporate
        6. Certificate of Incorporation issued by RoC along with PAN and TAN
        7. Open a Current checking account on the corporate name` },
            {
                subHeading: `Required documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a One Person Company:
        1. Identity & Address Proof:
        Copy of Pan Card of Director Citizen Id card or driver's licence or Passport Any one: Bank Statement or Electricity Bill or Mobile Bill (not older than a couple of months) Passport Size Photograph of administrators
        2. Proof of Registered Office:
        Conveyance/ Lease deed/Rent Agreement etc. at the side of rent receipts (any one) Copy of the utility bills (Telephone/Gas/Electricity bill) (not older than 2 months) (any one) NOC from landowner` },
        ],
    },
    {
        heading: `Private Limited Company`, description: `Registering a corporation is the opening move to start out a business in India. If you are a start-up and have high growth aspirations, then you ought to incorporate a Private Ltd. Private Limited Company is incorporated under the Companies Act, 2013 and governed by the Ministry of Corporate Affairs (MCA).`,
        content: [
            {
                subHeading: `Requirements for Registering a Private Limited Company`, subDesc: `• Minimum 2 Directors, but a maximum two hundred can become shareholders.
        • One Resident Director: Among 2 directors, a minimum of one director should be a resident Indian for the registration. Resident Indian suggests that someone who has spent a minimum of 182 days or more during a fiscal year in India.
        • No Minimum Capital: There is no minimum capital needed for said registration but a minimum authorized share capital of Rupees One lakh.
        • Unique Name: The recommended name of the Private Limited ought to be distinctive and should not be like any already existing name or trademark.` },
            {
                subHeading: `Detailed Registration Process`, subDesc: `1. Application for DSC & DIN for Partners
        2. Application filing for Name availability
        3. Drafting and Filing of LLP agreement
        4. Drafting and Filing of eMoA and eAoA
        5. Apply for PAN and TAN of the corporate
        6. Certificate of Incorporation issued by RoC along with PAN and TAN
        7. Open a Current checking account on the corporate name` },
            {
                subHeading: `List of essential Documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Private Limited Company:
        1. Copy of Pan Card of directors (mandatory)
        2. Aadhaar Card or Driving license or Passport; Bank Statement or Electricity Bill or Mobile Bill (not older than 2 months) (any one)
        3. Passport Size Photograph of administrators
        4. Proof of Registered Office:
        5. Conveyance/ Lease deed/Rent Agreement etc. alongside rent receipts (any one)
        6. Copy of the utility bills (Telephone/Gas/Electricity bill) (not older than 2 months) (any one)
        7. NOC from landholder` },
        ],
    },
    { heading: `Trademark`, description: ``, content: [{ subHeading: ``, subDesc: `` }], },
    {
        heading: `Copyright`, description: `Copyright is the right granted to creators of literary, dramatics, music, and inventive work and producers of films and recordings. The holder of Copyright has the rights to reproduce, communicate, distribute, and assign the work he has created. Copyright ensures minimum safeguards of authors' rights over their creations, thereby protecting liability.
    The Copyright Act, 1957 protects original literary, dramatic, musical and artistic works and cinematographic films and sound recordings from unauthorized usage and repeating of their creation. Copyright protects the expressions and not the ideas. There’s no copyright protection for ideas, procedures, ways of operation or mathematical ideas.`,
        content: [
            {
                subHeading: `Registration procedure`, subDesc: `Following is a step by step guide of the registration procedure as per the governing rules:
        1. Copyright registration application has to be prepared in Form IV
        2. Application must be duly signed by the applicant and submitted by the advocate under whose name Power of Attorney has been executed
        3. Online filing of an application for Copyright Registration
        4. If discrepancy and/or objections are found, discrepancy notice will be issued and the same needs to be responded to within 30 days from the date of issuance of the notice
        5. If there are no discrepancies or objections with the application, the copyright will be registered` },
            {
                subHeading: `Required documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Copyright:
        1. Proof of Applicant - PAN Card, Address Proof of Applicant and Certificate of Incorporation (if the Copyright is to be registered for a Company)
        2. Nature of Work
        3. Copies of the original work
        4. Category & Description of the Work
        5. Title of the Work
        6. Language of the Work
        7. Date of Publication
        8. NOC from Author/Publisher (if applicable)
        9. Power of Attorney in favor of the Lawyer/Attorney` },

        ],
    },
    {
        heading: `Patent`, description: `As per the Patent Act, 1970 for an invention to be patentable, the invention should be a brand new product or method, involving an inspired step and capable of being created or employed in industry. Patent registration may be obtained in Republic of India for an invention. Patent registrations are not applicable for all inventions, and therefore the invention should satisfy bound criteria to be patentable in Republic of India. Patent filing or patent registration is that the opening move a creator takes to safeguard his/her invention from being exploited. Patent filing in Republic of India may be a sophisticated method, however, with the proper legal steering, it may be done simply.`, content: [
            {
                subHeading: `Registration procedure`, subDesc: `Following is a step by step guide of the registration procedure as per the governing rules:
        1. Patent Search
        2. File application
        3. Preparation of a patentability report
        4. Publication of application
        5. Patent Examination
        6. Patent Objections
        7. Grant of Patent` },
            {
                subHeading: `Required documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Patent:
        1. Title of the invention
        2. Candidates Information - Name, address, contact details and status of every human for the patent
        3. Description of the Invention - careful description of the invention and details concerning what the discoverer needs to say out of the invention
        4. Technical aspects of the invention - Technical details concerning the invention and drawings along with copy of tentative patent (if filed)` },
        ],
    },
    { heading: `Legal Advice for Startup`, description: ``, content: [{ subHeading: ``, subDesc: `` }], },
])

export const buisnessAndContractsService = JSON.stringify([
    {
        heading: `Document Review & Consult`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Founders Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Shareholder’s Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Term Sheet Review`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Franchise Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Joint Venture`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Non Disclosure Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Service Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Business Partnership Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Licensing Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Letter of Intent`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Software as a Service (SAAS) Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `IP Assignment Agreement`, description: `A formal agreement between two parties known as an IP Assignment Agreement, a property owner (known as the assignor) transfers full ownership of a subject matter to the other party (known as assignee). The owner of such material may delegate all intellectual property rights in such items such as music, design, artwork, photographs, videos, and sounds, among other things.\nIP Assignment Agreement's Contents
1. Information on the transferred subject matter
2. Payment type
3. Warranty and representation
4. Compensation
5. Liability restrictions
6. Termination grounds
7. The Severability
8. Refund
9. Other legal provisions.`, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Consultancy Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Employment Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Freelancer Agreement`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Privacy Policy`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
    {
        heading: `Terms of Use`, description: ``, content: [
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
            {
                subHeading: ``, subDesc: ``
            },
        ],
    },
])
import React from 'react'
import { Box, Typography } from '@mui/material';
import Download from "../../component/downloadButton";
import tick from "../../assets/logo/tick.svg";
import chooseLawyerBG from "../../assets/image/chooseLawyerbg.svg";
import audioviodeoBG from "../../assets/image/audiovideobg.svg";
import chooseLawyerImage from "../../assets/image/chooseLawyer.svg";
import audioVideoImage from "../../assets/image/audiovideocall.webp";
import talkTolawyerBG from "../../assets/image/talktoLawyerBackground.webp";
import talkTolawyerBGN from "../../assets/image/lawNew.svg";
import talkTolawyer from "../../assets/image/talktolawyerHero.webp";
import FAQ from "../../component/Faq";
import CTA from "../../component/cta";
import { faqQues, reviewTalkToLawywer } from "./constant";
import Slots from "./component/slots";
import Testimonials from '../../component/testimonials';

const TalkToLawyer = () => {
    return (
        <>
            {/* Hero Section */}
            < Box sx={{
                maxWidth: '100%',
                mt: { md: '150px', sm: '144px', xs: '72px' },
                //  mb: { lg: "60px", md: "60px", sm: "60px", xs: "32px" },
                mb: { lg: '232px', md: '180px', sm: '90px', xs: '32px' }
            }}
            >

                <Box className="container" >
                    <Box className="flexrow" sx={{
                        justifyContent: 'space-between',
                        // flexWrap:'wrap'
                        flexDirection: {
                            lg: "row",
                            md: "row",
                            sm: "row",
                            xs: "column-reverse",

                        },
                        gap: { sm: "40px", xs: "32px" },

                    }}>
                        <Box className="flexcol"
                            sx={{
                                rowGap: '40px',
                            }}>
                            <Box className="flexcol" sx={{
                                rowGap: '16px',
                                maxWidth: { md: '560px', sm: '400px' },
                                pt: { md: '40px', sm: '28px', xs: '0px' },
                                alignItems: 'flex-start',
                            }}>
                                <Typography variant="h1" className='font700 bannerHeading'
                                >Talk to Lawyer</Typography>
                                <Typography className='font400 p1'>
                                    Get legal assistance where you want it, when you need it by talking to our India Legal network lawyer.
                                    Whether your case involves Property & Real Estate, Check Bounce & Money Recovery, Civil Matters, Criminal Matters, Cyber Crime, Consumer Protection, Divorce & Child Custody, Employment Issues, Company & Start-Ups and other legal problems. Our lawyers will listen to your concerns, and will explain the options available to you moving forward. You need not worry.
                                </Typography>
                            </Box>
                            <Box className="flexcol"
                                sx={{
                                    rowGap: { md: '80px', sm: '64px', xs: '36px' },
                                    alignItems: 'flex-start'
                                }}>
                                <Box className="flexcol"
                                    sx={{
                                        rowGap: "16px",
                                    }}>
                                    <Box className="flexrow" sx={{
                                        columnGap: '40px', flexDirection: { xs: 'column', sm: 'row' }, rowGap: "18px",
                                    }} >
                                        <Box className="flexrow" sx={{ columnGap: "10px", width: '152px', alignItems: "center" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Legal consultation
                                            </Typography>
                                        </Box>
                                        <Box className="flexrow" sx={{ columnGap: "10px" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Realtime Reports
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className="flexrow" sx={{ columnGap: '40px', flexDirection: { xs: 'column', sm: 'row' }, rowGap: "18px", }} >
                                        <Box className="flexrow" sx={{ columnGap: "10px", width: '152px', alignItems: "center" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Easy To Use
                                            </Typography>
                                        </Box>
                                        <Box className="flexrow" sx={{ columnGap: "10px" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                24x7 Call Support
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Download name={"Download App"}
                                    applink={'https://play.google.com/store/apps/details?id=com.indialegal.user'} />
                            </Box>
                        </Box>
                        <Box sx={{
                            width: { md: '510px', sm: '382px', xs: "auto" },
                            height: { md: '449px', sm: '336px'},
                            minHeight: "240px",
                            // backgroundImage: `url(${talkTolawyerBG})`,
                            // backgroundImage: `url(${talkTolawyerBGN})`,
                            // backgroundRepeat: 'no-repeat',
                            // position: 'relative',
                            // backgroundSize: 'contain'
                            objectFit: 'contain'
                        }}>
                            <img src={talkTolawyerBG} alt="lawyer"
                                style={{ width: "-webkit-fill-available", height: "-webkit-fill-available" }}

                            />
                            {/* <Box sx={{ position: 'absolute', top: '50px', right: { md: '72px', sm: '56px' }, maxWidth: { xs: '324px' } }}>
                            </Box> */}

                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* choose lawywer */}
            < Box sx={{ maxWidth: '100%', mt: { sm: '40px', xs: '32px' }, mb: { md: '64px', sm: '48px', xs: '32px' } }}>
                <Box className="container" >
                    <Box className="flexrow" sx={{
                        height: { md: '492px', sm: '370px', xs: 'auto' },
                        flexWrap: { xs: 'wrap', sm: 'nowrap' },
                        justifyContent: { sm: "space-between", xs: "center" },
                        columnGap: '10px',
                        rowGap: { xs: "24px" }
                    }}>

                        <Box>

                            <Box className='flexcol' sx={{
                                rowGap: '16px',
                                width: { lg: '560px', md: '460px', sm: '400px', xs: '100%' }
                            }}>
                                <Typography className='font700 heading'
                                    sx={{ pt: { lg: "188px", md: '160px', sm: '80px' } }}
                                >Choose your Lawyer</Typography>
                                <Typography className='font 400 p1'>Based on your legal issue, choose a lawyer from our network lawyers. </Typography>
                            </Box><Box className='flexcol' sx={{
                                rowGap: '16px',
                                width: { lg: '560px', md: '460px', sm: '400px', xs: '100%' }
                            }}>
                                <Typography className='font700 heading'
                                    sx={{ pt: { lg: "40px", md: '20px', sm: '20px' } }}
                                >Audio and Video Calls</Typography>
                                <Typography className='font 400 p1'>On phone instant audio, video legal consultation with a lawyer from our lawyers network. </Typography>
                            </Box>

                        </Box>

                        <Box sx={{
                            width: { md: "501px", sm: '375px', xs: '320px' },
                            height: '100%',
                            backgroundImage: `url(${chooseLawyerBG})`,
                            backgroundRepeat: 'no-repeat',
                            top: '0px',
                            backgroundSize: 'contain'
                        }}>
                            <Box sx={{ mx: 'auto', pt: { md: '97px', sm: '77px', xs: '27px' }, width: { md: '324px', sm: '204px', xs: '204px' } }}>
                                <img className='imageSize' src={chooseLawyerImage} alt="book appointment" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* audio video call section */}
            {/* <Box sx={{ maxWidth: '100%', mb: { md: '64px', sm: '48px', xs: '32px' } }}>
                <Box className="container" >
                    <Box className="flexrow" sx={{
                        height: { md: '492px', sm: '370px', xs: 'auto' },
                        flexWrap: { xs: 'wrap', sm: 'nowrap' },
                        justifyContent: { sm: "space-between", xs: "center" },
                        columnGap: '10px',
                        rowGap: { xs: "24px" },
                        flexDirection: { sm: 'row', xs: 'column-reverse' },
                        alignItems: { xs: 'center' }
                    }}>
                        <Box sx={{ width: { md: "501px", sm: '375px', xs: '100%' }, height: '100%', backgroundImage: `url(${audioviodeoBG})`, backgroundRepeat: 'no-repeat', top: '0px', backgroundSize: 'contain', maxWidth: { xs: '320px' } }}>
                            <Box sx={{ mx: 'auto', pt: { md: '97px', sm: '77px', xs: '27px' }, width: { md: '324px', sm: '204px', xs: '204px' } }}>
                                <img className='imageSize' src={audioVideoImage} alt="book appointment" />
                            </Box>
                        </Box>

                        <Box className='flexcol'
                            sx={{
                                rowGap: '16px',
                                width: { lg: '560px', md: '460px', sm: '400px', xs: '100%' }
                            }}>
                            <Typography className='font700 heading' sx={{ pt: { lg: "80px", md: '64px', sm: '56px' } }} >Audio and Video Calls</Typography>
                            <Typography className='font 400 p1'>On phone instant audio, video legal consultation with a lawyer from our lawyers network.</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box> */}
            {/* Which one you should choose */}
            {/* <Box sx={{ maxWidth: '100%', mb: { md: '80px', sm: '64px', xs: '32px' } }}>
                <Box className="container" >
                    <Box sx={{ height: { md: '468px', xs: 'auto' }, pt: { md: '56px', sm: '42px', xs: '32px' } }}>
                        <Box className="flexcol" sx={{ rowGap: { lg: '72px', md: '64px', sm: '32px', xs: '24px' }, alignItems: 'center' }}>
                            <Typography className='font700 subHeading'> Which one you should Choose?</Typography>

                            <Box className="flexrow" sx={{
                                columnGap: { lg: '59px', md: '42px', xs: '42px' }, flexWrap: { xs: 'wrap', md: 'nowrap' }, alignItems: 'center',
                                justifyContent: 'center',
                                rowGap: '42px',
                                overflow: 'hidden'
                            }}>
                                <Slots time={"15 Min"} heading={"Quick Consultation!"} subHeading={"Lorem ipsum dolor sit amet, consectetur adipiscing elit,"} />
                                <Slots time={"30 Min"} heading={"Review Consultation"} subHeading={"Lorem ipsum dolor sit amet, consectetur adipiscing elit,"} />
                                <Slots time={"45 Min"} heading={"Suggestive "} subHeading={"Lorem ipsum dolor sit amet, consectetur adipiscing elit,"} />
                                <Slots time={"60 Min"} heading={"Detailed Consultation"} subHeading={"Lorem ipsum dolor sit amet, consectetur adipiscing elit,"} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>*/}
            {/* testimonial Section */}
            {/* <Testimonials review={reviewTalkToLawywer} /> */}
            {/* Faq */}
            <FAQ ques={faqQues} />
            {/* cta section */}
            <CTA user={true} appLink={"https://play.google.com/store/apps/details?id=com.indialegal.user"} />
        </>
    )
}

export default TalkToLawyer
import React, { useState } from 'react'
import { Box, Typography } from "@mui/material";
import {
  BCKIMG,
  BOX2ND,
  SERVICE2NDHEADING,
  SERVICE2NDPARA,
  SERVICEHEADING,
} from "./style";
import tick from "../../assets/logo/tick.svg";
import Download from "../../component/downloadButton";
import { benefits, reviewProperty, faq, familyServices } from "./constant";
import Testimonials from "../../component/testimonials";
import Benefits from "./benafits";
import Faq from "../../component/Faq";
import RightArrow from "../../assets/image/rightArrow.svg";
import LeftArrow from "../../assets/image/leftArrow.svg";
import ServiceListSlider from "../../component/ServiceListSlider";
import FamilyandPersonalpageN from "../../assets/image/FamilyHeroImg.webp";
import FAQ from "../../component/Faq";
import CTA from "../../component/cta";
import HowItWorks from "../../component/HowItWorks";
const FamilyandPersonalpage = () => {

  const services = JSON.parse(familyServices);
  const [isActive, setIsActive] = useState(0);
  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          // mt: { md: "200px", sm: "160px", xs: "112px" },
          mt: { md: '150px', sm: '144px', xs: '72px' },
          mb: { lg: "60px", md: "60px", sm: "60px", xs: "32px" },
        }}
      >
        <Box className="container">
          <Box
            sx={{
              // p: { lg: "60px", md: "30px", sm: "20px", xs: "20px" },
              display: "flex",
              // alignItems:{lg:"center",md:"center", xs:"unset",sm:"center"},
              justifyContent: 'space-between',
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "row",
                xs: "column-reverse",
              },
              // gap: { lg: "100px", md: "100px", sm: "64px", xs: "32px" },
              gap: { sm: "40px", xs: "32px" },
            }}
          >
            <Box className="flexcol"
              sx={{
                rowGap: '40px',
              }}>
              <Box className="flexcol"
                sx={{
                  // width: { lg: "50%", md: "50%", xs: "100%", sm: "50%" },
                  // // pt: {lg:"0px", md:"0px", sm:"0",xs:"0"},
                  // pt: { md: '40px', sm: '28px', xs: '0px' },
                  rowGap: '16px',
                                maxWidth: { md: '560px', sm: '400px' },
                                pt: { md: '40px', sm: '28px', xs: '0px' },
                                alignItems: 'flex-start',
                }}
              >
                <Typography
                  className="font700 bannerHeading"
                // sx={{
                //   fontSize: { lg: "48px", md: "48px", sm: "32px", xs: "32px" },
                //   lineHeight: "1.25",
                //   color: "#272828",
                // }}
                >
                  Family and Personal 
                </Typography>
                <Typography
                  className=' font400 p1'
                // sx={[
                //   BOX2ND,
                //   // {
                //   //   fontSize: {
                //   //     lg: "20px",
                //   //     md: "20px",
                //   //     sm: "16px",
                //   //     xs: "16px",
                //   //   },
                //   // },
                // ]}
                >Family difficulties and personal problems can be very stressful and upsetting. Because
                  of the emotions involved they sometimes have a tendency to get out of hand. To deal
                  with them you need to know how the law works, and what your rights and options are.
                  We at India Legal App provide services related to matters of inheritance, succession,
                  marriage registration, adoption, divorce, maintenance, child custody, guardianship,
                  the partition of family property etc.
                </Typography>
              </Box>
              <Box className="flexcol"
                                sx={{
                                    rowGap: { md: '80px', sm: '64px', xs: '36px' },
                                    alignItems: 'flex-start'
                                }}>
                                <Box className="flexcol"
                                    sx={{
                                        rowGap: "16px",
                                    }}>
                                    <Box className="flexrow" sx={{
                                        columnGap: '40px', flexDirection: { xs: 'column', sm: 'row' }, rowGap: "18px",
                                    }} >
                                        <Box className="flexrow" sx={{ columnGap: "10px", width: '152px', alignItems: "center" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Legal consultation
                                            </Typography>
                                        </Box>
                                        <Box className="flexrow" sx={{ columnGap: "10px" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Realtime Reports
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className="flexrow" sx={{ columnGap: '40px', flexDirection: { xs: 'column', sm: 'row' }, rowGap: "18px", }} >
                                        <Box className="flexrow" sx={{ columnGap: "10px", width: '152px', alignItems: "center" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Easy To Use
                                            </Typography>
                                        </Box>
                                        <Box className="flexrow" sx={{ columnGap: "10px" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                24x7 Call Support
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Download name={"Download App"} 
                                applink= {'https://play.google.com/store/apps/details?id=com.indialegal.user'}/>
                            </Box>
            </Box>
            <Box
              // sx={[
              //   BCKIMG,
              //   { width: { lg: "50%", md: "50%", sm:"382px",xs: "auto" }, 
              //   height: { md: '449px', sm: '336px', xs: "237px" }
              // },
              // ]}
              sx={{
                width: { md: '510px', sm: '382px', xs: "auto" },
                height: { md: '449px', sm: '336px'},
                minHeight: "240px",
                objectFit: 'contain'
              }}
            >
              <img
                src={FamilyandPersonalpageN}
              style={{ width: "-webkit-fill-available",height:"-webkit-fill-available" }}
              />
            </Box>
          </Box>
          {/* serviceSection */}
          <Box sx={{ mt: { lg: "80px", md: "64px", sm: "40px", xs: "28px" } }}>
            <Box>
              <Typography
                className="font700"
                sx={{
                  fontSize: { lg: "32px", md: "32px", sm: "28px", xs: "28px" },
                  textAlign: "center",
                }}
              >
                Family and Personal Services
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
                mt: { lg: "40px", md: "40px", sm: "40px", xs: "28px" },
                mb: { lg: "100px", md: "100px", sm: "50px", xs: "50px" },
                flexWrap: "wrap",
                overflow: 'hidden'
              }}
            >

              <ServiceListSlider
                serviceList={familyServices}
                setIsActive={setIsActive}
                isActive={isActive} />
            </Box>

            {/* Description */}
            <Box className='container'>
              <Typography className='font700 heading'
                sx={{ mb: { lg: '32px', md: '24px', sm: '18px', xs: '18px' } }}>
                {services[isActive].heading}
              </Typography>
              {((services[isActive]?.description) ?
                (<Typography className='font400 p1 preLine'
                  sx={{ mb: { lg: '32px', md: '24px', sm: '18px', xs: '18px' } }}>
                  {services[isActive].description}
                </Typography>) : null)}

              <Box className='flexcol'
                sx={{
                  rowGap: { lg: '32px', md: '24px', sm: '18px', xs: '18px' }
                }} >
                {services[isActive]?.content.map((el, ind) => {
                  return (
                    <Box key={`service-${ind}`}>
                      <Typography key={`service-${el.subHeading}-${ind}`}
                        className='font600 subHeading preLine'
                        sx={{ mb: { lg: '24px', md: '20px', sm: '16px', xs: '16px' } }}>
                        {el.subHeading}
                      </Typography>
                      <Typography key={`serviceDes-${el.subHeading}-${ind}`}
                        className='font400 p1 preLine'>
                        {el.subDesc}
                      </Typography>
                    </Box>
                  )
                })
                }

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
   {/* How it works? */}
   <Box sx={{ maxWidth: "100%" }}>
        <Box className="container">
          <Box
            className="flexcol"
            sx={{
              rowGap: { lg: "72px", md: "64px", sm: "48px", xs: "32px" },
              mb: "40px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              className="font700 heading">
              How it works?
            </Typography>
            <Box
              // className="flexrow"
              sx={{
                // rowGap: { md: "80px", sm: "48px", xs: "32px" },
                // columnGap: "50px",
                // px: { lg: "0px", xs: "0px" },
                // flexWrap: "wrap",
                // justifyContent: "center",
                width:'100%'
              }}
            >
              {/* <Benefits benefits={benefits} /> */}
              <HowItWorks/>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* faq section */}
      <FAQ ques={faq} />
      {/* cta section */}
      <CTA user={true} appLink={"https://play.google.com/store/apps/details?id=com.indialegal.user"} />
    </>
  )
}

export default FamilyandPersonalpage
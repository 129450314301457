import React from "react";
import { Box, Button, Typography } from "@mui/material";
import downArrow from "../../assets/logo/arrow-circle-down.svg";
import Gradient from "../../assets/image/gradient.svg";
import LawyerImage from "../../assets/logo/LawyerPage.png";
import Download from "../../component/downloadButton";
import AboutUsImage from "../../assets/image/aboutusteam.png";
import { benefits } from "./constant";
import Benefits from "../propertypage/benafits";
import CardAboutUs from "./CardAboutUs";
import Testimonials from "../../component/testimonials";
import { faq, reviewLawyer } from "./constant";
import Faq from "../../component/Faq";
import CTA from "../../component/cta";

const LawyerPage = () => {
  return (
    <>
      {/* Hero Section */}
      <Box
        sx={{
          maxWidth: "100%",
          mt: { lg: "112px", md: "80px", sm: "72px", xs: "56px" },
          minHeight: { lg: "570px", md: "484px", sm: '363px', xs: '363px' },
        }}
      >
        <Box className="container">
          <Box
            className="flexcol"
            sx={{
              maxWidth: "100%",
              justifyContent: "center",
              width: "100%",
              minHeight: { lg: "607px", md: "570px", sm: "484px", xs: "363px" },
              backgroundImage: `url(${LawyerImage})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            {/* <Box
              sx={{
                width: { lg: "64px", md: "48px", sm: "42px" },
                height: { lg: "64px", md: "48px", sm: "42px" },
                position: "absolute",
                left: "50%",
                top: "80%",
                display: { xs: "none", sm: "block" },
              }}
            >
              <img className="imageSize" src={downArrow} alt="DownArrow" />
            </Box> */}
            <Box
              className="flexcol"
              sx={{
                // pt: '104px',
                justifyItems: "center",
                maxWidth: {
                  lg: "500px",
                  md: "425px",
                  sm: "320px",
                  xs: "300px",
                },
                pl: { lg: "38px", md: "28px", sm: "24px", xs: "20px" },
                rowGap: { lg: "42px", md: "28px", sm: "24px", xs: "20px" },
              }}
            >
              <Box
                className="flexcol"
                sx={{
                  rowGap: "16px",
                }}
              >
                <Typography className="font700 bannerHeading">
                Join a community of legal experts.
                  {/* <span style={{ color: "rgba(209, 46, 41, 1)" }}>
                    legal aid
                  </span> */}
                  
                </Typography>
                <Typography
                  className="font400 p1">
                  Take your practice to new heights with us!
                </Typography>
              </Box>
              <Box sx={{}}>
                <Download name={"Download App"}
                applink={'https://play.google.com/store/apps/details?id=com.indialegal.lawyer'} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* OurMission */}
      <Box
        className="container"
        sx={{
          display: "flex",
          gap: {lg:"40px",md:'40px',sm:'24px',xs:'24px'},
          mt: { lg: "120px", md: "80", xs: "56px", sm: "72px" },
          flexDirection: {
            lg: "row",
            md: "row",
            sm: "row",
            xs: "column",
          },
        }}
      >
        <Box
          sx={{
            width: {lg: "50%",md: "50%",sm: "50%", xs: "100%", },
            display: {sm: 'block', xs: 'none', },
            mt:{sm:'16px',xs:'0px',md:'0px'}
          }}
        >
          <img
            src={AboutUsImage}
            alt="team-img"
            style={{ width: "-webkit-fill-available" }}
          />
        </Box>
        <Box className='flexcol'
          sx={{
            rowGap: {md:"16px",sm:'0px',xs:'16px'},
            width: {lg: "50%",md: "50%", sm: "50%", xs: "100%", },
          }}
        >
          <Typography
            variant="h2"
            className="font700 heading"
          // sx={{
          //   fontSize: { lg: "32px", md: "32px", sm: "32px", xs: "20px" },
          // }}
          >
            Our mission
          </Typography>
          <Box
            sx={{
              width: { lg: "50%", md: "50%", sm: "50%", xs: "100%", },
              display: { sm: 'none', xs: 'block', }
            }}
          >
            <img
              src={AboutUsImage}
              alt="team-img"
              style={{ width: "-webkit-fill-available" }}
            />
          </Box>
          <Typography className="font400 p1"
          >
            Our mission at India Legal App is to offer free legal aid to economically weaker section along with customisable legal services for others. So if you want to contribute to our mission in making legal service simple and available to everyone, join our team
          </Typography>

          <Box sx={{ mt: { lg: '52px', md: '42px', sm: '8px', xs: '32px' } }}>
            <Download 
            name={"Join our community!"}
            applink={'https://play.google.com/store/apps/details?id=com.indialegal.lawyer'} />
          </Box>
        </Box>
      </Box>
      {/* Matter */}
{/* 
      <Box
        sx={{
          backgroundColor: "#F7E3E340",
          pt: { lg: "40px", md: "40px", sm: "30px", xs: "20px" },
          pb: { lg: "40px", md: "40px", sm: "30px", xs: "20px" },
          mb: { sm: "80px", lg: "80px", xs: "56px", sm: "72px" },
          mt: { sm: "80px", lg: "80px", xs: "56px", sm: "72px" },
        }}
      >
        <Box sx={{ pb: "60px" }}>
          <Typography
            variant="h2"
            sx={{ textAlign: "center" }}
            className="heading font700"
          >
            Numbers that matter
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "50px",
            textAlign: "center",
            flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" }
          }}
        >
          {[1, 2, 3].map((i) => (
            <Box>
              <Typography
                variant="h2"
                className="font700 heading"
              // sx={{ fontSize: "40px" }}
              >
                1000+
              </Typography>
              <Typography className="font500" sx={{ fontSize: "16px" }}>
                Lawyers Associated
              </Typography>
            </Box>
          ))}
        </Box>
      </Box> */}
      {/* why choose us/ */}
      <Box sx={{ maxWidth: "100%", margin: "100px 0px"}}>
        <Box className="container">
          <Box
            className="flexcol"
            sx={{
              rowGap: { lg: "70px", md: "64px", sm: "48px", xs: "32px" },
              mb: "40px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box>

            <Typography
              className="font700 heading" >
              Why <span style={{color:'rgba(209, 46, 41, 1)'}}>use</span> India Legal ?
            </Typography>
            <Typography className="p1 flexcenter" mt={3}>
                As an advocate who has registered on the India Legal Lawyer
                service provider app, you would be able to access a variety of
                features and services, such as:
              </Typography>
              </Box>
            <Box
              className="flexrow"
              sx={{
                rowGap: { md: "80px", sm: "48px", xs: "32px" },
                columnGap: "50px",
                px: { lg: "0px", xs: "0px" },
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Benefits benefits={benefits} />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Features lawyer */}
      {/* <Box
        sx={{
          mt: { lg: "50px", md: "50px", sm: "50px", xs: "20px" },
          mb: { lg: "50px", md: "50px", sm: "50px", xs: "20px" },
        }}
      >
        <CardAboutUs />
      </Box> */}
      {/* <Testimonials review={reviewLawyer} /> */}
      <Faq ques={faq} />
      <CTA user={false} appLink={"https://play.google.com/store/apps/details?id=com.indialegal.lawyer"}/>
    </>
  );
};

export default LawyerPage;

import { Box, Typography } from "@mui/material";
import React from "react";
import email from "../../assets/logo/email.svg";
import location from "../../assets/logo/location.svg";
import call from "../../assets/logo/call.svg";
import phone from "../../assets/logo/phone.svg";

const InfoComponent = () => {
  return (
    <Box
      className="flexcol"
      sx={{
        maxWidth: { xs: "300px", sm: "320px", md: "400px", lg: "480px" },
        background: "#D12E29",
        padding: { xs: "20px", sm: "30px", lg: "40px" },
        mx: { xs: "20px" },
        color: "white",
        borderRadius: "10px",
        rowGap: { xs: "20px", sm: "44px", md: "56px" },
      }}
    >
      <Typography sx={{ fontSize: { xs: "32px", sm: "42px" } }}>Info</Typography>
      <Box className="flexrow" sx={{ alignItems: "center", gap: "18px" }}>
        <Box sx={{ textAlign: "center" }}>
          <img src={email} />
        </Box>
        <a
          className="font500"
          style={{
            textDecoration: "none",
            fontSize: '20px',
            lineHeight: "1.5",
            color: "#ffff",
          }}
          href="mailto: legal@indialegalapp.com"
        >
          {" "}
          legal@indialegalapp.com
        </a>
        {/* <Typography sx={{fontSize: {md: "20px"}}}>legal@indialegalapp.com</Typography> */}
      </Box>
      <Box className="flexrow" sx={{ alignItems: "center", gap: "28px" }}>
        <Box sx={{ textAlign: "center" }}>
          <img src={phone} />
        </Box>
        <Typography sx={{ fontSize: { md: "20px" } }}>
          <a style={{
            textDecoration: "none",
            fontSize: '20px',
            lineHeight: "1.5",
            color: "#ffff",
          }} href='tel:+91 9811776358'>+91-9891226611</a></Typography>
      </Box>
      <Box className="flexrow" sx={{ alignItems: "center", gap: "16px" }}>
        <img src={call} />
        <Typography sx={{ fontSize: { md: "20px" } }}>
          <a style={{
            textDecoration: "none",
            fontSize: '20px',
            lineHeight: "1.5",
            color: "#ffff",
          }} href='tel:+91-0120-2471400-432'>+91-0120-2471400-432</a></Typography>
      </Box>
      <Box className="flexrow" sx={{ gap: "28px" }}>
        <Box>
          <img src={location} />
        </Box>
        <Typography sx={{ fontSize: { md: "20px" } }}>
          NOIDA HEAD OFFICE
          <br />
          A -9, Sector-68, Gautam Buddh <br />
          Nagar,
          <br />
          NOIDA (U.P.) 201301
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoComponent;

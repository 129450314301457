// import "./App.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Input } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import DownloadButton from "./downloadButton";
import CommonInput from "./commonInput";
import { useNavigate } from "react-router";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "60%", md: "60%", sm: "75%", xs: "75%" },
  // height: {lg:"75%", md:"65%",},
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 4,
  textAlign: "center",
  padding: { lg: "50px", md: "40px", sm: "30px", xs: "20px" },
};

function App({ 
  title, subtitle, open, submitted, setOpen,
submitResponse}) {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      navigate('/')
    }, 3000);
  }

  return (
    <Box>
      {/* <Button onClick={handleOpen} className="font700">
        Click Modal
      </Button> */}
      {submitted ? (

        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography className="heading font700" variant="h6" component="h2">
                Thank you for submitting your query!!
              </Typography>
            </Box>
          </Modal>
        </>) : (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography className="heading font700" variant="h6" component="h2">
                {title}
              </Typography>
              <Typography
                className="font400 p3"
                sx={{ pb: { lg: "45px", md: "45px", sm: "20px", xs: "20px" } }}>
                {subtitle}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <CommonInput placeholder="Enter Name" type="text" />
                <CommonInput placeholder="Enter email id" type="text" />
                <CommonInput placeholder="Contact number" type="text" />
              </Box>
              <Box sx={{ display: "flex", pt: "30px", pb: "15px", gap: "10px" }}>
                <CheckBox />
                <Typography sx={{ color: "#BBBBBB" }} className="font400">
                  I agree
                </Typography>
              </Box>
              <Box>
                <DownloadButton name={"Submit"} />
              </Box>
            </Box>
          </Modal>
        </>)
      }

    </Box >

  );
}

export default App;

import { React } from 'react'
import { Box, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
const ServiceListSlider = ({ serviceList, setIsActive, isActive }) => {
  const lists = JSON.parse(serviceList);
  const setActiveTab = (i) => {
    switch (i) {
      case isActive: return ('activeServiceTab'); break;
      default: return ('serviceTab');
    }
  }
  return (
    <Box sx={{ width: '100%', height:{lg:'54px'} }}>
      <Swiper
        spaceBetween={32}
        modules={[Navigation, Pagination]}
        navigation={true}
        className='myServiceListSwiper'
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          // when window width is >= 640px
          320: {
            width: 190,
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            width: 634,
            slidesPerView: 3,
          },
          1024: {
            width: 856,
            slidesPerView: 4,
          },
          1201: {
            width: 1078,
            slidesPerView: 5,
          },
        }}
      >
        {lists.map((el, i) =>

          <SwiperSlide key={`serviceSlide-${i}`}>
            <Box key={`list-${i}`} className={setActiveTab(i)}
              onClick={() => setIsActive(i)}
            >
            {/* //   <Typography> */}
                {el.heading}
                {/* </Typography> */}
            </Box>
          </SwiperSlide>
        )}
      </Swiper>
    </Box>
  )
}

export default ServiceListSlider
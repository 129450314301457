import profile from '../../assets/logo/profile.svg'
import clientManagement from '../../assets/logo/client-mgmt.svg'
import networking from '../../assets/logo/networking.svg'
import setting from '../../assets/logo/setting-2.svg'


export const faq = JSON.stringify([{
    id: "1", ques: "What is India Legal App? ",
    ans: "India Legal App offers free legal aid to economically weaker section. Along with customisable legal services for others. "
},
{
    id: "2", ques: "Is India Legal App FREE? ",
    ans: "Yes, it is free App you can download it from Play Store and App Store. "
},
{
    id: "3", ques: "What services India Legal App provides? ",
    ans: "We offer legal service that helps you create legal documents and get legal advice as fast as possible. These are the most popular services - Talk to Lawyer, Business and Contracts,Real Estate,Startup, Family and Personal."
},
{
    id: "4", ques: "What is IL Suraksha Kavach? ",
    ans: "As the name says, IL Suraksha Kavach is an affordable legal protection plan of India Legal App. In this legal protection plan, we combine various legal services such as Free Consultation for 30 mins, Rent Agreements, Affidavits, Study Material for Law Students, Legal Magazine Access, Exclusive Video content, Exclusive Legal Talk and access to group events."
},
{
    id: "5", ques: "What is SOS Service? ",
    ans: "SOS service is FREE legal dedicated helpline, working 24x7. Whenever you require any kind of legal help, our dedicated team is always available to help you"
},
{
    id: "6", ques: "What is Talk to Lawyer? ",
    ans: "On phone instant audio, video legal consultation from experienced advocates. It’s a simple process - book your mins, select your advocate, and get legal advice."
}])

export const reviewLawyer = [
    { review: 'App is very useful to help me in my legal advice and using the app is also very simple.', reviwerName: 'Shubham Sharma', reviwerType: 'Service that customer availed' },
    { review: 'I recently used a legal service on India Legal App and I am extremely satisfied with the experience.', reviwerName: 'Nivideta Singh', reviwerType: 'Service that customer availed' },
    { review: 'Definitely recommend using India Legal. The Lawyer I spoke with was very knowledgeable and suggested me with perfect solutions.', reviwerName: 'Vandana Tomar', reviwerType: 'Service that customer availed' },
    { review: 'App is very useful to help me in my legal advice and using the app is also very simple.', reviwerName: 'Shubham Sharma', reviwerType: 'Service that customer availed' },
    { review: 'I recently used a legal service on India Legal App and I am extremely satisfied with the experience.', reviwerName: 'Nivideta Singh', reviwerType: 'Service that customer availed' },
    { review: 'Definitely recommend using India Legal. The Lawyer I spoke with was very knowledgeable and suggested me with perfect solutions.', reviwerName: 'Vandana Tomar', reviwerType: 'Service that customer availed' },

]

export const benefits = [{ id: '14', image: profile, heading: "Profile creation", subHeading: 'You can create your profile on the app, which will include your professional details, such as your education, experience, and areas of expertise. ' },
{ id: '8', image: clientManagement, heading: "Client management", subHeading: `You can manage your clients cases' and related documents on the app.` },
{ id: '9', image: networking, heading: "Networking opportunities", subHeading: "You may be able to connect with other lawyers, law firms, and legal professionals through the app's networking features" },
{ id: '9', image: setting, heading: "Marketing tools", subHeading: "Also the app may offer to help you promote your legal services" },
]
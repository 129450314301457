import React from 'react';
import { Box, Typography } from '@mui/material';
const benefits = ({ benefits }) => {
    return (
        <>
            {benefits.map((el, ind) => {
                return (
                    <Box key={`benefits-wrapper-${ind}`}
                        className="flexcol"
                        sx={{
                            width: {md: '260px', xs: '100%px' },
                            // height: { md: '206px', xs: '154px' },
                            height: 'auto',
                            rowGap: { md: '24px', sm: '18px', xs: '14px' }, alignItems: 'center'
                        }}>
                        <Box key={`benfits-box-${ind}`}
                            sx={{
                                width: { md: '96px', xs: '72px', sm: '84px' },
                                minHeight: { md: '96px', xs: '72px', sm: '84px' },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: "center",
                                borderRadius: { lg: '16px', xs: '12px' },
                                background: 'rgba(247, 227, 227, 0.25)'
                            }}>
                            <Box key={`benfits-image-div${ind}`}
                                sx={{
                                    width: { md: '48px', xs: '36px', sm: '28px' },
                                    height: { md: '48px', xs: '36px', sm: '28px' }
                                }}>
                                <img key={`benfits-image-${ind}`}
                                className="imageSize" src={el.image} alt={el.heading} />
                            </Box>
                        </Box>
                        <Box key={`${el.heading}-${ind}`}
                            className="flexcol"
                            sx={{ rowGap: '8px', alignItems: 'center' }}>
                            <Typography key={`benefit-${el.heading}`}
                                className='font600 subHeading'
                                sx={{
                                    textAlign: 'center'
                                }}>
                                {el.heading}</Typography>
                            <Typography key={`benefit-desc-${el.heading}`}
                                className="font400 p3"
                                sx={{
                                    textAlign: 'center'
                                }}>
                                {el.subHeading}</Typography>
                        </Box>
                    </Box>
                )
            })}
        </>
    )
}

export default benefits
import React from 'react';
import { Box, Typography } from '@mui/material';
// import Diamond from "../../../assets/logo/Diamond.svg";
const benefits = ({ benefits }) => {
    return (
        <>
            {benefits.map((el, ind) => {
                return (
                    <Box key={`benefits-wrapper-${ind}`}
                        className="flexcol"
                        sx={{ width: '256px', rowGap: '24px', alignItems: 'center', }}>
                        <Box key={`benfits-box-${ind}`}
                        sx={{
                            // width: '96px',
                            // height: '96px !important',
                            padding: {lg:"32px",md:'28px',sm:'24px', xs:'18px'},
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: "center",
                            borderRadius: { lg: '16px', md: '12px', xs:'4px'},
                            background: 'rgba(247, 227, 227, 0.25)'
                        }}>
                            <img key={`benfits-image-${ind}`}
                            src={el.image} alt="service_icon" style={{
                                width: "40px"
                            }} />
                        </Box>
                        <Box className="flexcol"
                            sx={{ rowgap: '8px', alignItems: 'center' }}>
                            <Typography key={`benefit-${el.heading}`}
                            className='font600 subHeading'>{el.heading}</Typography>
                            <Typography key={`benefit-desc-${el.heading}`}
                            className='font400 p3' sx={{ textAlign: 'center' }}>{el.subHeading}</Typography>
                        </Box>
                    </Box>
                )
            })}
        </>
    )
}

export default benefits
import propertyImg from "../../assets/image/property2ndImg1.webp";

export const BOX2ND = {
  // fontWeight: 400,
  // fontSize: "16px",
  // lineLeight: "140%",
  // letterSpacing: "0.02em",
  // color: "#6C6C6C",
  paddingTop:"16px"
};
export const BCKIMG = {
  backgroundSize:"contain",
  backgroundRepeat:"no-repeat",
  backgroundImage: `url(${propertyImg})`,

}
export const SERVICEHEADING = {
  color: "#D12E29",
  fontWeight: 700,
fontSize: "16px",
lineHeight: "20px"
};
export const SERVICE2NDHEADING = {
  fontWeight: 700,
// fontSize: "32px",
lineHeight: "140%",
letterSpacing: "0.02em"
};
export const SERVICE2NDPARA = {
  fontweight: 400,
// height: "200px",
lineHeight: "140%",
letterSpacing: "0.02em"
}
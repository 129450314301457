import { React, useState, useRef, useEffect } from "react";
import logo from "../assets/logo/logo.svg";
import { Box, Typography } from "@mui/material";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import hamburgerIcn from "../assets/logo/hamburgerIcon.svg";
import Cross from "../assets/logo/cross.svg";
import DownloadButton from "./downloadButton";
import { ContactSupportOutlined } from "@mui/icons-material";


const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const ref = useRef();
  const { pathname } = useLocation();
  const [selectedItem, setSelectedItem] = useState("");
  const nav = [
    { id: "2", name: "IL Suraksha Kavach", link: "/IL-suraksha-kavach" },
    { id: "3", name: "Talk to Lawyer", link: "/talk-to-lawyer" },
    { id: "4", name: "Family & Personal", link: "/family-and-personal" },
    { id: "5", name: "Real Estate ", link: "/real-estate" },
    { id: "6", name: "Business & Contracts ", link: "/buisness-and-contracts" },
    { id: "7", name: "Start Up", link: "/start-up" },
    { id: "8", name: "Lawyer Page", link: "/lawyer" },
  ];

  useEffect(() => {
    let item = null;
    nav.forEach((element) => {
      if (element.link === pathname) {
        item = element;
      }
    });

    if(item) {
      setSelectedItem(item.id)
    }
    else {
      setSelectedItem("")
    }
  }, [pathname]);

  
  const handleNav = (navItem) => {
    setSelectedItem(navItem.id);
    // navigate(`${navItem.link}`)
  };

  return (
    <>
      <Box
        sx={{
          display: "block",
          width: "100%",
          // overflow: 'hidden',
          top: "0",
          backgroundColor: "#fff",
          zIndex: "1000",
          position: "fixed",
          px: { lg: "10px", md: "0px" },
        }}
      >
        <Box
          className="container"
          sx={{
            height: { sm: "80px", lg: "112px", xs: "56px", sm: "72px" },
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "space-between", xs: "space-between" },
            columnGap: { md: "60px", lg: "70px" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: { md: "120px", sm: "90px", xs: "84px" },
              height: { md: "44px", xs: "31px", sm: "33px" },
            }}
          >
            <Link
              className="noDecoration"
              to="/"
              onClick={() => setSelectedItem("")}
            >
              <img className="imageSize" src={logo} alt="logo" />
            </Link>
          </Box>
          <Box
            className="navGap"
            sx={{
              display: { md: "flex", sm: "none", xs: "none" },
              // columnGap: { lg: '32px', md: '18px', sm: '12px' }
            }}
          >
            {nav.map((el, ind) => {
              return (
                <Link
                  key={`${el.id}-${ind}`}
                  className="noDecoration"
                  onClick={() => handleNav(el)}
                  to={el.link}
                >
                  {el.id == 8 ? (
                    <Typography
                      key={`${el.name}-${ind}`}
                      className="font700 navP"
                      sx={{
                        borderBottom: selectedItem === el.id && "3px solid black",
                        color: "#D12E29",
                        lineHeight: "1.5",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      {el.name}
                    </Typography>
                  ) : (
                    <Typography
                      key={`${el.name}-${ind}`}
                      className="font600 navP"
                      sx={{
                        color: "#6C6C6C",
                        lineHeight: "1.5",
                        cursor: "pointer",
                        textDecoration: "none",
                        borderBottom: selectedItem === el.id && "3px solid red",
                      }}
                    >
                      {el.name}
                    </Typography>
                  )}
                </Link>
              );
            })}
          </Box>

          <Box
            className="hamburgerIcon"
            sx={{
              display: { md: "none", xs: "block" },
            }}
            onClick={() => {
              setIsMobile(!isMobile);
              // (isMobile?  ref.current.style.animationName="menu" : ref.current.style.animationName="close" )
            }}
          >
            {isMobile ? (
              <img className="imageSize" src={Cross} alt="menu" />
            ) : (
              <img className="imageSize" src={hamburgerIcn} alt="menu" />
            )}
          </Box>
          {/* <downloadButton /> */}
          <Box
            ref={ref}
            className="flexcol menu"
            sx={{
              display: isMobile ? "flex" : "none",
              rowGap: "24px",
              pt: "24px",
              pl: "20px",
            }}
            onClick={() => {
              setIsMobile(!isMobile);
            }}
          >
            {nav.map((el, ind) => {
              return (
                // <Box sx={{position:'relative',top:'50%',left:'50%',transform:"translate(-50%, -50%)" }}>
                <Link
                  key={`el.id-${ind}`}
                  className="noDecoration"
                  to={el.link ? el.link : "#"}
                >
                  {el.id == 8 ? (
                    <Typography
                      className="font700"
                      sx={{
                        color: "#D12E29",
                        lineHeight: "1.5",
                        fontSize: "14px",
                        textDecoration: "none",
                      }}
                      key={el.id}
                    >
                      {el.name}
                    </Typography>
                  ) : (
                    <Typography
                      className="font600"
                      sx={{
                        color: "#6C6C6C",
                        lineHeight: "1.5",
                        fontSize: "14px",
                        textDecoration: "none",
                      }}
                      key={el.id}
                    >
                      {el.name}
                    </Typography>
                  )}
                </Link>
                // </Box>
              );
            })}
            {/* <DownloadButton name={'Download App'} /> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;

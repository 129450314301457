import React from 'react';
import { Box, Typography } from '@mui/material';
import Download from "../../../component/downloadButton";
import UpdatesImage from "../../../assets/image/ctaUpdatesImage.webp"
const Updates = () => {
  return (
    <Box sx={{ maxWidth: '100%', bgcolor: 'rgba(233, 233, 233, 0.25)', my: { lg: '80px', md: '64px', sm: '48px', xs: '32px' } }}>
      <Box className="container" sx={{}}>
        <Box className='flexrow'
          sx={{
            justifyContent: 'space-between',
            height: { lg: '492px', md: '418px', sm: '312px', xs: '312px', },
            alignItems: "center"
          }}>
          <Box className="flexcol"
            sx={{
              rowGap: { lg: '56px', md: '42px', sm: '28px', xs: '24px' }
            }}
          >
            <Box className="flexcol"
              sx={{
                width: { lg: '485px', md: '412px', sm: '309px', xs: '100%' },
                rowGap: '16px',
              }}>
              <Typography className='font700 heading' >Watch Exclusive Videos , Movies & Series related to Law and Crime.</Typography>
              <Typography className='font400 p1'>
                Videos relating to law and crime can now be enjoyed at the click of a button, anytime, anywhere on India Legal App.
              </Typography>
            </Box>
            <Box>
                <Download name={'Download App'}
                applink= {'https://play.google.com/store/apps/details?id=com.indialegal.user'} />
              </Box>
          </Box>
          <Box sx={{
            height: { lg: '420px', md: '380px', sm: '312px', xs: '312px', },
            width: { lg: '600px', md: '503px', sm: '400px', xs: '312px', },
            display: { xs: 'none', sm: 'block' }
          }}>
            <img className="imageSize" src={UpdatesImage} alt='updates' />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Updates
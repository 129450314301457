import React, { useState } from "react";
import { Input, TextField } from "@mui/material";

const CommonInput = ({ placeholder, type, onChange, value, variant, fieldName, ...rest}) => {
  let er = false;
  const [errorText, setErrorText] = useState("");
  const validateWhileInput = (fieldName, e) => {
    const reg = /^[0-9]+$/;
    const regName = /^[[A-Za-z .]+$/;
    const regAlpha = /^[[A-Za-z]+$/;
    switch (fieldName) {
      case "mobileNo":
        setErrorText((reg.test(e.target.value) ? "" : "Only numeric values are allowed"));
        break;
      default:
        return ""
    }
  }

  const validateAfterInput = (fieldName) => {
    const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    switch (fieldName) {
      case "email":
        setErrorText((emailRe.test(value) ? "" : "Please enter valid email"));
        break;
      case "mobileNo":
        setErrorText((value.length < 10) ? "Please enter 10 digit mobile number" : "");
        break;
      default:
        return ""
    }
  }
  if (errorText !== "") {
    er = true;
  }
  else {
    er = false;
  }
  return (
    <>
      < TextField
        placeholder={placeholder}
        name={fieldName}
        onChange={onChange}
        type={type ?? "text"}
        value={value}
        sx={{
          borderColor: "#B7B7B7",
          borderRadius: "8px",
          outline: "none",
          width: "100%",
          // padding: { lg: "14px", sm: "10px", xs: "8px" },
          fontSize: { lg: "14px", sm: "12px", xs: "12px" },
        }}
        inputProps={{
          ...rest,
          style: {
            fontFamily: 'Mullish !important',
            color: '#6C6C6C',
            fontWeight: 'font400',
            fontSize: '16px',
            lineHeight: '1.4',
            height:'48px'
          }
        }}
        onInput={(e) => validateWhileInput(fieldName, e)}
        onBlur={() => validateAfterInput(fieldName)}
        error={er}
        helperText={errorText}
      />
    </>
  );
};

export default CommonInput;

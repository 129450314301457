import { Box, Typography } from "@mui/material";
import React from "react";
import Download from "../../component/downloadButton";
import Faq from "../../component/Faq";
import blogs from "..//../assets/image/blogs-page.png";
import { faq } from "../homepage/constant";
import { faqBlogs } from "./constant";
import CTA from "../../component/cta";


const Blogs = () => {
  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          mt: { md: "150px", sm: "144px", xs: "72px" },
          // mb: { lg: "232px", md: "180px", sm: "90px", xs: "32px" },
        }}
      >
        <Box className="container" mb={{ md: "150px", sm: "90px", xs: "32px" }}>
          <Box
            className="flexrow"
            sx={{
              justifyContent: "space-between",
              // flexWrap:'wrap'
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "row",
                xs: "column-reverse",
              },
              gap: { sm: "40px", xs: "32px" },
            }}
          >
            <Box
              className="flexcol"
              sx={{
                rowGap: "40px",
              }}
            >
              <Box
                className="flexcol"
                sx={{
                  rowGap: "16px",
                  maxWidth: { md: "560px", sm: "400px" },
                  pt: { md: "40px", sm: "28px", xs: "0px" },
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="h1"
                  className="font700 bannerHeading"
                  color={"#D12E29 !important"}
                >
                  Blogs
                </Typography>
                <Typography className="heading font700">
                  Coming Soon...
                </Typography>
                {/* <Typography className="font400 p1">
                  Get legal assistance where you want it, when you need it by
                  talking to our India Legal network lawyer. Whether your case
                  involves Property & Real Estate, Check Bounce & Money
                  Recovery, Civil Matters, Criminal Matters, Cyber Crime,
                  Consumer Protection, Divorce & Child Custody, Employment
                  Issues, Company & Start-Ups and other legal problems. Our
                  lawyers will listen to your concerns, and will explain the
                  options available to you moving forward. You need not worry.
                </Typography> */}
              </Box>
              <Box
                className="flexcol"
                sx={{
                  rowGap: { md: "80px", sm: "64px", xs: "36px" },
                  alignItems: "flex-start",
                }}
              >
                {/* <Box
                  className="flexcol"
                  sx={{
                    rowGap: "16px",
                  }}
                >
                  <Box
                    className="flexrow"
                    sx={{
                      columnGap: "40px",
                      flexDirection: { xs: "column", sm: "row" },
                      rowGap: "18px",
                    }}
                  >
                    <Box
                      className="flexrow"
                      sx={{
                        columnGap: "10px",
                        width: "152px",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "16.67px", height: "16.67px" }}>
                        <img className="imageSize" src={tick} alt="tick" />
                      </Box>
                      <Typography
                        className="font600"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "1.4",
                          color: "#242424",
                        }}
                      >
                        Legal consultation
                      </Typography>
                    </Box>
                    <Box className="flexrow" sx={{ columnGap: "10px" }}>
                      <Box sx={{ width: "16.67px", height: "16.67px" }}>
                        <img className="imageSize" src={tick} alt="tick" />
                      </Box>
                      <Typography
                        className="font600"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "1.4",
                          color: "#242424",
                        }}
                      >
                        Realtime Reports
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className="flexrow"
                    sx={{
                      columnGap: "40px",
                      flexDirection: { xs: "column", sm: "row" },
                      rowGap: "18px",
                    }}
                  >
                    <Box
                      className="flexrow"
                      sx={{
                        columnGap: "10px",
                        width: "152px",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "16.67px", height: "16.67px" }}>
                        <img className="imageSize" src={tick} alt="tick" />
                      </Box>
                      <Typography
                        className="font600"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "1.4",
                          color: "#242424",
                        }}
                      >
                        Easy To Use
                      </Typography>
                    </Box>
                    <Box className="flexrow" sx={{ columnGap: "10px" }}>
                      <Box sx={{ width: "16.67px", height: "16.67px" }}>
                        <img className="imageSize" src={tick} alt="tick" />
                      </Box>
                      <Typography
                        className="font600"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "1.4",
                          color: "#242424",
                        }}
                      >
                        24x7 Call Support
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}
                <Download
                  name={"Download App"}
                  applink={
                    "https://play.google.com/store/apps/details?id=com.indialegal.user"
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: { lg: "510px", md:'434px', sm: "382px", xs: "auto" },
                // height:'auto',
                height: { lg: "449px",md:'382px', sm: "336px" },
                // backgroundImage: `url(${talkTolawyerBG})`,
                // backgroundImage: `url(${talkTolawyerBGN})`,
                // backgroundRepeat: 'no-repeat',
                // position: 'relative',
                // backgroundSize: 'contain'
                minHeight: "240px",
                objectFit: "contain",
              }}
            >
              <img
                src={blogs}
                alt="lawyer"
                style={{
                  width: "-webkit-fill-available",
                  height: "-webkit-fill-available",
                }}
              />
              {/* <Box sx={{ position: 'absolute', top: '50px', right: { md: '72px', sm: '56px' }, maxWidth: { xs: '324px' } }}>
                            </Box> */}
            </Box>
          </Box>
        </Box>
        <Faq ques={faqBlogs} />
        <CTA
          user={true}
          appLink={
            "https://play.google.com/store/apps/details?id=com.indialegal.lawyer"
          }
        />
      </Box>
    </>
  );
};

export default Blogs;

import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Modal, Grid } from "@mui/material";
import bgImage from "../../assets/image/ellipse_background.svg";
import ShareIcon from "../../assets/image/share.svg";
import download from "../../assets/image/download.svg";
import fb from "../../assets/image/fb.svg";
import wa from "../../assets/image/wa.svg";
import x from "../../assets/image/x.svg";
import { useLocation } from "react-router";

const CertificateDownloadPage = () => {
  const state = useLocation();
  const pdfUrl = state?.state?.pdfUrl;

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const imageUrl = state?.state?.imageUrl;

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "downloaded-pdf.pdf";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShareClick = () => {
    setShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setShareModalOpen(false);
  };
  const handleWhatsAppShare = () => {
    const title = "Your Certificate"; // Set your desired title
    const message = "Check out this PDF file: " + pdfUrl;

    let whatsappUrl = "https://wa.me/?text=";
    whatsappUrl += encodeURI(title + " - " + message);

    window.open(whatsappUrl, "_blank");
  };

  function onError(error) {
    console.error("Error loading PDF:", error);
  }

  return (
    <Box
      sx={{
        backgroundImage: `url(${bgImage})`,

        backgroundPosition: "50% 58%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        mt: { lg: "112px", md: "72px", sm: "72px", xs: "56px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: "#2F2F2F",
            fontSize: {
              xl: "28px",
              lg: "24px",
              md: "22px",
              sm: "18px",
              xs: "18px",
            },
            fontWeight: 500,
            fontFamily: "mulish",
            mt: { xl: "60px", lg: "48px", md: "42px", sm: "96px", xs: "72px" },
          }}
        >
          Your certificate is ready!
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { xl: "32px", lg: "28px", md: "24px", sm: "40px", xs: "24px" },
          width: {
            xl: "800px",
            lg: "640px",
            md: "568px",
            sm: "600px",
            xs: "272px",
          },
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <img
          src={imageUrl}
          loading="lazy"
          style={{
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { xl: "60px", lg: "48px", md: "42px", sm: "120px", xs: "120px" },

          pb: { xl: "108px", lg: "88px", md: "76px", sm: "311px", xs: "40px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            columnGap: "24px",
            rowGap: "20px",
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          {" "}
          <Button
            sx={{
              fontSize: {
                xl: "16px",
                lg: "14px",
                md: "12px",
                sm: "16px",
                xs: "14px",
              },
              color: "#FFFFFF",
              fontWeight: 700,
              fontFamily: "mulish",
              backgroundColor: "#D12E29",
              width: {
                xl: "172px",
                lg: "172px",
                md: "172px",
                sm: "172px",
                xs: "272px",
              },
              "&:hover": {
                background: "#8B0000",
                color: "#FFFFFF",
              },
            }}
            onClick={handleDownload}
          >
            <img
              src={download}
              alt="Download Icon"
              style={{ paddingRight: "10px" }}
            />
            Download
          </Button>
          <Button
            sx={{
              fontSize: "16px",
              color: "#D12E29",
              fontWeight: 700,
              fontFamily: "mulish",
              backgroundColor: "#FFFFFF",
              width: {
                xl: "172px",
                lg: "172px",
                md: "172px",
                sm: "172px",
                xs: "272px",
              },
            }}
            onClick={handleShareClick}
          >
            <img
              src={ShareIcon}
              alt="Share Icon"
              style={{ paddingRight: "10px" }}
            />
            Share
          </Button>
        </Box>
      </Box>

      {/* Share Modal */}
      <Modal open={shareModalOpen} onClose={handleCloseShareModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Share via:
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <img src={wa} alt="WhatsApp" onClick={handleWhatsAppShare} />
            </Grid>

            <Grid item>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  pdfUrl
                )}`}
                target="_blank"
              >
                <img src={x} alt="Facebook" />
              </a>
            </Grid>
            <Grid item>
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  pdfUrl
                )}`}
                target="_blank"
              >
                <img src={fb} alt="Twitter" />
              </a>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default CertificateDownloadPage;

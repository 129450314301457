import "./App.css";
import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Navbar from "./component/navbar";
import Footer from "./component/footer";
import HomePage from "./pages/homepage/Homepage";
import TalkToLawyer from "./pages/talkToLawyer/TalkToLawyer";
import SurakshaKavach from "./pages/surakshaKavach/SurakshaKavach";
import PropertyPage from "./pages/propertypage/property";
import CareerPage from "./pages/careerpage/careerpage";
import LawyerPage from "./pages/LawyersPage/LawyerPage";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import TermsCondition from "./pages/TermsCondition/TermsCondition";
import AboutUs from "./pages/AboutUs/AboutUs";
import StartUpPage from "./pages/StartUpPage/StartUpPage";
import BussnessContract from "./pages/BusinessContractPage/BusinessContract";
import FamilyandPersonalpage from "./pages/F&Perpage/FamilyandPersonalpage";
import JdPage from "./pages/careerpage/JdPage";
import ContactUs from "./pages/ContactUs/ContactUs";
import Blogs from "./pages/Blogs/Blogs";
import VoterPledge from "./pages/Voters/VoterPledge";
import PledgeDescription from "./pages/Voters/PledgeDescription";
import CertificateDownloadPage from "./pages/Voters/CertificateDownloadPage";
// import LawyerPage from './pages/lawyerPage/LawyerPage';
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#AA3030",
      },
    },
    typography: {
      fontFamily: `"Mullish", "Helvetica", "Arial", sans-serif`,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
      fontWeightExtraBold: 700,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1200,
        xl: 1440,
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="talk-to-lawyer" element={<TalkToLawyer />} />
          <Route path="IL-suraksha-kavach" element={<SurakshaKavach />} />
          <Route path="/real-estate" element={<PropertyPage />} />
          <Route path="/careers" element={<CareerPage />} />
          <Route path="/careers/:id" element={<JdPage />} />
          {/* <Route path="/sankalp/form" element={<VoterPledge />} /> */}
          {/* <Route path="/sankalp/pledge" element={<PledgeDescription />} /> */}
          <Route
            path="/sankalp/certificate"
            element={<CertificateDownloadPage />}
          />

          <Route path="/lawyer" element={<LawyerPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-&-condition" element={<TermsCondition />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/start-up" element={<StartUpPage />} ContactUs />
          <Route
            path="/buisness-and-contracts"
            element={<BussnessContract />}
          />
          <Route
            path="/family-and-personal"
            element={<FamilyandPersonalpage />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blogs" element={<Blogs />} />

          {/* <Route path='/lawyer' element={<LawyerPage />} /> */}
        </Routes>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;

import { axiosInstance } from "../axios";


export const postQuery = async (params) => {
    try {
      const { data } = await axiosInstance.post(`/api/v1/admin/pocket-services/visitor-query`,params);
      return data;
    } catch (e) {
      return {
        error: e,
      };
    }
  };

export const getAllJobs = async (params) => {
  try {
    const { data } = await axiosInstance.get(`/api/v1/job/open-jobs`,params);
    return data;
  } catch (e) {
    return {
      error: e,
    };
  }
}

export const jobApplication = async (params) => {
    try {
      const { data } = await axiosInstance.post(`api/v1/job/admin/application`,params);
      return data;
    } catch (e) {
      return {
        error: e,
      };
    }
  };
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  BCKIMG,
  BOX2ND,
  SERVICE2NDHEADING,
  SERVICE2NDPARA,
  SERVICEHEADING,
} from "./style";

import { benefits, reviewProperty, faqQues, startUpServices } from "./constant";
import Testimonials from "../../component/testimonials";
import Download from "../../component/downloadButton";
import tick from "../../assets/logo/tick.svg";
import Benefits from "./benafits";
import FAQ from "../../component/Faq";
import CTA from "../../component/cta";
import RightArrow from "../../assets/image/rightArrow.svg";
import LeftArrow from "../../assets/image/leftArrow.svg";
import ServiceListSlider from "../../component/ServiceListSlider";
import businessContractN from "../../assets/image/B&CHeroImg.webp"
import HowItWorks from "../../component/HowItWorks";
const BusinessContarct = () => {

  const services = JSON.parse(startUpServices);
  const [isActive, setIsActive] = useState(0);
  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          // mt: { md: "200px", sm: "160px", xs: "112px" },
          mt: { md: '150px', sm: '144px', xs: '72px' },
          mb: { lg: "60px", md: "60px", sm: "60px", xs: "32px" },
        }}
      >
        <Box className="container">
          <Box
            sx={{
              // p: { lg: "60px", md: "30px", sm: "20px", xs: "20px" },
              display: "flex",
              // alignItems: { lg: "center", md: "center", xs: "unset", sm: "center" },
              justifyContent: 'space-between',
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "row",
                xs: "column-reverse",
              },
              gap: { sm: "40px", xs: "32px" },
              // gap: { lg: "100px", md: "100px", sm: "64px", xs: "32px" },
            }}
          >
            {/* <Box
              sx={{
                width: { lg: "50%", md: "50%", xs: "100%", sm: "50%" },
                // pt: {lg:"0px", md:"0px", sm:"0",xs:"0"},
                pt: { md: '40px', sm: '28px', xs: '0px' },
              }}
            > */}
            <Box className="flexcol"
              sx={{
                rowGap: '40px',
              }}>
              <Box className="flexcol" sx={{
                rowGap: '16px',
                maxWidth: { md: '560px', sm: '400px' },
                pt: { md: '40px', sm: '28px', xs: '0px' },
                alignItems: 'flex-start',
              }}>

                <Typography
                  className="font700 bannerHeading"
                >
                  Business and Contract 
                </Typography>
                <Typography
                  className="font400 p1"
                >
                  Contracts are a crucial instrument in the business world for establishing and upholding
                  relationships between many parties, including suppliers, clients, employees, and
                  shareholders. Contracts can help minimise the likelihood of disagreements and
                  misunderstandings by ensuring that all parties to a transaction are aware of their
                  obligations and responsibilities. By having a well-drafted agreement in place,
                  companies can avoid potential disputes and unnecessary litigation.
                </Typography>
              </Box>
              <Box className="flexcol"
                                sx={{
                                    rowGap: { md: '80px', sm: '64px', xs: '36px' },
                                    alignItems: 'flex-start'
                                }}>
                                <Box className="flexcol"
                                    sx={{
                                        rowGap: "16px",
                                    }}>
                                    <Box className="flexrow" sx={{
                                        columnGap: '40px', flexDirection: { xs: 'column', sm: 'row' }, rowGap: "18px",
                                    }} >
                                        <Box className="flexrow" sx={{ columnGap: "10px", width: '152px', alignItems: "center" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Legal consultation
                                            </Typography>
                                        </Box>
                                        <Box className="flexrow" sx={{ columnGap: "10px" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Realtime Reports
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className="flexrow" sx={{ columnGap: '40px', flexDirection: { xs: 'column', sm: 'row' }, rowGap: "18px", }} >
                                        <Box className="flexrow" sx={{ columnGap: "10px", width: '152px', alignItems: "center" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Easy To Use
                                            </Typography>
                                        </Box>
                                        <Box className="flexrow" sx={{ columnGap: "10px" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                24x7 Call Support
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Download name={"Download App"} 
                                applink= {'https://play.google.com/store/apps/details?id=com.indialegal.user'}/>
                            </Box>
            </Box>
            <Box
              // sx={[
              //   BCKIMG,
              //   { width: { lg: "50%", md: "50%", sm:"382px",xs: "auto" },
              //   height: { md: '449px', sm: '336px', xs: "237px" }
              // },
              // ]}
              sx={{
                width: { md: '510px', sm: '382px', xs: "auto" },
                height: { md: '449px', sm: '336px'},
                minHeight: "240px",
                objectFit: 'contain'
              }}
            >
              <img
                src={businessContractN} alt="BuisnessContract"
                style={{ width: "-webkit-fill-available",height:"-webkit-fill-available" }}
                 />
            </Box>
          </Box>

          {/* serviceSection */}
          <Box sx={{ mt: { lg: "80px", md: "64px", sm: "40px", xs: "28px" } }}>
            <Box>
              <Typography
                className="font700"
                sx={{
                  fontSize: { lg: "32px", md: "32px", sm: "28px", xs: "28px" },
                  textAlign: "center",
                }}
              >
                Business and Contract Services
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
                mt: { lg: "40px", md: "40px", sm: "40px", xs: "28px" },
                mb: { lg: "100px", md: "100px", sm: "50px", xs: "50px" },
                flexWrap: "wrap",
                overflow: 'hidden'
              }}
            >

              <ServiceListSlider
                serviceList={startUpServices}
                setIsActive={setIsActive}
                isActive={isActive} />
            </Box>

            {/* Description */}
            <Box className='container'>
              <Typography className='font700 heading'
                sx={{ mb: { lg: '32px', md: '24px', sm: '18px', xs: '18px' } }}>
                {services[isActive].heading}
              </Typography>
              {((services[isActive]?.description) ?
                (<Typography className='font400 p1 preLine'
                  sx={{ mb: { lg: '32px', md: '24px', sm: '18px', xs: '18px' } }}>
                  {services[isActive].description}
                </Typography>) : null)}

              <Box className='flexcol'
                sx={{
                  rowGap: { lg: '32px', md: '24px', sm: '18px', xs: '18px' }
                }} >
                {services[isActive]?.content.map((el, ind) => {
                  return (
                    <Box key={`service-${ind}`}>
                      <Typography key={`service-${el.subHeading}-${ind}`}
                        className='font600 subHeading'
                        sx={{ mb: { lg: '24px', md: '20px', sm: '16px', xs: '16px' } }}>
                        {el.subHeading}
                      </Typography>
                      <Typography key={`serviceDes-${el.subHeading}-${ind}`}
                        className='font400 p1 preLine'>
                        {el.subDesc}
                      </Typography>
                    </Box>
                  )
                })
                }

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* How it works? */}
      <Box sx={{ maxWidth: "100%" }}>
        <Box className="container">
          <Box
            className="flexcol"
            sx={{
              rowGap: { lg: "72px", md: "64px", sm: "48px", xs: "32px" },
              mb: "40px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              className="font700 heading">
              How it works?
            </Typography>
            <Box
              // className="flexrow"
              sx={{
                // rowGap: { md: "80px", sm: "48px", xs: "32px" },
                // columnGap: "50px",
                // px: { lg: "0px", xs: "0px" },
                // flexWrap: "wrap",
                // justifyContent: "center",
                width:'100%'
              }}
            >
              {/* <Benefits benefits={benefits} /> */}
              <HowItWorks/>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Faq */}
      <FAQ ques={faqQues} />
      {/* cta section */}
      <CTA user={true} appLink={"https://play.google.com/store/apps/details?id=com.indialegal.user"} />
    </>
  )
}

export default BusinessContarct;


// {jdData.map((i,ind) => {
//   return(

//     <Box key={`Jd-${ind}`} className="flexcol" sx={{ gap: "24px" }}>
//       <Box key={`JdWrapper-${ind}`}>
//         <Typography key={ind} className="font700 bannerHeading flexcenter">
//           {/* {i?.heading} */}
         
//         </Typography>
//       </Box>
//        {/* {/* <Box className="flexcol" sx={{ gap: "10px" }}>
//         <Typography variant="h3" className="heading font700">
//           {i}
//         </Typography>
//         <Typography className="p2 font400">{i?.role}</Typography>
//       </Box> */}
//       <Box className="flexcol" sx={{ gap: "10px" }}>
//         <Typography variant="h3" className="heading font700">
//           {i}
//         </Typography>
//         <Typography className="p2 font400">
//           {i}
//         </Typography> 
//       </Box>
//     </Box>
// )
// })
// }
import React from "react";
import { Box, Typography } from "@mui/material";
import Store from "../assets/logo/Appstore.svg";
import topImage from "../assets/image/top_cta_img.webp";
import bottomImage from "../assets/image/bottom_cta_img.webp";
import Download from "./downloadButton";
import TopImage from "../assets/image/topImage.png";
import BottomImage from "../assets/image/BottomImage.svg";
import GooglePLay from "../assets/logo/google-play.svg";
import AppStore from "../assets/logo/app-store.svg";
const cta = ({ user,appLink}) => {
  return (
    <Box
      sx={{
        height: { md: "200px", sm: "166px", xs: "auto" },
        backgroundColor: "#FFEDED",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        className="container"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          overflow: "hidden",
          flexDirection: { xs: "column", sm: "row" },
          rowGap: "24px",
          py: { xs: "18px", sm: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
            justifyContent: "center",
            alignItems: "flex-start",
            minWidth: { lg: "423px", md: "342px" },
            px: { lg: "10px", md: "0px" },
          }}
        >
          <Typography className="font700 heading">
            Get Started with <span className="primary">IndiaLegal</span>
          </Typography>
          <Download
            name={"Download App"}
            applink={appLink}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            columnGap: { lg: "54px", md: "48px", sm: "32px" },
          }}
        >
          <Box
            sx={{
              width: { md: "158px", sm: "134px" },
              height: { md: "159px", sm: "135px" },
              mt: { md: "41px", sm: "35px" },
              display: { sm: "block", xs: "none" },
            }}
          >
            <img className="imageSize" src={BottomImage} alt="Lawyer section" />
          </Box>
          <Box
            sx={{
              width: { md: "158px", sm: "134px" },
              height: { md: "159px", sm: "138px" },
              display: { sm: "block", xs: "none" },
            }}
          >
            <img className="imageSize" src={TopImage} alt="Lawyer section" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box className="flexcol" sx={{ rowGap: "8px" }}>
              <Box
                sx={{
                  width: { md: "184px", xs: "156px" },
                  height: { md: "56px", xs: "48px" },
                  cursor: "pointer",
                }}
                onClick={() => {
                  user
                    ? window.open(
                        `https://play.google.com/store/apps/details?id=com.indialegal.user`,
                        "_blank"
                      )
                    : window.open(
                        `https://play.google.com/store/apps/details?id=com.indialegal.lawyer`
                      );
                }}
              >
                <img
                  className="imageSize"
                  src={GooglePLay}
                  alt="GooglePLay"
                  style={{ objectFit: "cover" }}
                />
              </Box>
              <Box
                sx={{
                  width: { md: "184px", xs: "156px" },
                  height: { md: "56px", xs: "48px" },
                  cursor: "pointer",
                }}
                onClick={() => {
                  user
                    ? window.open(
                      `https://apps.apple.com/in/app/india-legal/id6446165204`,
                        "_blank"
                      )
                    : window.open(
                        'https://apps.apple.com/in/app/india-legal-lawyer-app/id6446164962',
                        "_blank"
                      );
                }}
              >
                <img
                  className="imageSize"
                  src={AppStore}
                  alt="AppStore"
                  style={{ objectFit: "cover" }}
                />
              </Box>
            </Box>
            {/* <Box sx={{ cursor: 'pointer' }}
                onClick={() => window.open(`https://play.google.com/store/apps/details?id=com.indialegal.user`, '_blank')}>
                <img className='imageSize' src={Store} alt="appstore" />
              </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default cta;

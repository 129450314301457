import React from 'react';
import { Box, Typography } from '@mui/material';
import TestimonialCard from '../component/testimonialCard';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
// import required modules
import { Pagination, Navigation, Autoplay, } from 'swiper';
const testimonials = ({ review }) => {
  return (
    <Box
      sx={{ maxWidth: '100%', py: '48px' , backgroundColor: 'rgba(233, 233, 233, 0.12)', }}>
      <Box className="container">
        <Box className="flexcol "
          sx={{
            display: 'flex',
            rowGap: '60px',
            alignItems: 'center',
            overflow: 'hidden'
          }}>
          <Typography className='font700 subHeading'>
            Here is what our Users have to say.</Typography>
          <Box sx={{
            width: '100%', mx: 'auto',
            overflow: 'hidden'
          }}>
            <Swiper
              spaceBetween={24}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              // modules={[Pagination, Navigation, Autoplay,]}
              // autoplay={{ delay: 2000, disableOnInteraction: false, }}
              className='myReviewSwiper'
              breakpoints={{
                // when window width is >= 640px
                320: {
                  // width: auto,
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                  width: 402,
                  slidesPerView: 1,
                },
                1024: {
                  width: 828,
                  slidesPerView: 2,
                },
                1260: {
                  width: 1254,
                  slidesPerView: 3,
                },
              }}
            >
              {review.map((el, i) =>
                <SwiperSlide key={`slide-${i}`}>
                  <TestimonialCard review={el} />
                </SwiperSlide>
              )}
            </Swiper>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default testimonials
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <Box
        sx={{
          mt: { lg: "112px", md: "80px", sm: "72px", xs: "56px" },
          mb: { lg: "56px", md: "40px", sm: "36px", xs: "28px" }
        }}
      >
        <Box className="container">
          <Box>
            <Typography className="font700 heading flexstart">
              PRIVACY POLICY OF INDIA LEGAL APP
            </Typography>
          </Box>
          {/* <Box>
            <Typography
              className="font600 subHeading flexstart"
              sx={{ pt: "24px" }}
            >
              EFFECTIVE FROM …….. (DATE , MONTH)… 2023
            </Typography>
          </Box> */}
          <Box>
            <Typography
              className="preLine subHeading font700"
              sx={{ pt: "32px", display: "flex", gap: "2%", color: '#6C6C6C !important' }}
            >
              1.
              <span className="preLine ">
                BACKGROUND
              </span>
            </Typography>
            <Typography className="preLine p2">
              <br />
              <b> 1.1 </b> E N Communication Private Limited, having its office at A-9, Sector-68, Gautam Buddh Nagar, Noida, Uttar Pradesh – 201301, India ("us", "we", or "our") operates an application named “India Legal App” (the “App”). The Website and App, along with all associated software, design, servers, data, user inputs, and associated services shall collectively be known as the “Platform”. The services we provide to you via the Platform shall be known as the “Services”.
              <br />
              <br />
              <b>1.2</b> Your privacy is very important to us. We respect your privacy and believe that we should clearly and transparently disclose our privacy practices to you. This privacy policy (the “Privacy Policy”) explains our policies regarding the collection, use, and disclosure of your personal information, personally identifiable information, and other data collected from you when you use our Services. This Privacy Policy does not apply to any third-party websites or applications. Please note that you shall visit any third-party sites and apps at your own risk upon conditions laid down in their privacy policies, if any.
              <br />
              <br /> <b>1.3</b> We may change, modify, amend, terminate, or replace this Privacy Policy at any time at our discretion. If we change, modify, amend, or terminate this Privacy Policy, such modifications will become effective immediately upon posting to the Website and/or App. We will notify you of material changes in advance by email or by notice when you log on to the Website or the App.
              <br />
              <br /> <b>1.4</b> We will not use or share your information with anyone except as described in this Privacy Policy. We use your information for providing and improving the Services. By using the Services, you agree to the collection and use of information in accordance with this Privacy Policy.
              <br />
              <br /> <b>1.5</b> You agree to periodically review the current version of the Privacy Policy, as posted on the Website or the App. If you do not agree with the terms of this Privacy Policy, please do not use the Services.
              <br />
              <br /> <b>1.6</b> This Privacy Policy is to be read with, and is an integral part of, our Terms of Use available at 
              {" "} <a href="https://www.indialegalapp.com/terms-&-condition">
             https://www.indialegalapp.com/terms-&-condition
              </a>. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Use.
            </Typography>
          </Box>
          <Box>
            <Typography
              className="preLine subHeading font700"
              sx={{ pt: "32px", display: "flex", gap: "2%", color: '#6C6C6C !important' }}
            >
              2.
              <span className="preLine subheading">
                COLLECTION OF INFORMATION{" "}
              </span>
            </Typography>
            <Typography className="preLine p2"            >
              <br />
              <b>2.1 </b>
              While using our Services, we may ask you to provide us with certain personally identifiable information (“PII”) that can be used to contact or identify you. PII includes, but is not limited to, your name, phone number, postal address, email address, and other information that by itself, or in conjunction with other information, can be used to specifically identify you. However, PII does not include aggregated information that, by itself, does not permit identification.
              <br />
              <br />
              <b>2.2</b> Information that we collect when you use our Services
              includes:
              <br />
              <br /> (i) <b>Information provided by you</b>
              <br />
              When you sign up and register with us through the Website and/or App, we ask you for certain information. This includes your name, email address, mobile number, telephone number, details of company name & registration, company address, and details of legal mandates you wish to use our Services for.

              <br />
              <br />
              (ii) <b>Log Data</b>
              <br /> With the help of third parties like Google and Facebook, we
              collect information that your browser or mobile device sends to
              them whenever you visit our Services <b>("Log Data")</b>. This Log
              Data includes information such as your computer's Internet
              Protocol <b>("IP")</b>
              address, geolocation and geospatial data, browser type and browser
              version. In case you are accessing the App, we may collect certain
              mobile device information such as device ID, model number,
              operating system, global positioning data, application, and other
              related information. We also collect data on the pages of our
              Services that you visit, the time and date of your visit, the time
              spent on those pages, default language and user preferences, and
              other similar usage-related statistics. We may assign each user of
              the Services unique identifiers to help keep track of future
              visits. <br />
              <br />
              (iii) <b>Payment information</b>
              <br />
              Our Services require you to make payments on the Platform. When
              you are making any payments, we collect billing details from you
              such as your name, name of company, billing address, contact
              number, payment method and IP address, and then redirect you to a
              secure payment service provider platform operated by our payments
              partner(s) Razorpay, Paytm, etc and other payment partners which
              may be added time to time. We do not store any payment-related
              information such as credit/debit card details and/or bank details.
              <br />
              <br />
              (iv){" "}
              <b>
                Advertising, Use of Cookies, and Other Tracking Technologies
                (Cookie Policy)
              </b>{" "}
              <br /> We advertise in a number of ways, including online through managed social media presences, and on other unaffiliated sites and mobile applications. To understand how our advertising campaigns are performing, we may collect certain information via our Sites through our advertising service providers. We or our vendors use several common online tracking tools to collect information about our users, such as browser cookies, web beacons, and other similar technologies. The information we collect includes IP addresses, the number of page visits, pages viewed via our Sites, search engine referrals, browsing activities over time and across other websites following your visit to one of our Sites or applications, and responses to advertisements and promotions on the websites and applications where we advertise. We do not permit third parties to advertise on our Site. We also use certain information to:
              <br />
              <br /> <b>•</b> Identify new visitors to our Sites;
              <br /> <b>•</b> Recognise returning visitors;
              <br /> <b>•</b> Advertise on other websites and mobile
              applications not affiliated with us;
              <br /> <b>•</b> Analyze the effectiveness of our advertisements;{" "}
              <br />
              <b>•</b> Better understand our audience, customers, or other Site
              visitors; and <br />
              <b>•</b> Determine whether you might be interested in new products
              or services.
              <br />
              <br /> (v) <b>Controlling Our Tracking Tools.</b>
              <br /> Your browser may give you the ability to control cookies. How you do so, however, depends on your browser and the type of cookie. Certain browsers can be set to reject all browser cookies. If you configure your computer to block all cookies, you may disrupt certain web page features, and limit the functionality we can provide when you visit or use our Sites. If you block or delete cookies, not all of the tracking that we have described in this policy will stop. Some browsers have a “Do Not Track” feature that lets you tell websites that you do not want to have your online activities tracked. These browser features are still not uniform, so we are not currently set up to respond to those signals.
              <br />
              <br /> (vi) <b>
                Controlling Online Interest-Based Ads.
              </b> <br /> We sometimes work with online advertising vendors to provide you with relevant and useful ads. This may include ads served on other companies’ websites. These ads may be based on information collected by us or third parties. For example, your geographical location may be used to target an ad for people in your area. These ads may also be based on your activities on our Sites or on third-party websites..
              <br />
              <br />
              (vii)
              <b> Information received from others</b>
              <br /> We may combine information about you that we already have with information we obtain from other sources, to provide you a better end-user experience or to improve the quality of our Services. Examples of information we may receive from other sources include details of the Mandate Information available to us, customer support or enrolment information, page view, search term and search result information, and credit or identity information which we may use to detect fraud. Please note that we use Google Analytics and similar kinds of tools for the purpose of analytics and measurement of the Services. For example, we may use the data gathered from your usage of the Services by Facebook, Twitter, etc. to design new products or services, or to make our existing offerings under the Services more user-friendly.
              <br />
              <br /> <b>2.3</b> Apart from the information listed above and the Mandate Information made available to us while providing our Services, we do not collect any other sensitive information such as financial data, political views of any individuals, health-related information, or sexual orientation of any individuals, information related to race, caste, or language (Collectively,{" "}
              <b>“Sensitive Personal Information”</b>), unless required by the
              provisions of any applicable law
            </Typography>
            <Typography
              className="preLine subHeading font700"
              sx={{ pt: "32px", display: "flex", gap: "2%", color: '#6C6C6C !important' }}
            >
              3.
              <span className="preLine subheading">
                USE OF INFORMATION{" "}
              </span>
            </Typography>
            <Typography className="preLine p2">
              <br />
              We use your PII and other information collected from you for the following purposes:
              <br />
              <br /> i. To provide you with a customized and optimized
              experience with our Services; <br />
              <br />
              ii. To provide you with the functionalities of the Website, App, and Services in a proper manner;
              <br />
              <br /> iii. To enable us to provide our Services in a proper manner;
              <br />
              <br /> iv. To communicate with you for your queries or other customer services;
              <br />
              <br /> v. To provide you with marketing or promotional communications and advertising that we believe may be of interest to you;
              <br />
              <br /> vi. To use the information for security, fraud prevention, and investigative purposes;
              <br />
              <br /> vii. To prevent, detect, and investigate breaches of this Privacy Policy or any applicable laws;
              <br />
              <br /> viii. To use the information for research and development, analytics, measurement, and other business purposes to understand and improve our services.
              <br />
            </Typography>
            <Typography
              className="preLine subHeading font700"
              sx={{ pt: "32px", display: "flex", gap: "2%", color: '#6C6C6C !important' }}
            >
              4.
              <span className="preLine">
                STORAGE OF INFORMATION{" "}
              </span>
            </Typography>
            <Typography className="preLine p2">
              <br />
              We do not share your PII with any third parties except in the following circumstances:
              <br />
              <br /> i. With service providers who assist us in providing our Services, including our payment gateway providers;
              <br />
              <br /> ii. With legal or regulatory authorities, if required by law or to protect our rights or the rights of others;
              <br />
              <br /> iii. With your consent or at your direction. <br />


            </Typography>
            <Typography
              className="preLine subHeading font700"
              sx={{ pt: "32px", display: "flex", gap: "2%", color: '#6C6C6C !important' }}
            >
              5.
              <span className="preLine">
                DATA RETENTION AND DELETION POLICY{" "}
              </span>
            </Typography>
            <br />
            <Typography className="preLine p2">
              We retain your PII and other information for as long as necessary to provide you with our Services and to comply with our legal obligations. We may retain your PII for longer periods if it is necessary to protect our rights or the rights of others. You may request the deletion of your PII by contacting us at <a href="mailto:legal@indialegalapp.com">
                legal@indialegalapp.com
              </a>
            </Typography>
            <Typography
              className="preLine subHeading font700"
              sx={{ pt: "32px", display: "flex", gap: "2%", color: '#6C6C6C !important' }}
            >
              6.
              <span className="preLine">
                SECURITY
              </span>
            </Typography>
            <Typography className="preLine p2">
              <br />
              We take the security of your PII and other information seriously. We use appropriate technical and organizational measures to protect your PII from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.
            </Typography>
            <br />
            <br />
            <Typography
              className="preLine subHeading font700"
              sx={{ pt: "32px", display: "flex", gap: "2%", color: '#6C6C6C !important' }}
            >
              7.
              <span className="preLine">
                RIGHTS WITH RESPECT TO YOUR INFORMATION
              </span>
            </Typography>
            <Typography className="preLine p2">
              <br />
              <b>7.1</b> The laws of some countries grant particular rights in respect of your personal information. If you are a user situated in the European Union, you may have the right to:
              <br />
              <br />
              i. Request a copy of your information and data portability: This right entitles you to know whether we hold any PII about you and, if we do, to obtain a copy of such PII in a structured, commonly used and machine-readable format;
              <br />
              <br />
              ii. Request that your information is deleted: This right entitles you to request the deletion of PII, including in situations where such PII would no longer be necessary to achieve the purposes;
              <br />
              <br />
              iii. Request that we correct inaccuracies relating to your information: This right entitles you to have PII to be corrected if it is inaccurate or incomplete;
              <br />
              <br />
              iv. Object to our use of your information: This right entitles you to request that we no longer process your PII;
              <br />
              <br />
              v. Request that restrictions be placed on processing of PII: This
              right entitles you to request that we only process PII in certain
              circumstances, including with consent.
              <br />
              <br />
              <b>7.2 </b>If you wish to make a request in respect of your personal
              information, please contact us at{" "}
              <a href="mailto:legal@indialegalapp.com">
                legal@indialegalapp.com
              </a>. <br /> <br />
              <b>7.3</b> If, at any time, you prefer not to receive communications from
              us, simply follow the unsubscribe options at the bottom of each
              such communication or contact us directly at{" "}
              <a href="mailto:legal@indialegalapp.com">legal@indialegalapp.com</a>. We
              will make commercially reasonable efforts to implement your
              opt-out requests promptly, but you may still receive our emails
              for up to 7 (seven) working days as we process your request.
              <br /><br />
              <b>7.4</b> In some circumstances, we may not be able to comply with a request that you make in respect of your personal information. For example, we may not be able to provide a copy of your information where it infringes on the rights of another user. We may also be required to retain certain information that you ask us to delete for various reasons, such as where there is a legal requirement to do so.
              <br /><br />
              <b>7.5</b> If we are unable to resolve your request, or if you are concerned about a potential violation, you may be entitled to report the issue or make a complaint to the data protection authority in your jurisdiction.
            </Typography>
            <br />
            <br />
            <Typography
              className="preLine subHeading font700"
              sx={{ pt: "32px", display: "flex", gap: "2%", color: '#6C6C6C !important' }}
            >
              8.
              <span className="preLine">
                CHANGES TO THIS PRIVACY POLICY
              </span>
            </Typography>
            <Typography className="p2 preLine">
              <br />
              We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Policy on our Website and/or App. You are advised to review this Privacy Policy periodically for any changes.
            </Typography>
            <Typography
              className="preLine subHeading font700"
              sx={{ pt: "32px", display: "flex", gap: "2%", color: '#6C6C6C !important' }}
            >
              9.
              <span className="preLine ">
                CONTACT US
              </span>
            </Typography>
            
            <Typography className="p2 preLine">
              <br />
              If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
               <br />
               <br />
              E N Communication Private Limited
              <br />
              A-9, Sector-68, Gautam Buddh Nagar, Noida, Uttar Pradesh – 201301, India
              <br />
              Email: <a href="mailto:legal@indialegalapp.com">legal@indialegalapp.com</a>.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;

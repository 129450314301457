import React, { useState, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import { useParams, useNavigate, json, useLocation } from "react-router-dom";
import { careerOpp } from "./constant";
import Modal from "./JobDetailsForm";
const JdPage = () => {

  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { state } = useLocation();
  // const data = link;
  // let jdData = careerOpp.filter((item) => item.link === data);
  // const [desc, setDesc] = useState([]);
  // const job = JSON.parse(JSON.stringify(jdData));
  // useEffect(() => {
  //   const keysToDelete = ["department", "location", "link"];
  //   job.map((el) => {
  //     keysToDelete.forEach((key) => {
  //       delete el[key];
  //     });
  //   });
  //   setDesc(job);
  // }, []);

  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          mt: { md: "150px", sm: "144px", xs: "72px" },
          mb: { lg: "80px", md: "72px", sm: "64px", xs: "48px" },
        }}
      >
        <Box className="container">
          {/* {desc.map((i, ind) => { */}
          {/* return ( */}
          <Box className="flexcol" sx={{ gap: "24px" }}>
            <Box>
              <Typography
                // key={ind}
                className="font700 bannerHeading flexcenter"
              >
                {state?.job?.designation}
              </Typography>
            </Box>
            {/* {Object.entries(i).map(([key, value]) =>
                  key === "heading" ? null : (
                    <Box
                      key={`JdDescWrapper-${key}`}
                      className="flexcol"
                      sx={{ gap: "10px" }}
                    >
                      <Typography
                        key={`JdHead-${key}`}
                        variant="h3"
                        className="heading font700"
                      >
                        {key}
                      </Typography>
                      <Typography
                        key={`JdDesc-${key}`}
                        className="p2 font400 preLine"
                      >
                        {value}
                      </Typography>
                    </Box>
                  ) */}
            {/* )} */}
            <Typography  className="p2 font400 preLine" id="quill-description" dangerouslySetInnerHTML={{ __html: state?.job?.description }}>
              {/* {state?.job?.description} */}
            </Typography>
          </Box>
          {/* ); */}
          {/* })} */}
        </Box>
        <Box className="flexcenter" sx={{ marginTop: "5%" }}>
          <Button
            className="font700 flexrow"
            sx={{
              backgroundColor: "#D12E29",
              color: "#FFFFFF",
              textTransform: "none",
              height: { xs: "48px", sm: "36px", md: "48px" },
              width: { xs: "175px", sm: "140px", md: "175px" },
              lineHeight: "1.25",
              fontSize: { xs: "14px", sm: "12px", md: "16px", lg: "16px" },
              justifyContent: "center",
              alignItems: "cenyer",
              "&:hover": {
                backgroundColor: "#D12E29",
                borderColor: "#349F6E",
                color: "#349F6E",
                border: "1px",
              },
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            Apply Here
          </Button>
        </Box>
        {open ? (
          <Modal
            open={open}
            setOpen={setOpen}
            title={state?.job?.designation}
            jobId={state?.job?._id}
          />
        ) : null}
      </Box>
    </>
  );
};

export default JdPage;

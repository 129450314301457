import React from 'react';
import { Box, Typography } from "@mui/material";
const serviceIcon = ({ name, icon }) => {
    return (
        <Box className='flexcol'
            sx={{ rowGap: '10px', alignItems: 'center', width:{lg:'140px',sm:"160px",xs:'49%'}, textAlign:"center" }}>
            <Box sx={{
                width: { lg: '48px', md: '42px', sm: '32px', xs: '32px' },
                height: { lg: '48px', md: '42px', sm: '32px', xs: '32px' }, 
                // cursor: 'pointer'
            }}>
                <img className="imageSize" src={icon} alt='note' />
            </Box>
            <Typography className='font600'
                sx={{
                    fontSize: {lg:'16px',md:'14px',sm:'14px',xs:'14px'},
                    // lineHeight: '2.20',
                    color: '#6C6C6C',
                    // cursor: 'pointer'
                }}
            >
                {name}
            </Typography>
        </Box>
    )
}

export default serviceIcon
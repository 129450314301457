// import "./App.css";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { jobApplication } from "../../Shared/Services/UserService";
import CommonInput from "../../component/commonInput";
import { useNavigate } from "react-router";
import { Button, Input } from "@mui/material";
import Close from "../../assets/logo/close-modal.svg";
import "./style.css";
import { toastobj } from "../../Shared/constant";
import { ToastContainer, toast } from "react-toastify";
import InputField from "../../component/InputField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "60%", md: "60%", sm: "75%", xs: "75%" },
  // height: {lg:"75%", md:"65%",},
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 4,
  textAlign: "center",
  padding: { lg: "50px", md: "40px", sm: "30px", xs: "20px" },
};

function JobDetailsForm({ title, subtitle, open, setOpen, jobId }) {
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [disable, setDisable] = useState(false);

  const userData = {
    name: "",
    email: "",
    mobileNo: "",
    resume: "",
    // jobId: "",
    // jobId: `${title}`
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState(userData);

  useEffect(() => {
    if (!!data?.name && !!data?.email && !!data?.mobileNo) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [data]);

  const fetchData = (e) => {
    // setData([e.target.name], e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    const reg = new RegExp("^[0-9]+$");
    const val = reg.test(value);
    const formData = new FormData();
    let check = ["mobileNo"].includes(name);
    if (check == true && val == false) {
      if (value === "") setData({ ...data, [name]: "" });
      return;
    } else setData({ ...data, [name]: value });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getSelectedFile = async (e) => {
    setSelectedFile(e.target.files[0]);
    // setValue("resume", JSON.stringify(e.target.files[0]))
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("File", selectedFile);
    formData.append("name", data.name);
    formData.append("mobileNo", data.mobileNo);
    formData.append("email", data.email);
    formData.append("jobId", jobId);
    try {
      const resp = await jobApplication(formData);
      if (resp.code === 200) {
        toast.success("Applied successfully");
        setOpen(false);
      } else {
        toast.error(resp?.error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            className="flexrow"
            sx={{
              width: "100%",
              height: { md: "32px", xs: "24px" },
              justifyContent: "flex-end",
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={Close}
              alt="close"
              onClick={handleClose}
            />
          </Box>
          <Typography className="heading font700" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography
            className="font400 p3"
            sx={{ pb: { lg: "45px", md: "45px", sm: "20px", xs: "20px" } }}
          >
            {subtitle}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box>
              <CommonInput
                placeholder="Enter Name"
                value={data.name}
                fieldName="name"
                type="text"
                onChange={fetchData}
              />
              {/* <p className="error-message">{errors?.name?.message}</p> */}
            </Box>
            <Box>
              <CommonInput
                placeholder="Enter email id"
                fieldName="email"
                value={data.email}
                type="text"
                onChange={fetchData}
              />
              {/* <p className="error-message">{errors?.email?.message}</p> */}
            </Box>
            <Box>
              <CommonInput
                placeholder="Contact number *"
                fieldName="mobileNo"
                value={data.mobileNo}
                type="text"
                onChange={fetchData}
                maxLength={10}
              />

              {/* <p className="error-message">{errors?.mobileNo?.message}</p> */}
            </Box>
          </Box>
          <Box sx={{ display: "flex", pt: "30px", pb: "15px", gap: "10px" }}>
            <Box>
              <input
                type="file"
                //  style={{ display: "none" }}
                accept="application/pdf"
                name="resume"
                onChange={(e) => getSelectedFile(e)}
              ></input>
            </Box>
          </Box>
          {/* {<p className="error-message">{errors?.resume?.message}</p>} */}
          <Box>
            <Button
              className="font700 flexrow"
              disabled={disable}
              sx={{
                backgroundColor: "#D12E29",
                color: "#FFFFFF",
                textTransform: "none",
                height: { xs: "48px", sm: "36px", md: "48px" },
                width: { xs: "175px", sm: "140px", md: "175px" },
                lineHeight: "1.25",
                fontSize: { xs: "14px", sm: "12px", md: "16px", lg: "16px" },
                justifyContent: "center",
                alignItems: "cenyer",
                "&:hover": {
                  backgroundColor: "#D12E29",
                  borderColor: "#349F6E",
                  color: "#349F6E",
                  border: "1px",
                },
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default JobDetailsForm;

import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { BCKIMG } from "../propertypage/style";
import Benefits from "../surakshaKavach/component/benefits";
import Testimonials from "../../component/testimonials";
import { faqCareer, reviewCareer, benefits } from "./constant";
import Faq from "../../component/Faq";
import CareerOpp from "./careerOpp";
import CTA from "../../component/cta";
import {useParams} from "react-router-dom";
import Career from "../../assets/image/careerHeroImg.webp"
import { getAllJobs } from "../../Shared/Services/UserService";


const Loyerpage = () => {
  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          mt: { md: '150px', sm: '144px', xs: '72px' },
          mb: { lg: "80px", md: "72px", sm: "64px", xs: "48px" },
        }}
      >
        <Box className="container">
          <Box
            className="flexrow"
            sx={{
              // p: { lg: "60px", md: "30px", sm: "20px", xs: "20px" },
              display: "flex",
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "row",
                xs: "column-reverse",
              },
              gap: { sm: "40px", xs: "40px" },
            }}
          >
            <Box
              className="flexcol"
              sx={{
                rowGap: "16px",
                width: { lg: "50%", md: "50%", xs: "100%", sm: "50%" },
                pt: { md: "40px", sm: "28px", xs: "0px" },
                alignItems: "flex-start",
              }}
            >
              <Typography className="font700 bannerHeading">
                Be a part of our team
              </Typography>
              <Typography className="font400 p1">
              Our India Legal Lawyer team is already top-notch, but we believe there's always room for improvement. That's why we're searching for talented individuals with exceptional skills to join us and contribute to our success. If you're ready for a challenging and rewarding opportunity, we'd love to hear from you.
              </Typography>
            </Box>
            <Box
              // sx={{
              //   width: { md: "510px", sm: "382px", xs: "auto" },
              //   height: { md: "449px", sm: "336px", xs: "300px" },
              //   backgroundImage: `url(${talkTolawyerBG})`,
              //   backgroundRepeat: "no-repeat",
              //   position: "relative",
              //   backgroundSize: "contain",
              // }}
              sx={{
                width: { md: '510px', sm: '382px', xs: "auto" },
                height: { md: '449px', sm: '336px'},
                minHeight: "240px",
                objectFit: 'contain'
              }}
            >
              {/* <Box
                sx={{
                  position: "absolute",
                  top: "50px",
                  right: { md: "72px", sm: "56px" },
                  maxWidth: {
                    xs: "324px",
                    lg: "-webkit-fill-available",
                    md: "-webkit-fill-available",
                  },
                }}
              >
                <img
                  className="imageSize"
                  src={talkTolawyer}
                  alt="lawyer_image"
                />
              </Box> */}
               <img
                src={Career}
                style={{ width: "-webkit-fill-available",height:"-webkit-fill-available" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Benefits */}
      <Box
        sx={{
          maxWidth: "100%",
          rowGap: { lg: "72px", md: "64px", sm: "48px", xs: "32px" },
          mb: { lg: "80px", md: "72px", sm: "64px", xs: "48px" },
        }}
      >
        <Box className="container">
          <Box
            className="flexcol"
            sx={{
              mb: "40px",
              alignItems: "center",
              rowGap: { md: "80px", sm: "48px", xs: "32px" },

            }}
          >
            <Box className="flexcol" sx={{ gap: "16px", textAlign: "center" }}>
              <Typography className="font700 heading">
                Why Work with us
              </Typography>
              <Typography className="p1 flexcenter">
              Working at India Legal App offers each individual the opportunity to be challenged and engaged in an environment predicated upon learning, collaboration and achievement.
              </Typography>
            </Box>
            <Box
              className="flexrow"
              sx={{
                columnGap: "50px",
                px: { lg: "0px", xs: "0px" },
                flexWrap: "wrap",
                justifyContent: "center",
                rowGap:'24px'
              }}
            >
              <Benefits benefits={benefits} />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Testimonials review={reviewCareer} /> */}
      <Box
        sx={{
          mb: { lg: "80px", md: "72px", sm: "64px", xs: "48px" },
        }}
      >
        <Box className="container">
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h1"
              className="font700 heading"
              sx={{ pb: "24px" }}
            >
              Career Opportunities
            </Typography>
            <CareerOpp />
          </Box>
        </Box>
      </Box>
      <Faq ques={faqCareer} />
      <CTA user={true} appLink={"https://play.google.com/store/apps/details?id=com.indialegal.lawyer"}/>
    </>
  );
};

export default Loyerpage;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import ServicesCard from '../../../component/servicesCard';
import { Navigation, Pagination, } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

const ServicesSlider = ({ serviceData }) => {
  return (
    <Box>
      <Swiper
        spaceBetween={32}
        modules={[Navigation, Pagination]}
        navigation={true}
        className='myServiceSwiper'
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          // when window width is >= 640px
          320: {
            width: 240,
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            width: 512,
            slidesPerView: 2,
          },
          1024: {
            width: 784,
            slidesPerView: 3,
          },
          1201: {
            width: 1056,
            slidesPerView: 4,
          },
        }}
      >
        {serviceData.map((el, i) =>
          <SwiperSlide key={`serviceSlide-${i}`}>
            <ServicesCard cardData={el} />
          </SwiperSlide>
        )}
      </Swiper>
    </Box>
  )
}

export default ServicesSlider
import grow from "../../assets/logo/grow.svg";
import diversity from "../../assets/logo/diversity.svg";
import community from "../../assets/logo/people.svg";
import team from "../../assets/logo/profile-2user.svg";


export const faqCareer = JSON.stringify([
  {
    id: "1",
    ques: "What is India Legal App? ",
    ans: "India Legal App offers free legal aid to economically weaker section. Along with customisable legal services for others. ",
  },
  {
    id: "2",
    ques: "Is India Legal App FREE? ",
    ans: "Yes, it is free App you can download it from Play Store and App Store. ",
  },
  {
    id: "3",
    ques: "What services India Legal App provides? ",
    ans: "We offer legal service that helps you create legal documents and get legal advice as fast as possible. These are the most popular services - Talk to Lawyer, Business and Contracts,Real Estate,Startup, Family and Personal.",
  },
  {
    id: "4",
    ques: "What is IL Suraksha Kavach? ",
    ans: "As the name says, IL Suraksha Kavach is an affordable legal protection plan of India Legal App. In this legal protection plan, we combine various legal services such as Free Consultation for 30 mins, Rent Agreements, Affidavits, Study Material for Law Students, Legal Magazine Access, Exclusive Video content, Exclusive Legal Talk and access to group events.",
  },
  {
    id: "5",
    ques: "What is SOS Service? ",
    ans: "SOS service is FREE legal dedicated helpline, working 24x7. Whenever you require any kind of legal help, our dedicated team is always available to help you",
  },
  {
    id: "6",
    ques: "What is Talk to Lawyer? ",
    ans: "On phone instant audio, video legal consultation from experienced advocates. It’s a simple process - book your mins, select your advocate, and get legal advice.",
  },
]);

export const reviewCareer = [
  {
    review:
      "App is very useful to help me in my legal advice and using the app is also very simple.",
    reviwerName: "Shubham Sharma",
    reviwerType: "Service that customer availed",
  },
  {
    review:
      "I recently used a legal service on India Legal App and I am extremely satisfied with the experience.",
    reviwerName: "Nivideta Singh",
    reviwerType: "Service that customer availed",
  },
  {
    review:
      "Definitely recommend using India Legal. The Lawyer I spoke with was very knowledgeable and suggested me with perfect solutions.",
    reviwerName: "Vandana Tomar",
    reviwerType: "Service that customer availed",
  },
  {
    review:
      "App is very useful to help me in my legal advice and using the app is also very simple.",
    reviwerName: "Shubham Sharma",
    reviwerType: "Service that customer availed",
  },
  {
    review:
      "I recently used a legal service on India Legal App and I am extremely satisfied with the experience.",
    reviwerName: "Nivideta Singh",
    reviwerType: "Service that customer availed",
  },
  {
    review:
      "Definitely recommend using India Legal. The Lawyer I spoke with was very knowledgeable and suggested me with perfect solutions.",
    reviwerName: "Vandana Tomar",
    reviwerType: "Service that customer availed",
  },
];
export const benefits = [
  {
    id: "14",
    image: grow,
    heading: "Grow with us",
    subHeading:
      "India Legal offers collegial environment so you can shape your career to grow with your skills, talents and interests. We value good ideas, take advantage of India Legal’s environment and culture to propel your career.",
  },
  {
    id: "8",
    image: team,
    heading: "Team Collaboration ",
    subHeading: `Join our team and contribute to our collective success – collaborate with exceptionally creative, highly talented and knowledgeable colleagues across disciplines.`,
  },
  {
    id: "9",
    image: diversity,
    heading: "Culture and Diversity ",
    subHeading: "At India Legal app, you can belong as your are.  ",
  },
  {
    id: "9",
    image: community,
    heading: "Community Involvement",
    subHeading:
      " Be part of something bigger than yourself – As India Legal app individual and as team, we contribute to our communities, with our full support. Join us",
  },
];

export const careerOpp = [
  {
    heading: "Product Manager",
    department: "Marketing",
    link: "product-manager",
    location: "Noida",
    ["Role & Responsibility"]: `The Product Manager is responsible for the product planning and execution throughout the Product Lifecycle, including: gathering and prioritizing product and customer requirements, defining the product      vision, and working closely with content, sales, marketing and support to      ensure revenue and customer satisfaction goals are met.The Product Manager also ensures that the product supports the company’s overall strategy and goals.`,
    ["Qualification and Experience"]: `Law Graduate, at least five years of experience in a related role, the ability to lead a strong team, formal training, and a PMP certification. Certification can be acquired through a professional association, a university or college.`,
  },
  {
    heading: "Call Centre Head",
    department: "Support",
    link: "call-centre-head",
    location: "Noida",
    ["Role & Responsibility"]: `Handle many inbound and outbound calls to customers and clients. Identify the needs of customers, resolve issues, and provide solutions. Upsell other products wherever possible. Ensure you follow the customer service script provided by the company for uniformity.`,
    ["Qualification and Experience"]: `Law graduate, at least five years of experience in a related role, the ability to lead a strong team, formal training in related field.`,
  },
  {
    heading: "Marketing & Sales Head",
    department: "Marketing",
    link: "marketing-sales-head",
    location: "Noida",
    ["Role & Responsibility"]:
      "Sales Manager responsibilities include developing key growth sales strategies, tactics and action plans. Successful execution of these strategies is required to achieve your financial targets. Sales director duties will include hitting annual targets, building relationships and understanding customer trends.",
    ["Qualification and Experience"]:
      "Law graduate, at least five years of experience in a related role, the ability to lead a strong team, formal training in related field.",
  },
  {
    heading: "Content Manager",
    department: "Marketing",
    link: "content-manager",
    location: "Noida",
    ["Role & Responsibility"]: `Content Manager Job entails overseeing marketing strategies that engage customers and ensuring they are happy with what the company's products or services offer them.`,
    ["Qualification and Experience"]: `Master in mass communication or PGD in mass communication from renowned university. At least 10 years of experience in journalistic field. Ability to lead a strong team, formal training in related field.`,
  },
  {
    heading: "Design& Graphics Head",
    department: "Product",
    link: "design-graphics-head",
    location: "Noida",
    ["Role & Responsibility"]: `The Graphics Manager will direct the production and layout of artwork, facilitating communication and collaboration across departments to ensure project/client specifications are met.`,
    ["Qualification and Experience"]: `Graduate, at least five years of experience in a related role, the ability to lead a strong team, formal training in related field.`,
  },
  {
    heading: "Producer",
    department: "Product",
    link: "producer",
    location: "Noida",
    ["Role & Responsibility"]: `Video Producer is responsible for managing and overseeing the production of a video. You will also be responsible for planning and organizing the shoots. In addition to this, you should be managing the budgets and work accordingly. `,
    ["Qualification and Experience"]: `Graduate, at least five years of experience in a related role, the ability to lead a strong team, formal training in related field.`,
  },
  {
    heading: "HR /Administrator",
    department: "HR",
    link: "hr-administrator",
    location: "Noida",
    ["Role & Responsibility"]: `HR & Admin Officer responsibilities include processing employee data, updating company policies and assisting in the hiring process. To be successful in this role, you should have solid organizational skills and be familiar with HR functions. Ultimately, you will make sure all HR operations run smoothly.`,
    ["Qualification and Experience"]: `Graduate, at least 10 years of experience in a related role, the ability to lead a strong team, formal training in related field.`,
  },
  {
    heading: "UI/UX Designer",
    department: "Product",
    link: "ui-ux-designer",
    location: "Noida",
    [`Job Description`]: `We are looking for a UI/UX Designer to turn our software into easy-to-use products for our clients.
      UI/UX Designer responsibilities include gathering user requirements, designing graphic elements and building navigation components. To be successful in this role, you should have experience with design software and wireframe tools. If
      you also have a portfolio of professional design projects that includes work with web/mobile applications, it would be great.`,
    Responsibilities: `• Gather and evaluate user requirements in collaboration with product managers and engineers
      • Illustrate design ideas using storyboards, process flows and sitemaps
      • Design graphic user interface elements, like menus, tabs and widgets
      • Build page navigation buttons and search fields
      • Develop UI mockups and prototypes that clearly illustrate how sites function and look like
      • Create original graphic designs (e.g. images, sketches and tables)
      • Prepare and present rough drafts to internal teams and key stakeholders
      • Identify and troubleshoot UX problems (e.g. responsiveness)
      • Conduct layout adjustments based on user feedback
      • Adhere to style standards on fonts, colors and images`,
    [`Requirements and skills`]: `• Proven work experience as a UI/UX Designer or similar role
    • Portfolio of design projects
    • Knowledge of wireframe tools (e.g. Wireframe.cc and InVision)
    • Up-to-date knowledge of design software like Adobe Illustrator and Photoshop
    • Team spirit; strong communication skills to collaborate with various stakeholders
    • Good time-management skills
    • BSc in Design, Computer Science or relevant field
    • Experience 1-2 Years`,
  },
  {
    heading: "Mobile Developer",
    department: "Engineering",
    link: "mobile-developer",
    location: "Noida",
    [`Job Description`]: `We are looking for a qualified Mobile developer to join our team. You will be working with our engineers to develop and maintain high quality mobile applications.
      If you’re passionate about mobile platforms and translating code into user-friendly apps, then this job is for you. As a Mobile developer, you’ll collaborate with internal teams to develop functional mobile applications, while working in a fast-paced environment.
      Ultimately, you should be able to design and build the next generation of our mobile applications.`,
    Responsibilities: `• Support the entire application lifecycle (concept, design, test, release and support)
      • Produce fully functional mobile applications writing clean code
      • Gather specific requirements and suggest solutions
      • Write unit and UI tests to identify malfunctions
      • Troubleshoot and debug to optimize performance
      • Design interfaces to improve user experience
      • Liaise with Product development team to plan new features
      • Ensure new and legacy applications meet quality standards
      • Research and suggest new mobile products, applications and protocols
      • Stay up-to-date with new technology trends`,
    [`Requirements and skills`]: `• Proven work experience as a Mobile developer
    • Demonstrable portfolio of released applications on the App store or the Android market
    • In-depth knowledge of at least one programming language like Swift and Java
    • Experience with third-party libraries and APIs
    • Familiarity with OOP design principles
    • Excellent analytical skills with a good problem-solving attitude
    • Ability to perform in a team environment
    • BSc degree in Computer Science or relevant field`,
  },
  {
    heading: "Front-end Developer",
    department: "Engineering",
    link: "front-end-developer",
    location: "Noida",
    [`Job Description`]: `We are looking for a qualified Front-end developer to join our IT team. You will be responsible for building the ‘client-side’ of our web applications. You should be able to translate our company and customer needs into functional and appealing interactive applications.
      If you’re interested in creating a user-friendly environment by writing code and moving forward in your career, then this job is for you. We expect you to be a tech-savvy professional, who is curious about new digital technologies and aspires to combine usability with visual design.
      Ultimately, you should be able to create a functional and attractive digital environment for our company, ensuring great user experience.`,
    Responsibilities: `• Use markup languages like HTML to create user-friendly web pages
      • Maintain and improve website
      • Optimize applications for maximum speed
      • Design mobile-based features
      • Collaborate with back-end developers and web designers to improve usability
      • Get feedback from, and build solutions for, users and customers
      • Write functional requirement documents and guides
      • Create quality mockups and prototypes
      • Help back-end developers with coding and troubleshooting
      • Ensure high quality graphic standards and brand consistency
      • Stay up-to-date on emerging technologies`,
    [`Requirements and skills`]: `• Proven work experience as a Front-end developer
    • Hands on experience with markup languages
    • Experience with JavaScript, CSS and jQuery
    • Familiarity with browser testing and debugging
    • In-depth understanding of the entire web development process (design, development and deployment)
    • Understanding of layout aesthetics
    • Knowledge of SEO principles
    • Familiarity with software like Adobe Suite, Photoshop and content management systems
    • An ability to perform well in a fast-paced environment
    • Excellent analytical and multitasking skills
    • BSc degree in Computer Science or relevant field`,
  },
  {
    heading: "Back-end Developer",
    department: "Engineering",
    link: "back-end-developer",
    location: "Noida",
    [`Job Description`]: `We are looking for an experienced Back-end developer to join our IT team. You will be responsible for the server side of our web applications.
        If you have excellent programming skills and a passion for developing applications or improving existing ones, then this job is for you. As a Back-end developer, you’ll work closely with our engineers to ensure system consistency and improve user experience.
        Ultimately, you should be able to develop and maintain functional and stable web applications to meet our company’s needs.`,
    Responsibilities: `• Participate in the entire application lifecycle, focusing on coding and debugging
        • Write clean code to develop functional web applications
        • Troubleshoot and debug applications
        • Perform UI tests to optimize performance
        • Manage cutting-edge technologies to improve legacy applications
        • Collaborate with Front-end developers to integrate user-facing elements with server side logic
        • Gather and address technical and design requirements
        • Provide training and support to internal teams
        • Build reusable code and libraries for future use
        • Liaise with developers, designers and system administrators to identify new features
        • Follow emerging technologies`,
    [`Requirements and skills`]: `• Proven work experience as a Back-end developer
      • In-depth understanding of the entire web development process (design, development and deployment)
      • Hands on experience with programming languages like Java, Ruby, PHP and Python
      • Working knowledge of CMS framework
      • Familiarity with front-end languages (e.g. HTML, JavaScript and CSS)
      • Excellent analytical and time management skills
      • Teamwork skills with a problem-solving attitude
      • BSc degree in Computer Science or relevant field`,
  },
  {
    heading: "Digital Project Manager",
    department: "Operations",
    link: "digital-project-manager",
    location: "Noida",
    [`Job Description`]: `We are looking for a qualified Digital project manager to join our team. You will be responsible for the day-to-day management and smooth operation of various digital projects, including site launches, online tools, web applications and advertising campaigns.
        For this position, we expect you to be a tech-savvy professional with an in-depth understanding of how technology can help us achieve our business goals. You should be methodical and have excellent time management skills. As a Digital project manager, you should also use your communication skills to collaborate effectively with various teams.
        Ultimately, you should be able to manage and deliver our projects’ digital lifecycle within quality, time and budget requirements.`,
    Responsibilities: `• Provide end-to-end project management
        • Scope project requirements and prepare budget
        • Develop a detailed project plan and monitor progress
        • Collaborate with internal teams to design, develop and implement digital projects
        • Deliver projects on time ensuring quality standards are met
        • Develop support documentation including risk logs and requirement specifications
        • Monitor and report on Google Analytics metrics
        • Communicate with the team and ensure all members are on board with delegated tasks
        • Highlight potential risks or malfunctions and act proactively to resolve issues
        • Seek opportunities for improvement and suggest new projects`,
    [`Requirements and skills`]: `• Proven work experience as a Digital project manager
      • Hands on experience with project management software, like MS Project and AtTask
      • Familiarity with Content Management systems and HTML/CSS
      • Understanding of SEO, ASO, SMO, Google Analytics and Comscore
      • Knowledge of UE software
      • Solid technical background with an ability to address accessibility and compatibility issues
      • Excellent organization and time management skills
      • Communication and team management skills
      • BSc in Computer Science, Digital Marketing or relevant field`,
  },
];
// regex to use in dashboard-- i?.heading.toLowerCase().replace(/ /g ,"-")
// ab.map((i)=>
// {console.log(i.heading.toLowerCase().replace(/ /g ,"-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replace(/--/g ,"-"))
// })

import Document from "../../assets/logo/ra-DocumentCollection.svg";
import Review from "../../assets/logo/ra-ReviewAnalysis.svg";
import Consultation from "../../assets/logo/ra-DetailedConsultation.svg";
import Service from "../../assets/logo/ra-ServiceDelivery.svg";
export const benefits = [{ id: '1', image: Document, heading: "Document Collection", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
{ id: '1', image: Review, heading: "Review Analysis", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
{ id: '1', image: Consultation, heading: "Detailed Consultation", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
{ id: '1', image: Service, heading: "Service Delivery", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
]
export const startUpServices = JSON.stringify([
  {
      heading: `Limited Liability Partnership Company`, description: `Limited Liability Partnership (LLP) was introduced in 2008, since then it has quickly become a well-liked legal structure for business. Restricted Liability Partnership Registration is ruled by the Liability Partnership Act, 2008.`, content: [
          {
              subHeading: `Benefits of Registering a Limited Liability Partnership Company`, subDesc: `Registering a Limited Liability Partnership Company offers several benefits:
      - liability of Partners 
      - separate Legal Entity
      - continuity of Existence 
      - least restrictive Compliance 
      - transfer of possession` },
          {
              subHeading: `List of essential Documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Limited Liability Partnership Company:
      1. Copy of Pan Card of Partners (mandatory)
      2. Aadhaar Card or Driving license or Passport; Bank Statement or Electricity Bill or Mobile Bill (not older than 2 months) (any one)
      3. Passport Size Photograph of Partners
      4. Proof of Registered Office:
      5. Conveyance/ Lease deed/Rent Agreement etc. alongside rent receipts (any one)
      6. Copy of the utility bills (Telephone/Gas/Electricity bill) (not older than 2 months) (any one)
      7. NOC from landholder` },
          {
              subHeading: `Detailed Registration Process`, subDesc: `Following is a step by step guide of the registration procedure as per the governing rules of the Ministry of Corporate Affairs:
      1. Application for DSC & DIN for Partners
      2. Application filing for Name availability
      3. Drafting and Filing of LLP agreement
      4. Drafting and Filing of eMoA and eAoA
      5. Apply for PAN and TAN of the corporate
      6. Certificate of Incorporation issued by RoC along with PAN and TAN
      7. Open a Current checking account on the corporate name` },
      ],
  },
  {
      heading: `Partnership Firm`, description: `“Partnership” is the relation between 2 or more persons who have agreed to share the profits of a business carried on by all or any of them. Two or additional parties come together with a proper agreement (known as Partnership Deed) where the roles, duties and also the share of every partner is expressly outlined. Persons who have agreed to enter into partnership are referred to as “PARTNERS” and collectively a “FIRM”.`, content: [
          {
              subHeading: `Registration procedure`, subDesc: `Following is a step by step guide of the registration procedure as per the governing rules:
      1. Draft a Partnership Deed
      2. Payment of stamp tax on Deed
      3. Notarization of Partnership Deed
      4. Apply for PAN and TAN of the Firm
      5. Certificate of Registration from RoF
      6. Open a Current checking account on the Firm’s name` },
          {
              subHeading: `Required documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Private Limited Company:
      1. Identity & Address Proof of Partners
      2. Copy of Pan Card of Partners (mandatory)
      3. Aadhaar Card or citizen Id card or driver's license or Passport
      4. Anyone of: Bank Statement or Electricity Bill or Mobile Bill (not older than two months)
      5. Passport Size Photograph of Partners
      6. Proof of Registered workplace (any one): Conveyance/ Lease deed/Rent Agreement etc. together with rent receipts (any one) Copy of the utility bills (Telephone/Gas/Electricity bill) (not older than 2 months) (any one)
      7. Documents needed for Partnership Registration Form No. one (Application for registration underneath Partnership Act) Original copy of Partnership Deed, signed by all Partners Instrument declaring intention to become Partner Rental or lease agreement of the property/campus on that the business is established` },
      ],
  },
  { heading: `One Person Company`, description: `Registering a corporation is the start to begin a business in Republic of India. The One Person Company (OPC) is a new kind of company formation introduced within the Companies Act of 2013. In OPC, one promoter gains full authority over the corporate, he is going to be the only real shareholder and director. An OPC is a hybrid structure which combines most of the advantages of a sole proprietary and a corporation.`, content: [
      { subHeading: `Registration procedure`, subDesc: `Following is a step by step guide of the registration procedure as per the governing rules:
      1. Application for DSC (Digital Signature Certificate)
      2. Apply for DIN (Director Identification Number)
      3. Application filing for Name convenience
      4. Drafting and Filing of eMoA and eAoA
      5. Apply for PAN and TAN of the corporate
      6. Certificate of Incorporation issued by RoC along with PAN and TAN
      7. Open a Current checking account on the corporate name` },
      { subHeading: `Required documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a One Person Company:
      1. Identity & Address Proof:
      Copy of Pan Card of Director Citizen Id card or driver's licence or Passport Any one: Bank Statement or Electricity Bill or Mobile Bill (not older than a couple of months) Passport Size Photograph of administrators
      2. Proof of Registered Office:
      Conveyance/ Lease deed/Rent Agreement etc. at the side of rent receipts (any one) Copy of the utility bills (Telephone/Gas/Electricity bill) (not older than 2 months) (any one) NOC from landowner` },
  ], },
  {
      heading: `Private Limited Company`, description: `Registering a corporation is the opening move to start out a business in India. If you are a start-up and have high growth aspirations, then you ought to incorporate a Private Ltd. Private Limited Company is incorporated under the Companies Act, 2013 and governed by the Ministry of Corporate Affairs (MCA).`,
      content: [
          {
              subHeading: `Requirements for Registering a Private Limited Company`, subDesc: `• Minimum 2 Directors, but a maximum two hundred can become shareholders.
      • One Resident Director: Among 2 directors, a minimum of one director should be a resident Indian for the registration. Resident Indian suggests that someone who has spent a minimum of 182 days or more during a fiscal year in India.
      • No Minimum Capital: There is no minimum capital needed for said registration but a minimum authorized share capital of Rupees One lakh.
      • Unique Name: The recommended name of the Private Limited ought to be distinctive and should not be like any already existing name or trademark.` },
          {
              subHeading: `Detailed Registration Process`, subDesc: `1. Application for DSC & DIN for Partners
      2. Application filing for Name availability
      3. Drafting and Filing of LLP agreement
      4. Drafting and Filing of eMoA and eAoA
      5. Apply for PAN and TAN of the corporate
      6. Certificate of Incorporation issued by RoC along with PAN and TAN
      7. Open a Current checking account on the corporate name` },
          {
              subHeading: `List of essential Documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Private Limited Company:
      1. Copy of Pan Card of directors (mandatory)
      2. Aadhaar Card or Driving license or Passport; Bank Statement or Electricity Bill or Mobile Bill (not older than 2 months) (any one)
      3. Passport Size Photograph of administrators
      4. Proof of Registered Office:
      5. Conveyance/ Lease deed/Rent Agreement etc. alongside rent receipts (any one)
      6. Copy of the utility bills (Telephone/Gas/Electricity bill) (not older than 2 months) (any one)
      7. NOC from landholder` },
      ],
  },
  { heading: `Trademark`, description: `A trademark is a sign capable of distinguishing the goods or services of one enterprise from those of other enterprises. Trademarks are protected by intellectual property rights. In principle, a trademark registration will confer an exclusive right to the use of the registered trademark. This implies that the trademark can be exclusively used by its owner, or licensed to another party for use in return for payment. Registration provides legal certainty and reinforces the position of the right holder, for example, in case of litigation.
   A word or a combination of words, letters, and numerals can perfectly constitute a trademark. But trademarks may also consist of drawings, symbols, three-dimensional features such as the shape and packaging of goods, non-visible signs such as sounds or fragrances, or colour shades used as distinguishing features – the possibilities are almost limitless.  `,
   content: [{ subHeading: `Registration procedure `, subDesc: `Following is a step bystep guide of the registration procedure as per the governing rules:
   • Trademark Search
   • Application Preparation
   • Application Filing
   • Government Processing` },
   { subHeading: `Required documents`,
    subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Trademark: 
    1. A copy of the trademark or of the logo. In case of trade mark for word, logo is not required.
    2. Applicant’s details like name, address and nationality.
    3. In case of company or LLP, the incorporation certificate.
    4. Udyog Aadhar registration, in case the company is eligible for lower filing fee.
    5. Description of goods or services represented by the mark.
    6. Trademark classunder the application must be filed.
    7. Power of attorney in Form 48 Format should be signed by the applicant.` }], },
  {
      heading: `Copyright`, description: `Copyright is the right granted to creators of literary, dramatics, music, and inventive work and producers of films and recordings. The holder of Copyright has the rights to reproduce, communicate, distribute, and assign the work he has created. Copyright ensures minimum safeguards of authors' rights over their creations, thereby protecting liability.
  The Copyright Act, 1957 protects original literary, dramatic, musical and artistic works and cinematographic films and sound recordings from unauthorized usage and repeating of their creation. Copyright protects the expressions and not the ideas. There’s no copyright protection for ideas, procedures, ways of operation or mathematical ideas.`,
      content: [
          {
              subHeading: `Registration procedure`, subDesc: `Following is a step by step guide of the registration procedure as per the governing rules:
      1. Copyright registration application has to be prepared in Form IV
      2. Application must be duly signed by the applicant and submitted by the advocate under whose name Power of Attorney has been executed
      3. Online filing of an application for Copyright Registration
      4. If discrepancy and/or objections are found, discrepancy notice will be issued and the same needs to be responded to within 30 days from the date of issuance of the notice
      5. If there are no discrepancies or objections with the application, the copyright will be registered and the Copyright Office will issue the Registration Certificate` },
          {
         subHeading: `Required documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Copyright:
      1. Proof of Applicant - PAN Card, Address Proof of Applicant and Certificate of Incorporation (if the Copyright is to be registered for a Company)
      2. Nature of Work
      3. Copies of the original work
      4. Category & Description of the Work
      5. Title of the Work
      6. Language of the Work
      7. Date of Publication
      8. NOC from Author/Publisher (if applicable)
      9. Power of Attorney in favor of the Lawyer/Attorney` },
      ],
  },
  { heading: `Patent`, description: `As per the Patent Act, 1970 for an invention to be patentable, the invention should be a brand new product or method, involving an inspired step and capable of being created or employed in industry. Patent registration may be obtained in Republic of India for an invention. Patent registrations are not applicable for all inventions, and therefore the invention should satisfy bound criteria to be patentable in Republic of India. Patent filing or patent registration is that the opening move a creator takes to safeguard his/her invention from being exploited. Patent filing in Republic of India may be a sophisticated method, however, with the proper legal steering, it may be done simply.`, content: [
      { subHeading: `Registration procedure`, subDesc: `Following is a step by step guide of the registration procedure as per the governing rules:
      1. Patent Search
      2. File application
      3. Preparation of a patentability report
      4. Publication of application
      5. Patent Examination
      6. Patent Objections
      7. Grant of Patent` },
      { subHeading: `Required documents`, subDesc: `This is an exhaustive compilation of required documents in order to prepare for the Registration of a Patent:
      1. Title of the invention
      2. Candidates Information - Name, address, contact details and status of every human for the patent
      3. Description of the Invention - careful description of the invention and details concerning what the discoverer needs to say out of the invention
      4. Technical aspects of the invention - Technical details concerning the invention and drawings along with copy of tentative patent (if filed)` },
  ], },
  { heading: `Legal Advice for Startup`, description: `Our goal is to create a ‘one-stop support system’ of services for entrepreneurs and traders. We provide legal suggestions that Indian founders ought to consider when creating their startup from selecting the appropriate legal structure for your startup, Registrations and Enterprise Licenses, Intellectual Property Safety, Founder Equity – Split and Vesting, Founder Agreements, Employment Contracts, Employee Stock Option Pool (ESOP), Third Party Agreementsand Compliance Management.`, content: [{ subHeading: ``, subDesc: `` }], },
])
export const faq = JSON.stringify([{
    id: "1", ques: "What is India Legal App? ",
    ans: "India Legal App offers free legal aid to economically weaker section. Along with customisable legal services for others. "
},
{
    id: "2", ques: "Is India Legal App FREE? ",
    ans: "Yes, it is free App you can download it from Play Store and App Store. "
},
{
    id: "3", ques: "What services India Legal App provides? ",
    ans: "We offer legal service that helps you create legal documents and get legal advice as fast as possible. These are the most popular services - Talk to Lawyer, Business and Contracts,Real Estate,Startup, Family and Personal."
},
{
    id: "4", ques: "What is IL Suraksha Kavach? ",
    ans: "As the name says, IL Suraksha Kavach is an affordable legal protection plan of India Legal App. In this legal protection plan, we combine various legal services such as Free Consultation for 30 mins, Rent Agreements, Affidavits, Study Material for Law Students, Legal Magazine Access, Exclusive Video content, Exclusive Legal Talk and access to group events."
},
{
    id: "5", ques: "What is SOS Service? ",
    ans: "SOS service is FREE legal dedicated helpline, working 24x7. Whenever you require any kind of legal help, our dedicated team is always available to help you"
},
{
    id: "6", ques: "What is Talk to Lawyer? ",
    ans: "On phone instant audio, video legal consultation from experienced advocates. It’s a simple process - book your mins, select your advocate, and get legal advice."
}])
import HeroImage1 from "../../assets/image/Homepage-2.webp";
import HeroImage2 from "../../assets/image/Homepage-1.webp"
import HeroImage3 from "../../assets/image/Homepage-3.webp"
import HeroImage4 from "../../assets/image/Homepage-4.webp"

import talktolawyerN from "../../assets/image/TalktoLawyerService.webp";
import legalDocumentN from "../../assets/image/Legal Document.webp";
import propertyN from "../../assets/image/RealEstateService.webp";
import startUpN from "../../assets/image/StartUpService.webp";
import familyimgN from "../../assets/image/FamilyService.webp";


export const faq = JSON.stringify([{
    id: "1", ques: "What is India Legal App? ",
    ans: "India Legal App offers free legal aid to economically weaker section. Along with customisable legal services for others. "
},
{
    id: "2", ques: "Is India Legal App FREE? ",
    ans: "Yes, it is free App you can download it from Play Store and App Store. "
},
{
    id: "3", ques: "What services India Legal App provides? ",
    ans: "We offer legal service that helps you create legal documents and get legal advice as fast as possible. These are the most popular services - Talk to Lawyer, Business and Contracts,Real Estate,Startup, Family and Personal."
},
{
    id: "4", ques: "What is IL Suraksha Kavach? ",
    ans: "As the name says, IL Suraksha Kavach is an affordable legal protection plan of India Legal App. In this legal protection plan, we combine various legal services such as Free Consultation for 30 mins, Rent Agreements, Affidavits, Study Material for Law Students, Legal Magazine Access, Exclusive Video content, Exclusive Legal Talk and access to group events."
},
{
    id: "5", ques: "What is SOS Service? ",
    ans: "SOS service is FREE legal dedicated helpline, working 24x7. Whenever you require any kind of legal help, our dedicated team is always available to help you"
},
{
    id: "6", ques: "What is Talk to Lawyer? ",
    ans: "On phone instant audio, video legal consultation from experienced advocates. It’s a simple process - book your mins, select your advocate, and get legal advice."
}])

export const heroContent = [{
    hf: "Get",
    hs: 'Legal Help Now',
    p: 'Legal issues can be confusing, complicated, and even a little embarrassing. We are here to help you.'
    , url: HeroImage4,
    link:"/talk-to-lawyer"
},
{
    hf: "Legally",
    hs: 'Protect Yourself',
    p: 'India Legal App provides fast and affordable legal access for all your legal issues.'
    , url: HeroImage2,
    link:"/IL-suraksha-kavach"
},
{
    hf: "",
    hs: 'Talk To Lawyer',
    // p: 'Get legal assistance where you want it, when you need it by talking to our India Legal Network Lawyer'
    p: 'You don’t necessarily know when you are going to need the help of a lawyer. It never comes at a good time, but with India Legal App, you can start resolving problems immediately.'
    , url: HeroImage3,
    link:"/"
},
{
    hf: "Our",
    hs: 'Network of Lawyers',
    // p: 'We have made the traditional process of finding a lawyer simple for you'
    p: 'It doesn’t matter if your dealing with a personal or a business legal issue, our network of lawyers specialises in providing you expert legal advice.'
    , url: HeroImage1,
    link:"/lawyer"
},]
export const reviewHome = [
    {review:'App is very useful to help me in my legal advice and using the app is also very simple.',reviwerName:'Shubham Sharma',reviwerType:'Service that customer availed'},
    {review:'I recently used a legal service on India Legal App and I am extremely satisfied with the experience.',reviwerName:'Nivideta Singh',reviwerType:'Service that customer availed'},
    {review:'Definitely recommend using India Legal. The Lawyer I spoke with was very knowledgeable and suggested me with perfect solutions.',reviwerName:'Vandana Tomar',reviwerType:'Service that customer availed'},
    {review:'App is very useful to help me in my legal advice and using the app is also very simple.',reviwerName:'Shubham Sharma',reviwerType:'Service that customer availed'},
    {review:'I recently used a legal service on India Legal App and I am extremely satisfied with the experience.',reviwerName:'Nivideta Singh',reviwerType:'Service that customer availed'},
    {review:'Definitely recommend using India Legal. The Lawyer I spoke with was very knowledgeable and suggested me with perfect solutions.',reviwerName:'Vandana Tomar',reviwerType:'Service that customer availed'},

]

export const ServicesHomepage = [
    { img: talktolawyerN, name: 'Talk to Lawyer', path: '/talk-to-lawyer'},
    { img: legalDocumentN, name: 'Business and Contracts', path: '/buisness-and-contracts' },
    { img: propertyN, name: 'Real Estate', path: '/real-estate' },
    { img: startUpN, name: 'Start Up', path: '/start-up' },
    { img: familyimgN, name: 'Family and Personal', path: '/family-and-personal' },
    ]
import axios from "axios";
import { BASE_URL } from "./constant";
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
    headers: {
      "ngrok-skip-browser-warning": true,
    },
});
export const axiosInstanceV2 = axios.create({
  baseURL: BASE_URL,
    headers: {
      "ngrok-skip-browser-warning": true,
      'Platform': 'user', 
      'Country': 'IN' 
    },
});
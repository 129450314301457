import Document from "../../assets/logo/ra-DocumentCollection.svg";
import Review from "../../assets/logo/ra-ReviewAnalysis.svg";
import Consultation from "../../assets/logo/ra-DetailedConsultation.svg";
import Service from "../../assets/logo/ra-ServiceDelivery.svg";
export const benefits = [{ id: '1', image: Document, heading: "Document Collection", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
{ id: '1', image: Review, heading: "Review Analysis", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
{ id: '1', image: Consultation, heading: "Detailed Consultation", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
{ id: '1', image: Service, heading: "Service Delivery", subHeading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
]
export const faqQues = JSON.stringify([{
  id: "1", ques: "What is India Legal App? ",
  ans: "India Legal App offers free legal aid to economically weaker section. Along with customisable legal services for others. "},
  {id: "2", ques: "Is India Legal App FREE? ",
  ans: "Yes, it is free App you can download it from Play Store and App Store. "},
  {id: "3", ques: "What services India Legal App provides? ",
  ans: "We offer legal service that helps you create legal documents and get legal advice as fast as possible. These are the most popular services - Talk to Lawyer, Business and Contracts,Real Estate,Startup, Family and Personal."},
  {id: "4", ques: "What is IL Suraksha Kavach? ",
  ans: "As the name says, IL Suraksha Kavach is an affordable legal protection plan of India Legal App. In this legal protection plan, we combine various legal services such as Free Consultation for 30 mins, Rent Agreements, Affidavits, Study Material for Law Students, Legal Magazine Access, Exclusive Video content, Exclusive Legal Talk and access to group events."},
  {id: "5", ques: "What is SOS Service? ",
  ans: "SOS service is FREE legal dedicated helpline, working 24x7. Whenever you require any kind of legal help, our dedicated team is always available to help you"},
  {id: "6", ques: "What is Talk to Lawyer? ",
  ans: "On phone instant audio, video legal consultation from experienced advocates. It’s a simple process - book your mins, select your advocate, and get legal advice."}
])
 
export const startUpServices = JSON.stringify([
    { 
        heading: `Founders Agreement`, description: `It is crucial to sign an agreement that controls the co-founders' professional relationship and lays out their shared understanding for future business goals in the early stage of a startup when they first start working together to develop a firm. The co-founders' agreement lessens the likelihood of future disagreements between the partners.`, content: [  
            {
                subHeading: `The following is stated in the founder's agreement:`,
                 subDesc: `1. The meaning of business
                 2. Future organizational design of the company ownership of intellectual property rights (such as copyright, patent, trade mark, etc.)
                 3. Parties' percentages of ownership in the company
                 4. Parties' roles and responsibilities
                 5. Mechanism for voting and making decisions
                 6. Performance standards and party termination
                 7. Parties' right to leave
                 8. Mechanism for resolving disputes` },
        ],
    },
    {
        heading: `Shareholder Subscription Agreement`, description: `A Shareholder Subscription Agreement (SHA), as the name implies, is a contract made between the company's shareholders and the business itself.This regulates how the company's shareholders interact with one another.`, content: [
          {        subHeading: `A well-written Shareholder's Agreement will have the clauses listed below:`,
            subDesc: `
            1.\b\bKind of security issued or obtained
            2.\b\bRate of convertible securities conversation
            3.\b\bVeto power
            4.\b\bLock-in period of the founder
            5.\b\bDrag along
            6.\b\bRide along
            7.\b\bFirst refusal rights (ROFR)
            8.\b\bFirst right of refusal (ROFO)
            9.\b\bWarranties & reps
            10.\bRights of exit
            11.\bLaw that governs and dispute resolution` },
             ],
    },
    { heading: `Term Sheet Review`, description: `A term sheet summarizes the fundamental terms and conditions of an investment and is often not legally enforceable. 
    The term sheet serves as a model and the foundation for more comprehensive, binding contracts. A legally binding agreement or contract that adheres to the terms of the term sheet is created after the parties concerned agree on the information provided in the term sheet.  `, content: [
      {        subHeading: `A well-written term sheet will have the following clauses:`,
      subDesc: `1.\b\bName of the business that will receive the funding
        2.\b\bWhat kind of security will be issued?
        3.\b\bRate of convertible securities conversation
        4.\b\bVeto power
        5.\b\bLock-in of the founder
        6.\b\bDragged on
        7.\b\bRide along
        8.\b\bFirst refusal rights (ROFR)
        9.\b\bFirst right of refusal (ROFO)
        10.\bWarranties & reps
        11.\bRights of exit
        12.\bThe rule of law and
        13.\bResolution of conflict` },
  ], },
    {
        heading: `Franchise Agreement`, description: `In India, a firm (the franchisor) enters into a contract with a person or an organisation to provide them the right to use the company's brand, its products, or its business processes (the franchisee). The terms between the two consenting parties are laid forth in the Franchise Agreement. It explains the:
        1. Payment to the franchisee in the form of royalties for using the
  company's trademark, etc.
  2. Restrictions on how to use the brand name,
  3. Terms relating to disciplinary measures, ranging from monetary fines
  and benefits to the termination of the contract and the company franchise
        `,
        content: [
            {
                subHeading: `The following is stated in the Franchise Agreement:`, subDesc: `1. The way in which the franchisor and franchisee conduct business
                2. Rights and restrictions on the use of the franchisor's brand name and trademark
                3. Specifies the territory, the royalty payment schedule, and the frequency of payments
                4. The upfront costs that must be paid to the franchisor
                5. Explains the agreement's legality and mentions the help that the franchisor will give for training and operations
                6. Explains the operational procedure and reporting structure
                7. Mechanism for termination and dispute settlement.` },
        ],
    },
    { heading: `Joint Venture`, description: `Joint venture (“JV”) is one of the most interesting kinds of business  arrangement, where two or more parties come together and pool their resources in order to accomplish a specific task.
    A JV could take various forms such as incorporating a completely new entity or merely entering into a contractual arrangement; it could be for a long term or for a limited duration in order to fulfil a strategic purpose. 
    JVs are thus highly flexible and floated structures depending upon the considerations and objectives of the participants.
    `, content: [{ subHeading: `The following is stated in the franchise agreement:`, subDesc: `1.\b\bDescription of the scope and objective
    2.\b\bSpecification of ensured capital
    3.\b\bOutline of fiduciary roles of the Partner
    4.\b\bShare or claim of each Partner
    5.\b\bBreakdown of the board and management
    6.\b\bObligations of the party
    7.\b\bConditions both precedent and subsequent
    8.\b\bDistribution of profits
    9.\b\bAccountability of share transfer in varied circumstances.
    10.\bRemedy of deadlock
    11.\bTermination clause
    12.\bFuture issue of capital
    13.\bProcedure of appointing the MD/CEO
    14.\bParticipation of investors (both local and foreign) in terms of equity
    15.\bVoting rights
    16.\bExit clauses
    17.\bAnti-dilution rights
    18.\bOther rights such as drag/tag along
    19.\bOther provisions such as NDA, indemnity, dispute resolution, anticompete, force majeure etc.` }], },
    { heading: `Non Disclosure Agreement`, description: `A nondisclosure agreement (NDA), often known as a confidentiality agreement, is  a  contract  that  guards  confidential  and  sensitive  company  information  and forbids the individual from disclosing it to any other party.
    NDAs can be made between businesses andtheir staff, consultants, partners, suppliers, middlemen, software developers, and clients. The goal of this is to stop anyone who has access to critical company information from disclosing it to a third party, which is a relatively straightforward premise.
    An NDA might be unilateral or mutual, depending on the situation.
    `,
     content: [{ subHeading: `An effective NDA includes the following:`, subDesc: `1. Confidential information definition
     2. The extent of the private information
     3. Standard confidentiality information exclusions
     4. Responsibility of the parties
     5. When sensitive information is disclosed, there are options for legal action.
     6. Information about confidentiality may be deleted or returned.
     7. Time period during which the information must be kept private` }], },
    { heading: `Service Agreement`, description: `A service agreement is a contract between a company that offers services and its client or consumer. For instance, a service agreement might be used to outline the terms of a contract between a client and a company providing web
    design services.
    The conditions of payment and deadlines are included in service agreements. Contracts often also specify the tasks to be carried out and the procedures to be followed in the event that adjustments are necessary.`, content: [
        { subHeading: `An effective Service Agreement includes the following:`, subDesc: 
      ` 1.\b\bAgreement's duration and payment terms
        2.\b\bThe meaning of service
        3.\b\bServices provided
        4.\b\bIncluded and excluded parties
        5.\b\bProperty rights
        6.\b\bConfidentiality
        7.\b\bParties' responsibilities and rights
        8.\b\bRepresentations and warranties for subcontractors
        9.\b\bIndemnity
        10.\bRestrictions on liability
        11.\bFinalization technique
        12.\bGoverning legislation and a dispute resolution process` },
         ], },
    { heading: `Business Partnership Agreement`, description: `A business partnership agreement is a written contract between two or more partners who want to start a business together. It specifies the terms and conditions of the partnership and the profit-and-loss distribution percentage between the partners. The reciprocal obligations between the partners will also be outlined in this agreement.`, content: [{ subHeading: `The following items are found in a well-written partnership agreement:`, subDesc: `
  1.\b\bThe essence of business
  2.\b\bPartners' names and numbers
  3.\b\bName of the company
  4.\b\bName of the company
  5.\b\bRegistered office
  6.\b\bRegistered office
  7.\b\bRatio of sharing profits/losses
  8.\b\bRevenue specifics
  9.\b\bDuration of the commercial relationship
  10.\bPartners' roles and responsibilities
  11.\bAccounts
  12.\bAccepting new partners
  13.\bNon-compete
  14.\bConfidentiality
  15.\bNotice
    ` }], },
    { heading: `Licensing Agreement`, description: `A licensing agreement is a written contract between two parties that grants one party, known as the licensor, the right to restrict how another party, known as the licensee, uses the licensor's property.  The owner of such content may provide a license for any intellectual property
    right subject matter, including music, design, artwork, photographs, videos, and sounds.
    The owner of such content may provide a license for any intellectual property right subject matter, including music, design, artwork, photographs, videos, and sounds.
    A license may be either exclusive or non-exclusive, worldwide or restricted to a certain region, assignable or non-assignable, etc. The type of license that the licensor wishes to provide the licensee is entirely up to them.`,
     content: [{ subHeading: `The following are included in a well-written Licensing Agreement:- `, subDesc: `1.\b\bInformation on the licensed subject matter
    2.\b\bThe length of the permit
    3.\b\bPayment type
    4.\b\bProvision of royalty payments
    5.\b\bLicense, whether exclusive or not
    6.\b\bUsage territory
    7.\b\bWarranty and representation
    8.\b\bCompensation
    9.\b\bTermination grounds
    10.\bThe severability
    11.\bWaiver
    12.\bOther legal provisions.
    ` }], },
    { heading: `Letter of Intent`, description: `A letter of intent (LOI) is a formal statement of one party's preliminary agreement to conduct business with another. The essential details of a potential agreement are described in the letter. These are frequently used in significant corporate transactions and are comparable to term sheets in substance.
    .`,
     content: [{ subHeading: `A well-written LOI will include the provisions listed below:-`, subDesc: `
     1.\b\bParty and business information
     2.\b\bThe agreement's terms
     3.\b\bTerms of payment
     4.\b\bLock-in
     5.\b\bDrag along
     6.\b\bRide along
     7.\b\bWarranties & reps
     8.\b\bRights of exit
     9.\b\bIndemnities
     10.\bRegulating law
     11.\bResolution of conflict
    ` }], },
    { heading: `Software as a Service Agreement`, description: `A method of distributing programs via the internet as a service is known as software as a service (or SaaS). You may avoid complicated software and hardware maintenance by just accessing software via the internet rather than installing and maintaining it. Software as a Service (SaaS) agreements are contracts between a software
    firm and its user or client that gives the latter the right to use the software in accordance with a set of terms that may be modified on an as-needed basis.
    `,
     content: [{ subHeading: `A well drafted SaaS will include the provisions listed below:-`, subDesc: `
     1.\b\bAgreement's duration and payment terms
     2.\b\bSoftware definition service scope
     3.\b\bInclusions and exclusions
     4.\b\bUpkeep, assistance, and upgrade
     5.\b\bOwnership rights
     6.\b\bLicense
     7.\b\bConfidentiality
     8.\b\bParties' responsibilities and rights
     9.\b\bRepresentations and warranties
     10.\bSubcontractors
     11.\bIndemnity
     12.\bRestrictions on liability
     13.\bTermination method
     14.\bGoverning legislation and a dispute resolution process
    ` }], },
    { heading: `IP Assignment Agreement`, description: `A formal agreement between two parties known as an IP Assignment Agreement, a property owner (known as the assignor) transfers full ownership of a subject matter to the other party (known as assignee). The owner of such material may delegate all intellectual property rights in such items such as music, design, artwork, photographs, videos, and sounds, among other things. `,
     content: [{ subHeading: `IP Assignment Agreement's Contents`, subDesc: `
     1. Information on the transferred subject matter
     2. Payment type
     3. Warranty and representation
     4. Compensation
     5. Liability restrictions
     6. Termination grounds
     7. The Severability
     8. Refund
     9. Other legal provisions` }], },
     { heading: `Consultancy Agreement`, description: `An organisation or employer and his or her consultant enter into a consultation agreement. This agreement specifies the terms of the connection between the consultant and the employer, including deliverables, deadlines, compensation, roles and responsibilities, etc. 
     It is important to engage into a consulting agreement, particularly from a business perspective, to specify the consultant's job scope and to impose restrictions on the consultant, including responsibilities for confidentiality, exclusivity, indemnity, and non-competition.
     `,
  
    //  { heading: `Consultancy Agreement`, description: `An organisation or employer and his or her consultant enter into a consultation agreement. This agreement specifies the terms of the connection between the consultant and the employer, including deliverables, deadlines, compensation, roles and responsibilities, etc.    It is important to engage into a consulting agreement, particularly from a business perspective, to specify the consultant's job scope and to impose restrictions on the consultant, including responsibilities for confidentiality, exclusivity, indemnity, and non-competition.   `,
    content: [{ subHeading: `A consultancy agreement's provisions`, subDesc: `   In a well-written Consultancy Agreement, the following issues are covered:
    1.\b\bWhat is the consultant's job description and what are theirresponsibilities?
    2.\b\bWhat is the consultant's expected or needed deliverable?
    3.\b\bWhat is the system for paying employees?
    4.\b\bWhat does the word "consulting" mean? Does a lock-in period exist?
    5.\b\bIs the consultant's exclusivity necessary to be offered?
    6.\b\bExist any entitlements to leave?
    7.\b\bHow may the consulting agreement be ended? How much warning isrequired?
    8.\b\bWhat would happen to the intellectual property in the work the consultantcompleted while under contract?
    9.\b\bAny existing restrictions on non-compete and non-solicitation?
    10.\bIndian laws that govern consulting agreements` }], },
    { heading: `Employment Agreement`, description: `An employment agreement (also known as an employment contract) is a contract that the employer and employee enter into that specifies the length of the employment, the conditions of the work, and the rights and obligations of the parties. It is important to sign an employment agreement, particularly from a business perspective, to specify the employee's job responsibilities and to impose restrictions on the employee, including obligations related to secrecy,  exclusivity, indemnification, and non-competition.  `,
     content: [{ subHeading: `An effective employment contract covers the following:-`, 
     subDesc: `   1. What are the responsibilities of an employee, and what is their job description?
     2. What are the incentive plan and compensation structure?
     3. How long is the job contract? Does a lock-in period exist?
     4. What are the break times and working hours?
     5. How many leave entitlements are there, and what kinds?
     6. How is it possible to end the employment? How much warning is required?
     7. What would happen to the intellectual property in the work completed by an employee while they were working for you?
     8. Exist any restrictions on non-compete and non-solicitation?
     9. What is the controlling law and the process for resolving disputes` }], },
     { heading: `Freelancer Agreement`, description: `An organisation or employer and the freelancer they have employed engage into a contract known as a Freelancer Agreement. This agreement specifies the conditions of the relationship between the freelancer and the employer, including deliverables, deadlines, payment, roles and duties, etc.  `,
     content: [{ subHeading: `In a well-written Freelancer Agreement, the following issues are covered:-`, 
     subDesc: ` 1. What is the freelancer's work description and what are their responsibilities?
     2. What is the freelancer's expected or needed deliverable?
     3. What is the system for paying employees?
     4. What is the length of the contract? Does a lock-in period exist?
     5. Is the freelancer's exclusivity necessary to be offered?
     6. Exist any entitlements to leave?
     7. How may the relationship be ended? How much warning is required?
     8. What would happen to the freelancer's intellectual property in the job 
     completed while under contract?
     9. Exist any restrictions on non-compete and non-solicitation?` }], },
  { heading: `Privacy Policy`, description: `A privacy policy is a written statement that informs users of how their personal data is gathered, used, processed, and disclosed on a website or mobile application.This privacy statement is applicable to both websites and mobile applications.Every website or application in India that collects and uses personal informationis required to have a privacy policy under the IT Act, 2000.Before collecting and processing users' information, the intention is to tell them and obtain their consent.`,
     content: [{ subHeading: `The document will extensively cover the following points :-`, subDesc: `1. What user information is being gathered?
     2. How and why are the data that have been gathered so far used?
     3. How is the information being handled?
     4. Does a third party get the data?
     5. What reasonable security measures are being taken to safeguard the 
     private data?
     6. How can people erase their personal information?
     7. Officer appointed to handle complaints
     8. governing law and resolving conflicts
     9. Modifications to the Privacy Policy
     ` }], },
  { heading: `Terms of Use`, description: `The terms of service, sometimes referred to as the terms of use or terms and conditions and frequently abbreviated as ToS, ToU, or T&C, is a contract that regulates how a website or app interacts with its users.
  The agreement outlines how the website should be used, outlines prohibited uses, establishes responsibility for acts, conduct, and behavior online, as well as the rights and obligations of the parties.
  `,
     content: [{ 
      subDesc: `1.\b\bMethod of usage
      2.\b\bUse restrictions
      3.\b\bProperty rights
      4.\b\bPermission to use
      5.\b\bRefunds
      6.\b\bIndemnification
      7.\b\bRestrictions on liability
      8.\b\bGoverning law and resolving conflicts
      9.\b\bChanges to the Terms
      10.\bSeverability
      11.\bWaiver
      ` }], }
  ])

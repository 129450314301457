import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const AboutUs = () => {
  return (
    <Box sx={{ mt: { lg: "112px", md: "80px", sm: "72px", xs: "56px" } }}>
      <Box className="container">
        <Box>

        <Box>
          <Typography className="heading font700"> About us</Typography>
          <br/>
          <Box>
          <Typography className="preLine p2">
          Our laws were created to protect and empower us as individuals,
           families and business owners, but most of the people don't get the
           help they need because of costly legal fees and complexity.
           <br />
           <br />
           At IL App, we want to change things by making legal services
           affordable, simple and available to more people than ever before. We
           are proud to say that we've been able to build a service that millions
           of people rely upon. Every day we spend our time and resources making
           it easier for people to get the legal help they need, so they can
           focus on what's really important, taking care of their families and
           building strong businesses.
           <br />
           <br />
           With IL Suraksha Kavach, we combine legal documents, legal advice with
           access to affordable representation by licensed advocates.
           <br />
           <br />
           With IL Apps Talk to Lawyer, we connect you to a lawyer of your
           concern. Then, because we know there's no replacement for qualified
           lawyers, local advocates are here to help when you need more guidance.
           With IL App you never have to do it alone.
           <br />
           <br />
           Our commitment to affordable and accessible legal services is at the
           heart of everything we do. Most advocates and bar associations agree
           that much can and should be done to improve access to the law by
           reducing cost and complexity. We invite you to try our service and see
           why customers say they're glad to have IL App at their finger tips.
           <br />
           <br />
           Together, we'll make the law work for you
          </Typography>
        </Box>
         
        </Box>
        </Box>
        
      </Box>
    </Box>
  );
};

export default AboutUs;

import React from 'react'
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
const servicesCard = ({ cardData }) => {
  return (
    <Box className="flexrow"
      sx={{
        width: '244px',
        height: '309px',
        mb:'32px'
      }}>
      <Link className='noDecoration' to={cardData.path} >
        <Box className="flexcol"
          sx={{
            width: '240px',
            height: '301px',
            rowGap: '12px',
            px: '4px',
            py: '4px',
            alignItems: "center",
            borderRadius: '10px',
            boxShadow: '0px 2px 16px #E3E3E3',
            backgroundColor: '#fff',
            cursor: 'pointer'
          }}>
          <img className="imageSize" src={cardData.img} alt={cardData.name} style={{objectFit:'cover'}}/>
          <Typography className="font700 p1"
            sx={{  color: '#272828 !important' }}>
              {cardData.name}</Typography>
        </Box>
      </Link>
    </Box>
  )
}

export default servicesCard;
import { React } from "react";
import { Box, Typography } from "@mui/material";
import Download from "../../component/downloadButton";
import FAQ from "../../component/Faq";
import CTA from "../../component/cta";
import Updates from "./sections/Updates";
import MobileStacked from "../../assets/image/StackedMobile.svg";
import Testimonials from "../../component/testimonials";
import GooglePLay from "../../assets/logo/google-play.svg";
import AppStore from "../../assets/logo/app-store.svg";
import { faq, reviewHome, heroContent, ServicesHomepage } from "./constant";
import serviceBackground from "../../assets/image/services_background.svg";
import SeviceIcon from "../../component/serviceIcon";
import RentalIcon from "../../assets/logo/note.svg";
import ConferenceIcon from "../../assets/logo/people.svg";
import JudgeIcon from "../../assets/logo/judge.svg";
import SurakshaKavach from "../../assets/image/suraksha-kavach-home.png";
import userAppQR from "../../assets/image/user-app-qr.jpg";
import HeroSlider from "../../component/heroSlider";
import ServicesSlider from "./sections/ServicesSlider";
import NewModal from "../../component/newModal";
import ConsultationIcon from "../../assets/logo/consultation.svg";
import BookIcon from "../../assets/logo/book.svg";
import SankalpImage from "../../assets/image/sankalp.svg";
import SankalpImageMob from "../../assets/image/sankalp_mob.svg";
import { useNavigate } from "react-router";

const Homepage = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Sankalp Section */}
      {/* <Box
        className="container-banner pointer"
        sx={{ mt: { lg: "112px", md: "80px", sm: "72px", xs: "56px" } }}
        onClick={() => navigate("/sankalp/form")}
      >
        <img
          src={window.innerWidth < 768 ? SankalpImageMob : SankalpImage}
          alt="sankalp_campaign"
          loading="eager"
          style={{ width: "100%" }}
        ></img>
      </Box> */}

      {/* Hero Section */}
      <HeroSlider heroContent={heroContent} />
      {/*What we do  */}
      <Box sx={{ maxWidth: "100%" }}>
        <Box
          className="container"
          sx={{ py: { lg: "80px", md: "64px", sm: "48px", xs: "32px" } }}
        >
          <Box
            className="flexrow"
            sx={{
              // columnGap: "70px",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              pr: { lg: "80px", md: "64px", sm: "48px", xs: "0px" },
              justifyContent: "space-between",
            }}
          >
            <Box
              className="flexcol"
              sx={{
                maxWidth: { lg: "660px", md: "495px", sm: "398px", xs: "100%" },
                rowGap: { lg: "86px", md: "64px", sm: "48px", xs: "32px" },
              }}
            >
              {/* <NewModal title={"CONTACT US!"} subtitle={"Get a call back."}/> */}
              <Box
                className="flexcol"
                sx={{ rowGap: "24px", alignItems: "flex-start" }}
              >
                <Typography className="font700 heading">What we do?</Typography>
                <Box
                  sx={{
                    height: {
                      lg: "622px",
                      md: "467px",
                      sm: "350px",
                      xs: "auto",
                    },
                    width: { sm: "auto", xs: "100%" },
                    maxWidth: { xs: "320px", sm: "100%", md: "100%" },
                    display: { xs: "block", sm: "none" },
                    mx: "auto",
                  }}
                >
                  <img
                    className="imageSize"
                    src={MobileStacked}
                    alt="App screenshot"
                  />
                </Box>
                <Typography
                  className="font400 p1"
                  sx={{
                    // fontSize: '20px',
                    lineHeight: "1.4",
                    letterSpacing: "0.5px",
                    color: "#9A9A9A",
                  }}
                >
                  India Legal App provides Free Legal Aid to economically weaker
                  sections of the society. We help people defend themselves by
                  always having a lawyer ready. We provide variety of ways
                  to connect with lawyers and get their advice through audio,
                  video or text. We offer legal service that helps people create
                  legal documents and get legal advice as fast as possible
                  through affordable plans. With India Legal App you never have
                  to do it alone
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  columnGap: { md: "46px", sm: "32px" },
                  alignContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box className="flexcol" sx={{ rowGap: "22px" }}>
                  <Typography
                    className="font400 p1"
                    sx={{
                      // fontSize: '20px',
                      lineHeight: "1.4",
                      letterSpacing: "0.5px",
                      color: "#272828",
                    }}
                  >
                    Click to download
                  </Typography>
                  <Box className="flexcol" sx={{ rowGap: "8px" }}>
                    <Box
                      sx={{
                        width: { md: "184px", xs: "156px" },
                        height: { md: "56px", xs: "48px" },
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open(
                          `https://play.google.com/store/apps/details?id=com.indialegal.user`,
                          "_blank"
                        )
                      }
                    >
                      <img
                        className="imageSize"
                        src={GooglePLay}
                        alt="GooglePLay"
                        style={{ objectFit: "cover" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: { md: "184px", xs: "156px" },
                        height: { md: "56px", xs: "48px" },
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open(
                          `https://apps.apple.com/in/app/india-legal/id6446165204`,
                          "_blank"
                        )
                      }
                    >
                      <img
                        className="imageSize"
                        src={AppStore}
                        alt="AppStore"
                        style={{ objectFit: "cover" }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Typography
                  className="font400 p1"
                  sx={{
                    fontSize: "20px",
                    lineHeight: "1.4",
                    letterSpacing: "0.5px",
                    color: "#272828",
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  {" "}
                  Or
                </Typography>
                <Box
                  className="flexcol"
                  sx={{
                    rowGap: "22px",
                    alignItems: "center",
                    display: { xs: "none", sm: "flex" },
                    width: { sm: "146px", md: "179px", lg: "184px" },
                  }}
                >
                  <Typography
                    className="font400 p1"
                    sx={{
                      fontSize: "20px",
                      lineHeight: "1.4",
                      letterSpacing: "0.5px",
                      color: "#272828",
                    }}
                  >
                    Scan To Download
                  </Typography>
                  <Box
                    sx={{
                      width: { lg: "120px", md: "120px", sm: "104px" },
                      height: { lg: "120px", md: "120px", sm: "104px" },
                    }}
                  >
                    <img className="imageSize" src={userAppQR} alt="QR Code" />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: { lg: "622px", md: "467px", sm: "350px", xs: "auto" },
                width: { sm: "auto", xs: "100%" },
                maxWidth: { xs: "320px", sm: "100%", md: "100%" },
                display: { xs: "none", sm: "block" },
              }}
            >
              <img
                className="imageSize"
                src={MobileStacked}
                alt="App screenshot"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Suraksha kavach */}
      <Box sx={{ maxWidth: "100%" }}>
        <Box
          className="container"
          sx={{ py: { lg: "80px", md: "64px", sm: "48px", xs: "32px" } }}
        >
          <Box
            className="flexrow"
            sx={{
              justifyContent: "space-between",
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            <Box
              className="flexcol"
              sx={{
                rowGap: "40px",
                maxWidth: { lg: "660px", md: "495px", sm: "398px", xs: "100%" },
              }}
            >
              <Box className="flexcol" sx={{ rowGap: "16px" }}>
                <Typography className="font700 heading">
                  IL Suraksha Kavach
                </Typography>
                <Box
                  sx={{
                    height: {
                      lg: "622px",
                      md: "467px",
                      sm: "350px",
                      xs: "auto",
                    },
                    width: { sm: "auto", xs: "100%" },
                    maxWidth: { xs: "320px", sm: "100%", md: "100%" },
                    display: { xs: "block", sm: "none" },
                    mx: "auto",
                  }}
                >
                  <img
                    className="imageSize"
                    src={SurakshaKavach}
                    alt="Suraksha Kavach"
                  />
                </Box>
                <Typography
                  className="font400 p1"
                  sx={{ lineHeight: "1.4", color: "#9A9A9A" }}
                >
                  Our IL Suraksha Kavach membership program offer you exclusive
                  access to a range of benefits designed to enhance your legal
                  experience.
                </Typography>
              </Box>
              <Box
                className="flexcol"
                sx={{ rowGap: "64px", alignItems: "flex-start" }}
              >
                <Box
                  className="flexrow"
                  sx={{
                    // columnGap: { lg: '56px', md: '48px', sm: '18px', xs: "32px" },
                    flexWrap: { xs: "wrap", sm: "wrap" },
                    alignItems: "stretch",
                    justifyContent: "center",
                    rowGap: "16px",
                    width: "100%",
                  }}
                >
                  <Box
                    className="flexrow"
                    sx={{
                      width: { sm: "100%", xs: "100%", md: "100%", lg: "50%" },
                      justifyContent: {
                        sm: "space-around",
                        xs: "space-between",
                      },
                    }}
                  >
                    <SeviceIcon
                      name={"Free Consultation"}
                      icon={ConsultationIcon}
                    />
                    <SeviceIcon
                      name={"Professional Services"}
                      icon={ConferenceIcon}
                    />
                  </Box>
                  <Box
                    className="flexrow"
                    sx={{
                      width: { sm: "100%", xs: "100%", md: "100%", lg: "50%" },
                      justifyContent: {
                        sm: "space-around",
                        xs: "space-between",
                      },
                    }}
                  >
                    <SeviceIcon
                      name={"Exclusive Legal Talk"}
                      icon={JudgeIcon}
                    />
                    <SeviceIcon
                      name={"Free Study Material for Law Students"}
                      icon={BookIcon}
                    />
                  </Box>
                </Box>
                <Download name={"Explore More"} path={"/IL-suraksha-kavach"} />
              </Box>
            </Box>
            <Box
              sx={{
                width: { lg: "480px", md: "384px", sm: "307px", xs: "100%" },
                display: { xs: "none", sm: "block" },
                height: { lg: "397px", md: "318px", sm: "254px", xs: "auto" },
              }}
            >
              <img
                className="imageSize"
                src={SurakshaKavach}
                alt="SurakshaKavach"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Services */}
      <Box sx={{ maxWidth: "100%" }}>
        <Box className="container">
          <Box
            sx={{
              positon: "relative",
              // pb: { md: '140px', sm: '64px', xs: '64px' },
              pt: { lg: "80px", xs: "40px" },
            }}
          >
            <Box
              className="flexcol"
              sx={{
                position: "relative",
                zIndex: "10px",
                rowGap: "60px",
                backgroundImage: `url(${serviceBackground})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: { md: "-80px -92px", xs: "center" },
                backgroundSize: "cover",
                height: { lg: "809px" },
                overflow: "hidden",
              }}
            >
              <Box
                className="flexcol"
                sx={{
                  rowGap: "16px",
                  alignItems: "center",
                  pt: { lg: "64px", md: "48px", sm: "0px", xs: "0px" },
                }}
              >
                <Typography className="font700 heading">
                  Our Services
                </Typography>
                <Typography
                  className="font600 p1"
                  sx={{
                    maxWidth: "831px",
                  }}
                >
                  We offer legal service that helps people create legal
                  documents and get legal advice as fast as possible.{" "}
                </Typography>
              </Box>
              <ServicesSlider serviceData={ServicesHomepage} />
              {/* <Box className='flexrow' sx={{ columnGap: '32px', justifyContent: 'center', flexWrap: 'wrap', rowGap: '32px' }}>
                <Box className='flexrow' sx={{ columnGap: '32px', justifyContent: 'center', flexWrap: 'wrap', rowGap: '32px' }}>
                  <ServicesCard image={talktolawyer} labelName={'Talk to Lawyer'} path={'/talk-to-lawyer'} />
                  <ServicesCard image={legalDocument} labelName={'Business and Contracts'} path={'/'} />
                </Box>
                <Box className='flexrow' sx={{ columnGap: '32px', justifyContent: 'center', flexWrap: 'wrap', rowGap: '32px' }}>
                  <ServicesCard image={property} labelName={'Real Estate'} path={'/real-estate'} />
                  <ServicesCard image={startUp} labelName={'Start Up'} path={'/'} />
                </Box>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* cta updates */}
      <Updates />
      {/* testimonial Section */}
      {/* <Testimonials review={reviewHome} /> */}
      {/* faq section */}
      <FAQ ques={faq} />

      {/* cta section */}
      <CTA
        user={true}
        appLink={
          "https://play.google.com/store/apps/details?id=com.indialegal.user"
        }
      />
    </>
  );
};

export default Homepage;

import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { careerOpp } from "./constant";
import { getAllJobs } from "../../Shared/Services/UserService";
const Constant = () => {
  const navigate=useNavigate();

  const [openJobs, setOpenJobs] = useState([])

  useEffect(() => {
    const getJobs = async () => {
      const res = await getAllJobs()
      if(res.code === 200) {
        setOpenJobs(res.data)
      }
    }
    getJobs()
  }, [])

  return (
    <Box
      sx={{
        display: "flex",
        gap: { md: "80px", sm: "48px", xs: "32px" },
        flexFlow: "wrap",
        justifyContent: "center",
      }}
    >
      {openJobs.length > 0 ? openJobs.map((i,ind) => (
        <Box key={`career-${ind}`} sx={{ width: "280px", cursor: "pointer" }}>
          <Box key={`careerWrapper-${ind}`}>
            <Link key={`careerHeading-${ind}`}
              className="font600 subHeading noDecoration"
              to={`/careers/${i._id}`}
              state={{job: i}}
              // onClick={() => navigate(`/careers/${i.link}`)}
              // onClick={() =>
              //   navigate({
              //     pathname: `/careers/current-openings`,
              //     search: createSearchParams({ role: `${i.heading}` }).toString(),
              //     state: { heading:"" }
              //   })
              // }
            >
              {i?.designation}
            </Link>
            {/* </a> */}
          </Box>
          <Box key={`careerdepartmentWrapper-${ind}`}
          sx={{ display: "flex", justifyContent: "center", gap: "2px" }}>
            <Typography  key={`careerdepartment-${ind}`}
              className="font400 p3"
              sx={{ color: "#D12E29 !important" }}
            >
              {i?.department}
            </Typography >
           < Typography key={`careerdescr-${ind}`}className="font400 p3" sx={{color:"#272828 !important"}}>|</Typography>
            <Typography key={`careerlocation-${ind}`}className="font400 p3">{i?.location}</Typography>
          </Box>
        </Box>
      )) : <Typography className="font600 subHeading">We will be post openings soon.</Typography>}
    </Box>
  );
};

export default Constant;

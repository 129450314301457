import { React, useState } from "react";
import { Box, Typography } from "@mui/material";
import plus from "../assets/logo/plus-circle.svg";
import minus from "../assets/logo/minus-circle.svg";

const Faq = ({ ques }) => {
  const [isActive, setIsActive] = useState();
  const [status, setStatus] = useState(false);
  const Faqs = JSON.parse(ques);
  const handleFaq = (id) => {
    const prevId = isActive;
    if(prevId === id) {
        setStatus(false)
        setIsActive()
    }
    else {
        setStatus(true)
        setIsActive(id);
    }
  };
  
  const setActiveClass = (id) => {
    const flag = status;
    if (id === isActive) {
      switch (flag) {
        case true:
          return "font500  faqOpen p3";
          break;
        case false:
          return "font500  faqClose p3";
          break;
      }
    }
    return "font500 faqAns";
  };
  const faqIcon = (id) => {
    const flag = status;
    if (id === isActive) {
      switch (flag) {
        case true:
          return minus;
          break;
        case false:
          return plus;
          break;
      }
    }
    return plus;
  };


  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Box className="container">
        <Box
          sx={{
            py: { sm: "64px", xs: "32px" },
            display: "flex",
            columnGap: "124px",
            flexDirection: { lg: "row", xs: "column" },
            rowGap: "32px",
            // px:{lg:'10px', md:'0px'}
            // px:{lg:'10px'}
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "24px",
              maxWidth: "426px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
              }}
            >
              <Typography
                className="primary font700"
                sx={{
                  fontSize: "20px",
                  lineHeight: "1.25",
                }}
              >
                Support
              </Typography>
              <Typography className="font700 heading">
                Frequently Asked Questions
              </Typography>
            </Box>
            <Typography
              className="font500"
              sx={{
                fontSize: { sm: "16px", xs: "14px" },
                lineHeight: "1.6",
                color: "#6C6C6C",
              }}
            >
              Everything you need to know about us. Can’t find the answer you’re
              looking for? Please reach out to our{" "}
              <a
                className="font500"
                style={{
                  textDecoration: "none",
                  fontSize: { lg: "16px", md: "14px", xs: "14px" },
                  lineHeight: "1.6",
                  color: "#6C6C6C",
                }}
                href="mailto: support@indialegalapp.com "
              >
                {" "}
                <span className="linkstyle">Customer Support.</span>
              </a>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: { sm: "28px", xs: "18px" },
              width: { lg: "650px", xs: "100%" },
            }}
          >
            {Faqs.map((FAQ) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    columnGap: { sm: "78px", xs: "24px" },
                    justifyContent: "space-between",
                  }}
                  key={FAQ.id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "16px",
                    }}
                    key={FAQ.id * 10}
                  >
                    <Box sx={{ whiteSpace: "pre-line" }}>
                      <Typography
                        className="font700 p1"
                        sx={{
                          color: "#272828 !important",
                          lineHeight: "1.25",
                        }}
                        key={FAQ.id * 20}
                      >
                        {FAQ.ques}
                      </Typography>
                    </Box>
                    <Box key={FAQ.id * 2}>
                      <Typography
                        className={setActiveClass(FAQ.id)}
                        key={FAQ.id * 40}
                      >
                        {FAQ.ans}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: { md: "24px", sm: "18px", xs: "16px" },
                      height: { md: "24px", sm: "16px", xs: "14px" },
                      minWidth: { md: "24px", sm: "18px", xs: "16px" },
                      cursor: "pointer",
                    }}
                    key={FAQ.id * 30}
                    onClick={() => {
                      handleFaq(FAQ.id);
                    }}
                  >
                    {/* {faqIcon()} */}
                    <img
                      className="imageSize"
                      key={FAQ.id * 65}
                      src={faqIcon(FAQ.id)}
                      alt="circle"
                    />
                    {/* <svg key={FAQ.id * 65} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path className="rotate" d="M12 8V16" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 12H16" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg> */}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Faq;

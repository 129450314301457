import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  BCKIMG,
  BOX2ND,
  SERVICE2NDHEADING,
  SERVICE2NDPARA,
  SERVICEHEADING,
} from "./style";
import Download from "../../component/downloadButton";
import tick from "../../assets/logo/tick.svg";
import {faq, propertyServices } from "./constant";
import Testimonials from "../../component/testimonials";

import Faq from "../../component/Faq";
import propertyImgN from "../../assets/image/property2ndImg1.webp"
import ServiceListSlider from "../../component/ServiceListSlider";
import CTA from "../../component/cta";
import HowItWorks from "../../component/HowItWorks";
const Property = () => {
  // const [show, setShow] = useState(0);
  const services = JSON.parse(propertyServices);
  const [isActive, setIsActive] = useState(0);
  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          // mt: { md: "200px", sm: "160px", xs: "112px" },
          mt: { md: '150px', sm: '144px', xs: '72px' },
          mb: { lg: "60px", md: "60px", sm: "60px", xs: "32px" },
        }}
      >
        <Box className="container">
          <Box
            sx={{
              // p: { lg: "60px", md: "30px", sm: "20px", xs: "20px" },
              display: "flex",
              // alignItems:{lg:"center",xs:"unset",sm:"center"},
              justifyContent: 'space-between',
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "row",
                xs: "column-reverse",
              },
              // gap: { lg: "100px", md: "100px", sm: "64px", xs: "0" },
              gap: { sm: "40px", xs: "32px" },
            }}
          >
            <Box className="flexcol"
                            sx={{
                                rowGap: '40px',
                            }}>
            <Box
              sx={{
                // width: { lg: "50%", md: "50%", xs: "100%", sm: "50%" },
                // // pt: {lg:"0px", md:"0px", sm:"0",xs:"0"},
                // pt: { md: '40px', sm: '28px', xs: '0px' },
                rowGap: '16px',
                maxWidth: { md: '560px', sm: '400px' },
                pt: { md: '40px', sm: '28px', xs: '0px' },
                alignItems: 'flex-start',
              }}
            >
              <Typography
                className="font700 bannerHeading"
                // sx={{
                //   fontSize: { lg: "48px", md: "48px", sm: "32px", xs: "32px" },
                //   lineHeight: "1.25",
                //   color: "#272828",
                // }}
              >
                Real Estate
              </Typography>
              <Typography
              className="font400 p1"
                sx={[
                  BOX2ND,
                  // {
                  //   fontSize: {
                  //     lg: "20px",
                  //     md: "20px",
                  //     sm: "16px",
                  //     xs: "16px",
                  //   },
                  // },
                ]}
              >
                Planning to buy a property? Well, we can help you get ready for a major paperwork as
the process involves providing to authorities and the bank in case you are taking a
property loan and acquiring several documents this ensures your ownership over the
property. A real estate contract is a legally binding agreement between two or more
parties for exchange, purchase, or any other type of real estate transaction. The
document outlines the terms agreed upon after negotiations have ensued. However,
the laws regarding real estate contracts may vary from state to state. Therefore, it is
prudent to hire a lawyer before signing anything.
              </Typography>
            </Box>
            <Box className="flexcol"
                                sx={{
                                    rowGap: { md: '80px', sm: '64px', xs: '36px' },
                                    alignItems: 'flex-start'
                                }}>
                                <Box className="flexcol"
                                    sx={{
                                        rowGap: "16px",
                                    }}>
                                    <Box className="flexrow" sx={{
                                        columnGap: '40px', flexDirection: { xs: 'column', sm: 'row' }, rowGap: "18px",
                                    }} >
                                        <Box className="flexrow" sx={{ columnGap: "10px", width: '152px', alignItems: "center" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Legal consultation
                                            </Typography>
                                        </Box>
                                        <Box className="flexrow" sx={{ columnGap: "10px" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Realtime Reports
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className="flexrow" sx={{ columnGap: '40px', flexDirection: { xs: 'column', sm: 'row' }, rowGap: "18px", }} >
                                        <Box className="flexrow" sx={{ columnGap: "10px", width: '152px', alignItems: "center" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                Easy To Use
                                            </Typography>
                                        </Box>
                                        <Box className="flexrow" sx={{ columnGap: "10px" }}>
                                            <Box sx={{ width: '16.67px', height: "16.67px" }}>
                                                <img className="imageSize" src={tick} alt="tick" />
                                            </Box>
                                            <Typography className='font600' sx={{
                                                fontSize: '14px',
                                                lineHeight: '1.4',
                                                color: '#242424',
                                            }}>
                                                24x7 Call Support
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Download name={"Download App"} 
                                applink= {'https://play.google.com/store/apps/details?id=com.indialegal.user'}/>
                            </Box>
            </Box>
            <Box
              // sx={[
              //   BCKIMG,
              //   { width: { lg: "50%", md: "50%", sm: "50%", sm:"382px",xs: "auto" } ,
              //   height: { md: '449px', sm: '336px', xs: "237px" }
              // },
                
              // ]}
              sx={{
                width: { md: '510px', sm: '382px', xs: "auto" },
                height: { md: '449px', sm: '336px'},
                minHeight: "240px",
                objectFit: 'contain'
              }}
            >
              <img 
                src={propertyImgN}
                style={{ width: "-webkit-fill-available",height:"-webkit-fill-available" }}
                />
            </Box>
          </Box>
          {/* serviceSection */}
          <Box sx={{ 
            mt: { lg: "80px", md: "64px", sm: "40px", xs: "28px" }
           }}>
            <Box>
              <Typography
                className="font700"
                sx={{
                  fontSize: { lg: "32px", md: "32px", sm: "28px", xs: "28px" },
                  textAlign: "center",
                }}
              >
                Real Estate Services
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
                mt: { lg: "40px", md: "40px", sm: "40px", xs: "28px" },
                mb: { lg: "100px", md: "100px", sm: "50px", xs: "50px" },
                flexWrap: "wrap",
                overflow: 'hidden'
              }}
            >
            
              <ServiceListSlider
                serviceList={propertyServices}
                setIsActive={setIsActive}
                isActive={isActive} />
            </Box>

            {/* Description */}
            <Box className='container'>
              <Typography className='font700 heading'
                sx={{ mb: { lg: '32px', md: '24px', sm: '18px', xs: '18px' } }}>
                {services[isActive].heading}
              </Typography>
              {((services[isActive]?.description) ?
                (<Typography className='font400 p1 preLine'
                  sx={{ mb: { lg: '32px', md: '24px', sm: '18px', xs: '18px' } }}>
                  {services[isActive].description}
                </Typography>) : null)}

              <Box className='flexcol'
                sx={{
                  rowGap: { lg: '32px', md: '24px', sm: '18px', xs: '18px' }
                }} >
                {services[isActive]?.content.map((el, ind) => {
                  return (
                    <Box key={`service-${ind}`}>
                      <Typography key={`service-${el.subHeading}-${ind}`}
                        className='font600 subHeading'
                        sx={{ mb: { lg: '24px', md: '20px', sm: '16px', xs: '16px' } }}>
                        {el.subHeading}
                      </Typography>
                      <Typography key={`serviceDes-${el.subHeading}-${ind}`}
                        className='font400 p1 preLine'>
                        {el.subDesc}
                      </Typography>
                    </Box>
                  )
                })
                }

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* How it works? */}
      <Box sx={{ maxWidth: "100%" }}>
        <Box className="container">
          <Box
            className="flexcol"
            sx={{
              rowGap: { lg: "72px", md: "64px", sm: "48px", xs: "32px" },
              mb: "40px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              className="font700 heading">
              How it works?
            </Typography>
            <Box
              // className="flexrow"
              sx={{
                // rowGap: { md: "80px", sm: "48px", xs: "32px" },
                // columnGap: "50px",
                // px: { lg: "0px", xs: "0px" },
                // flexWrap: "wrap",
                // justifyContent: "center",
                width:'100%'
              }}
            >
              {/* <Benefits benefits={benefits} /> */}
              <HowItWorks/>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Testimonials review={reviewProperty} /> */}
      <Faq ques={faq} />
      {/* cta section */}
      <CTA user={true} appLink={"https://play.google.com/store/apps/details?id=com.indialegal.user"} />
    </>
  );
};

export default Property;
// slider
{/* <Box
              sx={{
                display: "flex",
                flexDirection: {
                  lg: "row",
                  md: "row",
                  sm: "row",
                  xs: "column",
                },
                p: { lg: "32px", md: "20px", sm: "0", xs: "0" },
                backgroundColor: {
                  lg: "#E9E9E91F",
                  md: "#E9E9E91F",
                  sm: "#fff",
                  xs: "#fff",
                },
                gap: { lg: "32px", md: "32px", sm: "32px", xs: "32px" },
                borderRadius: "10px",
                pb: { lg: "32px", xs: "18%" },
              }}
            >
              <Box
                sx={{
                  width: {
                    lg: "50%",
                    md: "50%",
                    sm: "50%",
                    xs: "100%",
                  },
                }}
              >
                <img src={propertyserviceImg} style={{ width: "100%" }} />
              </Box>
              <Box
                sx={{
                  width: {
                    lg: "50%",
                    md: "50%",
                    sm: "50%",
                    xs: "100%",
                  },
                  position: "relative",
                  backgroundColor: "#fff",
                  p: {
                    lg: "32px",
                    md: "20px",
                    sm: "20px",
                    //  xs: "16px"
                  },
                  borderRadius: "10px",
                }}
              >
                <Typography variant="h1" sx={SERVICEHEADING}>
                  PROPERTY REPORT
                </Typography>
                <Typography
                  variant="h1"
                  sx={[
                    SERVICE2NDHEADING,
                    {
                      pb: { lg: "32px", xs: "0px" },
                      fontSize: {
                        lg: "32px",
                        md: "32px",
                        sm: "20px",
                        xs: "12px",
                      },
                    },
                  ]}
                >
                  {show === 1
                    ? "Why Property Report?"
                    : show === 0
                    ? "What is Property Report?"
                    : "Service Description"}
                </Typography>
                <Typography
                  variant="h2"
                  sx={[
                    SERVICE2NDPARA,
                    {
                      fontSize: {
                        lg: "16px",
                        md: "14px",
                        sm: "14px",
                        xs: "14px",
                      },
                    },
                  ]}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    position: "absolute",
                    // bottom: "2px",
                    width: "100%",
                    // right: "32px",
                    justifyContent: "center",
                  }}
                >
                  {show ? (
                    <Button
                      variant="text"
                      // style={{fontSize:"12px"}}
                      sx={{ color: "#D12E29" }}
                      onClick={() => setShow(show - 1)}
                    >
                      {/* <img src ={LeftArrow}/> */}
{/*</Box> What is Property Report
                    </Button>
                  ) : null}{" "}
                  <Box sx={{ flexGrow: 0.5 }}></Box>
                  <Button
                    onClick={() => setShow(show + 1)}
                    variant="text"
                    sx={{ color: "#D12E29" }}
                  >
                    {show == 1
                      ? "Service Description"
                      : show == 0
                      ? "Why Property Report"
                      : null}
                  </Button>
                </Box>
              </Box>
            </Box> */}
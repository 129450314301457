import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const TermsCondition = () => {
  return (
    <Box
      sx={{
        width: "100%",
        mt: { lg: "112px", md: "80px", sm: "72px", xs: "56px" },
      }}
    >
      <Box className="container">
        <Box>
          <Typography className="font700 subHeading flexstart">TERMS & CONDITIONS (INDIA LEGAL APP)
</Typography>
<br/>
<br/>
        </Box>
        <Box>
          <Typography className="preLine p2">
            <b>Terms and Conditions for using India Legal App</b>
            <br />
            The following terms and conditions constitute an agreement between
            you and E N Communication Private Limited (“India Legal App,” “we,”
            or “us”). BY USING THE INDIA LEGAL APP (the “App”), AND/OR BY
            REGISTERING WITH US,YOU SIGNIFY THAT YOU AGREE TO THESE TERMS and;
            CONDITIONS, including that you consent to the information practices
            disclosed in our Privacy Policy, which is incorporated herein by
            reference, and that you are agree to comply with applicable laws,
            governed and interpreted by the country (hereinafter referred to as
            “India”). Please note that we offer the App 'AS IS' and without
            warranties. If you are registering an account or using the App on
            behalf of an individual or entity other than yourself, you represent
            that you are authorised by such individual or entity to accept these
            Terms & Conditions on such individual or entity's behalf.
            <br />
            <br />
            In addition, you understand that the specific method, features and
            services offered on India Legal App may be altered, improved,
            removed or adapted at any time by India Legal App in its sole
            discretion and without any notice. If you do not agree with any of
            these terms, refrain using this App.
          </Typography>
          <Typography className="preLine p2">
            <b>GENERAL TERMS:</b>
            <br />
            1. India Legal App is no way, directly or indirectly responsible for
            the consultation and assistance provided by the Lawyers. We are a
            technology company enabling legal service providers to access the
            client demand through our platform. India Legal App has no control
            over the Services provided by the Lawyer listed on our platform as
            he is independent of India Legal App and not an employee ours. India
            Legal App will not be responsible in case of any
            issues/discrepancies with the Services provided by the Lawyer.
            <br />
            <br />
            All of the materials and information on the App are provided for
            informational purposes only, and may not reflect current legal
            developments or variances in the law of different jurisdictions. The
            materials and information on the App do not necessarily reflect the
            opinions of the Lawyers of the Law Firms, their partners, clients or
            affiliates. The information in the App is not guaranteed to be
            correct, complete or up to date. The mere receipt of an e-mail from
            or a 'post' on the App does not create an Lawyer-client
            relationship. The applicable Lawyer / Law Firm is solely responsible
            for providing its services to you, and you agree that India Legal
            App shall not be liable for any damages or costs of any type arising
            out of or in any way connected with your use of such services
            (including, without limitation, claims based on malpractice). You
            agree that any claim arising out of your relationship with a Legal
            expert, Law Firm or Lawyer shall be brought solely against such
            Legal Expert, Law Firm or Lawyer because India Legal App is doing
            nothing more than aggregating all kind legal service demand
            generated on the App for the listed lawyers, legal experts and the
            Law Firms to enable their respective practices, neither India Legal
            App nor any of its licensors or affiliates shall be included within
            any such claim. India Legal App, Legal experts, Lawyers /
            Professionals using India Legal App to provide online consultations
            (collectively “Lawyers”), Affiliates and Third party providers
            (collectively “Affiliates”) would serve you to the best of their
            ability under the following terms and conditions:
          </Typography>

          <br />
          <br />
          <Typography className="preLine p2">
            <b> 1. USING THE INDIA LEGAL APP APP</b>
            <br />
            All users must be eighteen years or older. In the case of minor
            Consumers under eighteen (18 years), a parent or guardian must
            accept this Agreement and guide all consultations under the services
            offered.
            <br />
            <br />
            The User takes full responsibility for ensuring that the information
            submitted is accurate. India Legal App shall make no effort to
            validate any information provided by the Consumer for use in
            Services with respect to content, correctness or usability. We with
            an intention to provide the best service possible could ask you to
            share more information as and when needed.
            <br />
            <b>2. HOW DOES IT WORK?</b>
            <br />
            <br />
            If you choose to opt a Paid Service at India Legal App, you will be
            charged a fee. The fee paid by you is paid to the Lawyer and is a
            payment by which you compensate the Lawyer for the time spent in
            providing you with services in a limited-scope Lawyer-client
            relationship. The fee is also intended in part to cover India Legal
            App's technology maintenance and other costs involved in operating
            the App business. The fee paid towards paid services is only towards
            the scope defined under the services offered for that particular
            booking and post the consultation, you may or may not engage the
            Lawyer directly towards any further services you wish to avail from
            the respective Lawyer. India Legal App is no way, directly or
            indirectly responsible for the consultation and assistance provided
            by the Lawyers. Once you pay the fee and your booking is confirmed,
            you are intimated about the same via email, SMS and / or phone.
            <br />
            <br />
            WE DO NOT IN ANY WAY SOLICIT, ENDORSE, PROMOTE ANY INDIVIDUAL LISTED
            ON APP. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
            DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH
            INFORMATION.
            <br />
            <b>3. INDEPENDENCE OF LAWYERS</b>
            <br />
            <br />
            The opinions, statements, answers (collectively “consultation”)
            provided by the Lawyers via India Legal App are solely the
            individual and independent opinions and statements of such
            individuals and do not reflect the opinions of India Legal App, its
            affiliates or any other organizations or institutions to which such
            Lawyer or such specialist or professional is affiliated or provides
            services. India Legal App does not recommend or endorse any specific
            Lawyers, products, procedures, opinions, or other information that
            may be mentioned on India Legal App or by a licensee of India Legal
            App. INDIA LEGAL APP is a technology platform to allow individual
            professionals to list themselves in order to have an access to
            demand in addition to their causal offline work.
            <br />
            <br />
            The inclusion of professionals and specialists on India Legal App or
            in any professional directory on India Legal App does not imply
            recommendation or endorsement of such professional nor is such
            information intended as a tool for verifying the credentials,
            qualifications, or abilities of any professional contained therein.
            Such information is provided on an 'as-is' basis and India Legal App
            disclaims all warranties, either express or implied, including but
            not limited to the implied warranties of merchantability and fitness
            for particular purpose.
            <br />
            <br />
            You agree that you understand Lawyers who participate in the India
            Legal App are not employed by or agents of India Legal App. Although
            India Legal App does require Lawyers to provide basic information
            about themselves to India Legal App and although India Legal App
            does rely upon and pass on this information to Users for their use,
            India Legal App does not separately verify this information and
            makes no representations as to the qualifications of any Lawyers. No
            listing of any Lawyer on this App, and no information of any kind or
            answers provided by any Lawyer, constitutes an endorsement,
            recommendation or referral of any Lawyer.
            <br />
            <br />
            In connection with their participation on India Legal App, Lawyers
            do make certain representations and warranties regarding the
            information they provide about the jurisdictions in which they are
            licensed to practice their profession and in good standing.
            Nevertheless, you are encouraged to investigate or perform your own
            due diligence concerning the Lawyers and their qualifications.
            <br />
            <br />
            No representation is made that the quality of the professional
            services to be performed is greater than the quality of professional
            services performed by other Lawyers. We urge all potential clients
            to make their own independent investigation and evaluation before
            any Lawyer is being considered.
            <br />
            <br />
            <b>4. MEMBERSHIP TERMS</b>
            <br />
            You represent and warrant that you are at least eighteen years of
            age and that you possess the legal right and the ability to enter
            into agreements pertaining to Terms & Conditions, to register on the
            India Legal App Services under your own name and to use the India
            Legal App Services in accordance with the Terms & Conditions and
            abide by the obligations hereunder.
            <br />
            <br />
            You are solely responsible for maintaining the confidentiality of
            your password and for all activities carried out under your India
            Legal App account. You agree to prohibit anyone else from using your
            password and immediately notify India Legal App of any unauthorised
            use of your password or other security concerns.
            <br />
            <br />
            No party has the power or authority as agent, employee or in any
            other capacity to represent, act for, bind or otherwise create or
            assume any obligation on behalf of the other party for any purpose
            whatsoever.
            <br />
            <br />
            International members acknowledge and agree that India Legal App may
            collect and use your Information and disclose it to other entities
            outside your resident jurisdiction. In addition, such Information
            may be stored on servers located outside your resident jurisdiction.
            By providing us with your Information, you acknowledge that you
            consent to the transfer of such Information outside your resident
            jurisdiction as detailed in our Privacy Policy. If you do not
            consent to such transfer, you may not use our Content or Services.
            <br />
            <br />
            Your affirmative act of using this App and/or registering for the
            App or the Services constitutes your electronic signature to these
            Terms & Conditions and your consent to enter into agreements with us
            electronically.
            <br />
            <br />
            The App may only be used in good faith and may not be used to
            transmit or otherwise make available any information that is false
            or that you do not have a right to make available under any law or
            under contractual relationships (such as inside information,
            proprietary and confidential information learned or disclosed as
            part of employment relationships or under nondisclosure agreements),
            to threaten, abuse, harass, or invade the rights of any person or
            entity, to infringe on any person or entity's intellectual property
            rights, or in any other way that could reasonably be deemed
            unethical, illegal, or offensive.
            <br />
            <br />
            You may not misidentify yourself or impersonate any person or
            entity, or falsely state or otherwise misrepresent your affiliation
            with a person or entity (e.g., pretend to be a different person or
            from a different company or organization).
            <br />
            <br />
            Unless you have our prior written consent, you will not post
            advertisements or promotional materials, solicit participants and/or
            visitors of the App, reproduce, duplicate, copy, sell, resell or
            exploit for any commercial purposes, any portion of the App or its
            Services, use of the App or it Services, or access to the App or its
            Services.
            <br />
            <br />
            You are prohibited from using any type of computer 'worm,' 'virus'
            or any other device that is intended or is likely to disrupt,
            overload, or otherwise impair the workings of any part of the App.
            If you do engage in such conduct, the resulting damage will be hard
            to quantify as a fixed amount and thus you hereby agree to pay us
            liquidated damages in the amount of INR 10,00,000 for each day that
            the App is damaged until the App is completely repaired and further
            unquantified damages which will occur due to your act will be
            accessed and you shall be liable to pay the same. This amount is
            derived by estimating the value of (1) the loss of good will caused
            by an inoperable App, (2) the time and money it will take to repair
            the App and to address the concerns of visitors. We are required to
            use reasonable efforts to repair the App as quickly as possible.
            This clause will not prohibit us from seeking additional
            compensation if your conduct injures us in a way not expressly
            contemplated herein.
            <br />
            <br />
            You are not permitted to collect or store personal data about other
            users. You are not permitted to access the App for the purpose of
            data mining or extracting content from the App beyond your personal
            end use.
            <br />
            <br />
            You are permitted to use the App to find a Legal professional/Lawyer
            to perform services for you related to your issues and to obtain
            general information about similar cases (each, a 'Permissible Use').
            You are not permitted to use the App for any other reason other than
            a Permissible Use as described above. Impermissible Uses shall
            include, but are not limited to, using the App to order to solicit,
            hire, engage or otherwise work with the employees or affiliates of
            India Legal App or the Lawyers, to interfere or attempt to interfere
            in the relationship between India Legal App and such employees,
            affiliates, Lawyers, or for any other purpose other than described
            herein (each, an 'Impermissible Use'). If you do engage in such
            conduct, the resulting damage will be hard to quantify as a fixed
            amount and thus you hereby agree to pay us liquidated damages in the
            amount of Rs.2,50,000 for each Impermissible Use, and further
            unquantified damages which will occur due to your act will be
            accessed and you shall be liable to pay the same. You further agree
            that this liquidated damages provision reasonably approximates
            actual costs, losses, and expenses which would be incurred by India
            Legal App due to any such Impermissible Use. You also agree that
            nothing in this section is intended to limit India Legal App’s right
            to obtain injunctive and other relief as may be appropriate.
            <br />
            <br />
            You may not forge headers or otherwise manipulate identifiers in
            order to disguise the origin of any Content transmitted through the
            App. You agree to not harm minors in any way.
            <br />
            <br />
            In the event you submit information through the App, you agree to
            provide true, accurate, current and complete information and agree
            to promptly update the information to keep it true, accurate,
            current and complete. If you provide any information that is untrue,
            inaccurate, not current or incomplete, and/or we have reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            current or incomplete, we have the right to suspend or terminate
            your participation in the App and/or refuse any and all current or
            future use of the App or its services (or any portion thereof).
            <br />
            <br />
            You understand and agree that all information, statistical data,
            text, software, music, sound, photographs, graphics, video, messages
            or other materials (Content), whether publicly posted or privately
            transmitted by you and other users of our service, are the sole
            responsibility of the person from which such Content originated.
            This means that you, and not us, are entirely responsible for all
            Content that you upload, post, email or otherwise transmit via the
            App. We do not control all of the Content posted via the App and, as
            such, do not guarantee the accuracy, integrity or quality of such
            Content. You understand that by using the App, you may be exposed to
            Content that is offensive, indecent or objectionable. Under no
            circumstances will we be liable in any way for any Content,
            including, but not limited to, for any errors or omissions in any
            Content, or for any loss or damage of any kind incurred as a result
            of the use of any Content posted, emailed or otherwise transmitted
            via the App.
            <br />
            <br />
            You acknowledge that we may or may not pre-screen Content, but that
            our designees and we shall have the right (but not the obligation)
            in our sole discretion to prescreen, refuse, or move any Content
            that is available via the App. Without limiting the foregoing, our
            designees and we shall have the right (but not the obligation) to
            remove any Content that violates this agreement or is otherwise
            objectionable.
            <br />
            <br />
            The decision to form Lawyer-client relationship is an important one
            and is between you and the Lawyer. You may wish to seek and retain
            the consultation of other Lawyers so as to meet all applicable
            deadlines that govern your query / problem. You hereby agree that,
            by taking any of the actions described above, you are soliciting and
            requesting a contact by telephone and as such, you may be contacted
            by telephone or e-mail as set forth in this Agreement and in our
            Privacy Policy, notwithstanding the listing of your telephone number
            and/or e- mail address in any applicable do-not-call registries.
            <br />
            <b>5. SCOPE OF SERVICES</b>
            India Legal App (the owners and the employee staff of the App) and
            third-party professionals who offer services through the App accept
            no responsibility for any medical, legal or financial events or
            outcomes related to services achieved through the use of the App.
            <br />
            <br />
            India Legal App does not guarantee that a web-based consultation,
            over-phone consultation or in-person / video consultation is the
            appropriate way to resolve/ solution for your particular problem.
            <br />
            <b>6. USER’S PROFILE AT INDIA LEGAL APP</b>
            Your India Legal App Profile is created to store record of your
            consultations and your personal query / problem information online,
            including history, etc.
            <br />
            <br />
            Any information provided as part of a web, over-phone or an
            in-person / video consultation becomes part of your India Legal App
            Record. You agree to provide accurate information to help us serve
            you best to our knowledge, to periodically review such information
            and to update such information as and when necessary.
            <br />
            <br />
            India Legal App reserves the right to maintain, delete or destroy
            all communications and materials posted or uploaded to the India
            Legal App Service according to its internal record retention and/or
            destruction policies.
            <br />
            <br />
            You might be contacted via email to review the information provided
            by you for India Legal App Record or for Online Consultations.
            Please make sure you provide a valid email-id and you update it as
            and when needed.
            <br />
            <b>7. SUPPLEMENTARY COMMUNICATION</b>
            <br />
            <br />
            India Legal App after your consent may send you newsletters and
            other information regarding common related topics or preventive care
            messages containing specific services’ related information, links to
            other related Apps and specific questions related to your India
            Legal App Record. Additionally, India Legal App makes available
            self-care informational services that provide general, and specific
            services’ information.
            <br />
            <br />
            These communications and resources are not comprehensive and do not
            include complete potential information pertaining to the subject
            matter. These are solely meant for general, educational and
            informational purposes and should not be relied upon as a substitute
            for user-specific query / problem evaluation or construed, directly
            or indirectly as the practice of that particular service or
            dispensing of that particular service by India Legal App.
            <br />
            <br />
            The information contained in these communications and resources is
            compiled from a variety of sources and may or may not be authored by
            India Legal App. India Legal App makes no warranty with respect to
            the content of these materials or the information contained therein.
            <br />
            <br />
            We are not responsible for anything outside of India Legal App. Now
            or in the future, the Content and Services may link you to other web
            Apps or information, software, data, or other contents, on or off
            the Internet. These other Apps or and contents are not India Legal
            App, and the information there is outside of our control. We do not
            control, endorse, or review the content of these Apps, and we are
            not responsible for such content.
            <br />
            <b>8. PROTECTION OF PROPRIETARY INFORMATION</b>
            <br />
            You agree that the Services, which include all software and
            information, both electronic and printed media, contain trade
            secrets and other proprietary intellectual property and are the
            proprietary property of India Legal App. They are protected by
            trademark laws and other proprietary rights and laws, and they may
            only be used or accessed as specifically provided for, in this
            Agreement.
            <br />
            <br />
            You agree that you will not store, copy, modify, rent, lease, loan,
            sell, distribute, transfer, transmit, display, reverse-engineer,
            reverse-assemble, or otherwise attempt to discover any programming
            code or any source code used in or with the Services. You will not
            otherwise attempt to transfer any right in the Services (which
            includes its software, design and content), create derivative works
            based on it or in any manner commercially exploit the Services in
            whole or in part. You agree that violations by you, any other person
            or entity, of these trade secrets and / or other intellectual
            property protections or the terms of this Agreement will be lead to
            prosecution under the Indian Laws and Bylaws. The final choice of
            whether the Consumer is in violation of any of these policies is at
            the sole discretion of India Legal App.
            <br />
            <br />
            Only for the duration of being logged into the Consumers’ valid
            India Legal App account, the consumer is hereby granted a revocable,
            non-exclusive, non-transferable license to use the services for
            personal, non-commercial purposes; subject to terms and
            qualifications of Agreements of India Legal App.
            <br />
            <br />
            With the exception of your case records, India Legal App retains all
            rights, titles and interests pertaining to India Legal App, the
            India Legal App Services and any content, products, documentation,
            software or other materials on the India Legal App web App along
            with any trade secret, trademark, service mark or other intellectual
            property or proprietary right in any of the foregoing.
            <br />
            <br />
            Contact us if you believe information on our App infringes your
            copyright. If you believe any materials accessible on or from India
            Legal App infringe your copyright, you may request removal of those
            materials.
            <br />
            <br />
            No part of the materials including graphics or logos, available in
            this App may be copied, photocopied, reproduced, translated or
            reduced to any electronic medium or machine-readable form, in whole
            or in part, without specific permission.
            <br />
            <br />
            Your license to access and use the App and its services are subject
            to the following additional restrictions and prohibitions on use.
            You may not (1) copy, print (except for the express limited purpose
            permitted by paragraph 8, above), republish, display, transmit,
            distribute, sell, rent, lease, loan or otherwise make available in
            any form or by any means all or any portion of the App, or any
            information or materials retrieved therefrom; (2) use the App or any
            other materials from the App to develop, or as a component of, an
            information storage and retrieval system, database, info-base, or
            similar information resource (in any media now existing or hereafter
            developed), that is offered for commercial distribution of any kind,
            including through sale, license, lease, rental, subscription, or any
            other commercial distribution mechanism; (3) create compilations or
            derivative works of the App or any other materials from the App; (4)
            use the App or any other materials from the App in any manner that
            may infringe any intellectual property right, or proprietary right
            of India Legal App or any third parties; (5) remove, change, or
            obscure any proprietary notice or Terms & Conditions contained in
            the App; (6) make any portion of the App available through any
            timesharing system, service bureau, the Internet, or any other
            technology now existing or developed in the future, without our
            express written consent; (7) remove, disable, defeat or change any
            functionality or appearance of the App; (8) decompile, disassemble
            or reverse engineer any App software or use any network monitoring
            or discovery software to determine the App architecture; (9) use any
            automatic or manual process to harvest information from the App;
            (10) use the App for the purpose of gathering information for or
            transmitting (a) unsolicited commercial email or SMS; (b) email or
            SMS that makes use of our name or trademarks, including in
            connection with invalid or forged headers, invalid or nonexistent
            domain names, or other means of deceptive addressing; and (c)
            unsolicited telephone calls or facsimile transmissions; (11) use the
            App in a manner that violates any state or federal law regulating
            commercial email, facsimile transmissions or telephone
            solicitations; and (12) export or re- export the App or any portion
            thereof, or any software available on or through the App, in
            violation of the export control laws and regulations of the United
            States.
            <br />
            <b>9. ETIQUETTE AND PRIVACY</b>
            <br />
            The resources of India Legal App should not be used to impersonate
            another person or misrepresent authorization to act on behalf of
            others or India Legal App. All messages transmitted via India Legal
            App should correctly identify the sender. The Consumer should never
            alter the attribution of origin in electronic mail messages or while
            posting any information.
            <br />
            <br />
            India Legal App has zero-tolerance for unsolicited bulk email, bulk
            SMS, unsolicited posting to news groups, marketing
            gimmicks/spam/scams in community resources, or other illegal
            activities. The Consumer shall not infringe upon the rights of
            others, spam, propagate computer worms or viruses, use a false
            identity, attempt to gain unauthorised entry to any App or network,
            infringe trademarks, or other intellectual property rights. The
            Consumer further agrees to comply with Indian export laws concerning
            the transmission of technical data and other regulated materials via
            the World Wide Web.
            <br />
            <br />
            The Consumer agrees to indemnify and hold India Legal App harmless
            for any and all acts found or suspected to be in violation hereof.
            The Consumer’s account will be terminated for any of the above
            infractions.
            <br />
            <br />
            The Consumer shall not attempt to undermine the security or
            integrity of computing systems or networks of India Legal App, its
            partners, or those accessed through or with their product, and must
            not attempt to gain unauthorised access.
            <br />
            <br />
            <b>
              India Legal App reserves the right to terminate any account which
              doesn’t follow our Terms and Conditions.
            </b>
            <br />
            <b>10. ELECTRONIC PAYMENT</b>
            <br />
            India Legal App may from time to time contract with third party
            payment service providers including banks to open nodal bank account
            under applicable Indian laws, to facilitate the payment between
            users i.e. buyers and sellers and for collection of Platform Fees
            and other charges. These third party payment service providers may
            include third party banking or credit card payment gateways, payment
            aggregators, cash on delivery or demand draft / pay order on
            delivery service providers, mobile payment service providers or
            through any facility as may be authorised by the Reserve Bank of
            India for collection, refund and remittance, as the case may be of
            payment or supporting the same in any manner.
            <br />
            <br />
            You agree, understand and confirm that the bank / debit / credit
            card details provided by you for availing services of India Legal
            App will be correct and accurate. These details are entered by you
            directly at the third party payment service provider webpage and
            India Legal App does not have any access whatsoever to your payment
            details.
            <br />
            <br />
            Your use of these Third Party Payment service providers is subject
            to their Terms & Conditions. You understand that India Legal App is
            not responsible or liable for the behaviour, features, or content of
            any Third Party Payment service provider.
            <br />
            <br />
            India Legal App cannot assist with a stop payment request for any
            payment scheduled using a Payment Account credit/debit card. In any
            such case, you must immediately and directly contact your
            credit/debit card company/bank.
            <br />
            <b>11. PAYMENT, CANCELLATION & REFUND POLICY</b>
            <br />
            <b>Payment Policy</b> All fee has to be paid upfront before availing
            any paid service at India Legal App.
            <br />
            <br />
            <b>Cancellation Policy</b>
            <br />
            <br /> In the event of cancellation of any paid consultation for
            which payment has been successfully made and a confirmation number
            has been generated, and the consultation has not been concluded due
            to any avoidable / unavoidable reason(s) we must be notified of the
            same in writing – by an email at info@India Legal App.
            <br />
            <br />
            Cancellation charges will be effective from the date we receive your
            email. Cancellation can be requested no later than 24 hours before
            final delivery time / Appointment time.
            <br />
            <br />
            Our Customer Service team will contact you within 7 business days
            and in cases where the Lawyer has not delivered the booked
            consultation, we will return your money within 15 business days from
            the day the request for Cancellation was received.
            <br />
            <br />
            Please note that there will be a nominal deduction (10%) in the
            amount refunded, payable towards online payment processing. The
            refund will be processed directly in your Bank / Credit Card account
            that was used to make the payment.
            <br />
            <br />
            Refund Policy In the event your request for consultation is accepted
            but post-payment due to unexpected and extenuating circumstances,
            your request for consultation is rejected or cancelled; You will be
            given an option of opting for another request for consultation OR
            will be offered a complete refund, if you so desire. The refund will
            be credited to your account within 15 working days.
            <br />
            <br />
            In case your credit card/debit card/payment account has been
            accidentally over-charged, please notify India Legal App of the same
            at the earliest. India Legal App will refund the outstanding amount
            to your Payment Account within 15 working days. In the reverse
            scenario, if the transaction amount during payment of the fee
            happens to be less than the actual fee amount, India Legal App will
            debit the outstanding amount from your Payment Account and notify
            you of the same.
            <br />
            <br />
            It is mandatory for the users to maintain unique Transaction ID
            numbers, which would be provided at the time of placing a request.
            This number would be required to address any problems, if any.
            <br />
            <br />
            In the majority of cases, there is NO other situation under which
            India Legal App will provide a refund, except for the condition
            expressly mentioned above under Refund Policy. In exceptional
            circumstances, the India Legal App team will work with you to reach
            a mutually acceptable solution. India Legal App intends to be
            objective, fair and reasonable in resolving such situations in order
            to maintain a positive and harmonious relationship with consumers.
            The maximum refund amount claimable from India Legal App cannot
            exceed the amount paid by the user at India Legal App for availing
            the Lawyer's service(s).
            <br />
            <br />
            No refund in any case whatsoever can be claimed from India Legal App
            for the amount paid to the Lawyer directly by the user.
            <br />
            <br />
            Please note that there will be a nominal deduction (10%) in the
            amount refunded, payable towards online payment processing. The
            refund will be processed directly in your Bank / Credit Card account
            that was used to make the payment.
            <br />
            <b>12. SUSPENSION DUE TO NON-COMPLIANCE WITH AGREEMENT</b>
            <br />
            India Legal App will make all possible efforts to provide you the
            best services for your payment. However, India Legal App shall not
            incur any liability and shall declare any service guarantee void in
            event of one or more of the following circumstances:
            <br />
            If you have not provided accurate details including contact
            information (user’s email and/or phone number), username and Payment
            account information.
            <br />
            If the transaction for payment doesn’t go through with your bank due
            to (including but not limited) insufficient funds, fraudulent
            access, failure of processing the request by our Affiliate merchants
            or your bank.
            <br />
            If circumstances beyond the control of India Legal App (such as, but
            not limited to, fire, flood, or interference from external forces)
            prevent proper execution of the transaction.
            <br />
            If you don’t respond to requests of more information (if needed) by
            the Lawyers to make a consultation. We would intimate you the
            needful via email and/or phone and/or SMS and/or as a message in
            your India Legal App account.
            <br />
            If the user is in breach of any of the Terms and Conditions of this
            Agreement and/or the Terms and Conditions of the usage of India
            Legal App.
            <br />
            <b>13. DISCLAIMERS</b>
            <br />
            <br />
            You acknowledge that your use of the India Legal App Services is
            solely at your own risk, and that you assume full responsibility for
            all risks associated therewith. To the extent that any of the
            Content included on India Legal App is provided by third party
            content providers or other users, we have no editorial control or
            responsibility of such content appearing on India Legal App. All
            information, products or services contained on or provided through
            this App are provided 'as is', without any warranty of any kind,
            express or implied, to the fullest extent permissible under
            applicable law. India Legal App and its Affiliates hereby disclaim
            all representations and warranties, express or implied, statutory or
            otherwise, including but not limited to warranties of
            merchantability, appropriateness, title, non-infringement and
            freedom from virus strains.
            <br />
            <br />
            Third-party content may appear on this App or may be accessible via
            links from this App. We shall not be responsible for and assume no
            liability for any mistakes, misstatements of law, defamation,
            omissions, falsehood, obscenity, pornography or profanity in the
            statements, opinions, representations or any other form of content
            contained in any third-party content appearing on the App. You
            understand that the information and opinions in the third-party
            content represent solely the thoughts of the author and is neither
            endorsed by us nor does it reflect our beliefs.
            <br />
            <br />
            The App may contain 'hyperlinks' to Apps neither owned nor
            controlled by us and we are not responsible for, and, unless
            otherwise noted specifically, make no representations or
            endorsements with respect to these Apps or with respect to any
            service or product associated with these Apps. By including these
            hyperlinks, we do not mean to state or imply that we sponsor, are
            affiliated with, or are legally authorised to use any trade name,
            registered trademark, service mark, logo, legal or official seal, or
            copyrighted symbol that may be reflected in the hyperlinks.
            <br />
            <br />
            Your correspondence or business dealings with, or participation in
            promotions of, advertisers found on or through the App, including
            payment and delivery of related goods or services, and any other
            terms, conditions, warranties or representations associated with
            such dealings, are solely between you and such advertiser. You agree
            that we shall not be responsible or liable for any loss or damage of
            any sort incurred as the result of any such dealings or as the
            result of the presence of such advertisers on the App.
            <br />
            <br />
            Without limiting the foregoing, India Legal App and its affiliates
            make no warranty as to the reliability, accuracy, timelines,
            usefulness, adequacy, completeness or suitability of the India Legal
            App products and services provided hereunder. India Legal App makes
            no representations or warranties about the satisfaction of
            government regulations.
            <br />
            <br />
            India Legal App will take all necessary precautions to protect
            itself against failure of our hardware and our software. The
            Consumer acknowledges and agrees that temporary interruptions in
            service may occur, and that India Legal App shall have no liability
            for any claim, cost, charge, loss or expense arising from or
            relating to use of the Services. India Legal App makes no warranty
            that the Services will meet your requirements, or that the
            Service(s) will be uninterrupted, timely, secure, or error free. The
            Consumer acknowledges and agrees that in the event of failure,
            restoration of service may take several days.
            <br />
            <br />
            We reserve the right at any time and from time to time to modify or
            discontinue, temporarily or permanently, the App (or any part of it)
            with or without notice. In the event of a modification or
            discontinuance, all information submitted by you and others may be
            lost. You agree to keep a permanent record of all information
            provided to us, and you agree that all information submitted to us
            is at your sole risk. You agree that we have no responsibility or
            liability for the deletion or failure to store any Content
            maintained or transmitted by the App including without limitation
            messages and other communications. You agree that we shall not be
            liable to you or to any third party for any modification, suspension
            or discontinuance of the Service.
            <br />
            <br />
            We do not represent or warrant that the App will be error-free, free
            of viruses or other harmful components, or that defects will be
            corrected. We do not warrant or represent that the information
            available on or through the App will be correct, accurate, timely,
            or otherwise reliable. We may (but are not obligated to) make
            improvements and/or changes to its features, functionality or
            content of the App at any time. In no event shall we be liable for
            any such errors or defects.
            <br />
            <b>14. DISCLAIMER OF WARRANTIES</b>
            <br />
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT: YOUR USE OF THE APP IS AT
            YOUR SOLE RISK. ALL CONTENT AND SERVICES ON THE APP IS PROVIDED
            SOLELY ON AN 'AS-IS/AS-AVAILABLE' BASIS. TO THE EXTENT PERMITTED BY
            APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
            IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, SATISFACTORY
            QUALITY, FITNESS FOR A PARTICULAR PURPOSE OR USE, AS TO THE RESULTS
            YOU MAY ACHIEVE ON ACCOUNT YOUR USE OF THE APP, AS TO THE RESULTS
            YOU MAY ACHIEVE ON ACCOUNT OF ANY RELATIONSHIP YOU ELECT TO CREATE
            WITH LAWYER / PROFESSIONAL, AND NON-INFRINGEMENT. NEITHER INDIA
            LEGAL APP NOR ANY OF ITS LICENSORS OR AFFILIATES MAKES ANY
            REPRESENTATIONS OR WARRANTIES REGARDING ANY LAWYER / PROFESSIONAL,
            INCLUDING, WITHOUT LIMITATION, ANY LAWYER / PROFESSIONAL’s ABILITY
            TO OBTAIN A FAVOURABLE RESULT FOR YOU.
            <br />
            <br />
            WE DO NOT GUARANTEE THE ACCURACY OR COMPLETENESS OF ANY CONTENT OR
            SERVICES AND WE DO NOT GUARANTY IN ANY SERVICES OR GOODS ASSOCIATED
            WITH THE APP WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY
            SERVICE OR GOOD WILL CONTINUE TO BE AVAILABLE.
            <br />
            <br />
            ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
            SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
            DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
            <br />
            <br />
            NO INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR
            THROUGH OR FROM THE APP SHALL CREATE ANY WARRANTY NOT EXPRESSLY
            STATED IN THIS AGREEMENT.
            <br />
            <b>15. LIMITATION OF LIABILITY</b>
            <br />
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT WE SHALL NOT BE LIABLE FOR
            ANY, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
            DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES,
            PROFITS, GOODWILL, USE, DATA, FAILURE TO REALISE EXPECTED SAVINGS,
            OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE
            INABILITY TO USE THE APP; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE
            GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR
            SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS
            ENTERED INTO THROUGH OR FROM THE APP; (iii) INVALID DESTINATIONS,
            TRANSMISSION ERRORS, OR UNAUTHORISED ACCESS TO OR ALTERATION OF YOUR
            TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY
            ON THE APP; (v) YOUR FAILURE TO RECEIVE ANY THIRD PARTY SERVICES OR
            PRODUCTS REQUESTED THROUGH THE APP OR (vi) ANY OTHER MATTER RELATING
            TO THE APP. IN NO EVENT ARE WE LIABLE TO YOU FOR AN AMOUNT IN EXCESS
            OF THE AMOUNT PAID BY YOU TO US FOR THE SERVICES IN QUESTION, IF
            ANY.
            <br />
            <b>16. EXCLUSIONS AND LIMITATIONS</b>
            <br />
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES
            OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
            CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF
            THE SECTIONS ABOVE MAY NOT APPLY TO YOU.
            <br />
            <b>17. INDEMNIFICATION</b>
            <br />
            You shall defend, indemnify, save and hold India Legal App, its
            affiliates harmless from all demands, liabilities, claims and
            expenses, including Lawyer's fees, fees of third parties relating to
            or arising under this Agreement, the Services provided hereunder or
            your use of the Services, including without limitation, infringement
            by you or someone else using the service with your computer, of any
            intellectual property or other proprietary right of any person or
            entity, or from the violation of any of our operating rules or
            policy relating to the Service(s) provided.
            <br />
            <br />
            When we are threatened with suit by a third party, we may seek
            written assurances from you concerning your promise to indemnify us;
            your failure to provide those assurances may be considered by us to
            be a breach of your agreement and may result in deactivation of your
            service(s) with immediate effect.
            <br />
            <br />
            Under no circumstances, including negligence, shall India Legal App
            including (it’s Affiliates) or anyone else involved in creating,
            producing or distributing India Legal App Services be liable for any
            direct, indirect, incidental, special or consequential damages that
            result from the use of or inability to use India Legal App Services,
            or that results from mistakes, omissions, interruptions, deletion of
            files, errors, defects, delays in operation, or transmission or any
            failure of performance, whether or not limited to acts of God,
            communication failure, theft, destruction or unauthorised access to
            India Legal App records, programs or services. This paragraph shall
            apply to all content and functionality of India Legal App Services.
            <br />
            <br />
            Notwithstanding the above, the Consumer's exclusive remedies for all
            damages, losses and causes of actions whether in contract, including
            negligence or otherwise, shall not exceed the aggregate amount,
            which the Consumer paid directly to India Legal App for the service
            in question. Such limitations shall apply to India Legal App total
            liability, including without limitation any liability for damages
            caused or allegedly caused by any failure of performance, error,
            omission, interruption, deletion, defect, delay in operation of
            transmission, communications failure, theft of destruction of or
            unauthorised access to, alteration of, or use of records, whether
            for breach of contract, tortuous behaviour, negligence, or under any
            other cause of action.
            <br />
            <br />
            In no event shall India Legal App nor any of its affiliates be
            liable for any direct damages in excess of the purchase price for
            the product. In addition, to the maximum extent permitted by law, in
            no event shall India Legal App nor any of its affiliates be liable
            for any special, punitive, indirect, incidental or consequential
            damages, including but not limited to personal injury, wrongful
            death, loss of use, loss of profits, interruption of service or loss
            of data, whether in any action in warranty, contract, tort
            (including, but not limited to negligence or fundamental breach), or
            otherwise arising out of or in any way connected with the use of, or
            the inability to use, this App or any service offered through this
            App or any material or information contained in, accessed through,
            or products purchased on this App, even if an authorised
            representative of India Legal App is advised of the likelihood or
            possibility of the same. To the extent certain jurisdictions
            restrict any of the above limitations of liability; such limitations
            shall not apply in such jurisdictions to the extent of such
            restrictions.
            <br />
            <b>18. THIRD PARTY PROTECTION</b>
            <br />
            The indemnification and disclaimers provisions set forth above are
            for the benefit of India Legal App and its affiliates. Each of these
            individuals or entities shall have the right to assert and enforce
            those provisions directly against you, the Consumer on its own
            behalf.
            <br />
            <b>19. JURISDICTION</b>
            <br />
            This Agreement constitutes the entire understanding of the parties,
            and is agreed to being entered into within the jurisdiction of New
            Delhi, India and shall be governed by and shall be construed in
            accordance with the laws of the Republic of India, without giving
            effect to any choice of law rules or principles.
            <br />
            <br />
            Any dispute with India Legal App shall exclusively be subject to the
            jurisdiction of the appropriate Courts situated at New Delhi, India.
            No party shall object to removal or prosecution of any litigation to
            a court within the jurisdiction of New Delhi, India.
            <br />
            <br />
            Any cause of action or claim you may have with respect to India
            Legal App must be commenced within one (1) year after it arises,
            except to the extent such litigation is not enforceable.
            <br />
            <br />
            To the fullest extent permitted by law, each party to this Terms &
            Conditions waives its or his or her right to a jury trial with
            respect to any dispute or other controversy arising from hereunder
            or your use of or access to the India Legal App Services or App.
            <br />
            <br />
            To the fullest extent permitted by law, each party to this Terms &
            Conditions waives its or his or her right to a jury trial with
            respect to any dispute or other controversy arising from hereunder
            or your use of or access to the India Legal App Services or App.
            <br />
            <br />
            Failure to exercise or delay in exercising any right hereunder, or
            failure to insist upon or enforce strict performance of any
            provision of this Terms & Conditions, shall not be considered waiver
            thereof, which can only be made by signed writing. No single waiver
            shall be considered a continuing or permanent waiver.
            <br />
            <b>20. RIGHT TO OPERATIONAL FUNCTIONALITY</b>
            <br />
            India Legal App reserves complete and sole discretion with respect
            to the operation of our Services. We may, among other things
            withdraw, suspend or discontinue any functionality or feature of our
            Services.
            <br />
            <br />
            By using the App, you do not acquire any rights to the App other
            than the limited license to use the App that can be terminated in
            accordance with this section. You agree that we, in our sole
            discretion, may terminate your password, account (or any part
            thereof) or use of the App, and remove and discard any Content
            within the App, for any reason, including, without limitation,
            non-payment, for lack of use, or if we believe that you have
            violated or acted inconsistently with the letter or spirit of this
            agreement. We may also in our sole discretion and at any time
            discontinue providing the App, or any part thereof, with or without
            notice. You agree that any termination of your access to the App
            under any provision of this agreement may be effected without prior
            notice, and acknowledge and agree that we may immediately deactivate
            or delete your account and all related Content information and files
            in your account and/or bar any further access to such files or the
            App. Further, you agree that we shall not be liable to you or any
            third-party for any termination of your access to the App.
            <br />
            <b>21. RIGHT TO MODIFY TERMS OF SERVICE</b>
            <br />
            You agree and confirm that the Terms of Service may be amended from
            time to time without notice and take responsibility to update
            yourself periodically with the latest version available on India
            Legal App App. Any such revision or change will be binding and
            effective immediately on release of the revised Agreement or change
            to the Service(s) on our App. Unless we notify otherwise, these
            terms incorporate and supersede any other terms associated with the
            Services.
            <br />
            <br />
            India Legal App has the right to discontinue service, or deny access
            to anyone who violates our policies or the terms and conditions
            stated herein, without prior notice or warning.
            <br />
            <br />
            India Legal App may freely transfer, assign or delegate all or any
            part of this Terms & Conditions, and any rights and duties
            hereunder. This Terms & Conditions will be binding upon and inure to
            the benefit of the heirs, successors and permitted assignees of the
            parties.
            <br />
            <b>22. NOTICE</b>
            <br />
            India Legal App may provide notice to you - the Consumer, by e-mail
            to the e-mail address provided by you during registration, by a
            general notice on the India Legal App App. You may give notice to
            India Legal App at any time via email to{" "}
            <a href="legal@indialegalapp.com">legal@indialegalapp.com</a>
            <br />
            <b>23. ASSIGNMENT</b>
            <br />
            We may assign this contract at any time to any parent, subsidiary,
            or any affiliated company, or as part of the sale to, merger with,
            or other transfer of our company to another entity. We will post a
            notice on the App regarding any change of ownership so that you have
            the opportunity to discontinue your use of the App or cancel your
            registration if you do not wish to continue to use the App and the
            Services under the new ownership. You may not assign, transfer or
            sublicense these Terms & Conditions to anyone else and any attempt
            to do so in violation of this section shall be null and void.
            <br />
            <b>24. WAIVER AND SEVERABILITY OF TERMS</b>
            <br />
            Our failure to exercise or enforce any right or provision of this
            agreement shall not constitute a waiver of such right or provision.
            If any provision of the agreement is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavour to give effect to the parties' intentions as
            reflected in the provision, and the other provisions of the
            agreement shall remain in full force and effect.
            <br />
            <b>25. LAWYER / PROFESSIONAL ETHICS NOTICE</b>
            <br />
            f you are Lawyer / Professional, participating in any aspect of this
            App, you acknowledge that rules of professional conduct apply to all
            aspects of your participation and that you will abide by such rules.
            The rules include, but are not limited to, the rules relating to
            advertising, solicitation of clients, unauthorised practice of your
            respective profession, and misrepresentations of fact. We disclaim
            all responsibility for your compliance with these rules.
            <br />
            The section titles in this agreement are for convenience only and
            have no legal or contractual effect.
            <br />
            <b>26. PRIVACY</b>
            <br />
            India Legal App does its best to protect your privacy with respect
            to information not otherwise disclosed by you in questions submitted
            to India Legal App.
            <br />
            <br />
            In connection with the submission of Free Questions and Paid
            Consultations, we only require, and we encourage you to only
            provide, the minimum amount of personal identifying information
            necessary to process a question. All of your personal information
            provided to India Legal App, including email addresses, is concealed
            from all other Users of India Legal App and is never published as
            part of the India Legal App public database. Only the India Legal
            App administrators have access, for purposes of App administration
            only, to this information. India Legal App will not disclose this
            information to third parties without your permission, except in the
            following circumstances: (i) to protect ourselves from liability, or
            (ii) to respond to legal process or to otherwise comply with the
            law. With respect to certain information supplied by you, as
            explained further below, there will be a need for disclosure to
            Lawyers / Professionals when you have booked a paid consultation.
            <br />
            <br />
            Whether other information submitted to India Legal App by you, such
            as the contents of the Question itself, will be publicly disclosed
            will vary as between Free Questions and Paid Consultations.
            <br />
            <b>27. OWNERSHIP OF CONTENT</b>
            <br />
            By submitting a Free Question to India Legal App, you understand and
            agree that your question and any answer provided by Lawyer /
            Professional will become part of the India Legal App public database
            and the sole property of India Legal App.
            <br />
            <br />
            Thus, as to all Free Questions and any answers, you agree that,
            without any compensation to you, India Legal App will own all rights
            to the submitted questions and answers (including any intellectual
            property and moral rights) and will have the sole unrestricted right
            to use, reproduce, edit, modify, sell, adapt, publish, translate,
            create derivative works from, distribute, communicate to the public,
            perform and display the submitted content (in whole or in part)
            worldwide and/or to incorporate it in other works in any form,
            media, or technology now known or later developed, for the full term
            of any rights that may exist in the content.
            <br />
            <b>28. ENTIRE AGREEMENT</b>
            <br />
            These Terms & Conditions, together with the privacy policy, which
            shall be deemed to be a part hereof and incorporated herein by
            reference represent the complete agreement between you and India
            Legal App concerning the subject matter hereof, and it replaces all
            prior oral or written communications (if any) concerning such
            subject matter.
            <br />
            <br />
            The Terms & Conditions shall commence upon the date you use the App
            in any form. This Terms & Conditions and the licenses granted
            hereunder shall be terminated without notice in the event you (or
            any authorised person using your account) fail to comply with the
            Terms and Conditions of this Terms & Conditions or the rules for use
            of the India Legal App service promulgated by India Legal App from
            time to time. We may also cancel or suspend your registration for
            any other reason, including inactivity for an extended period, but
            will attempt to notify you in advance of such cancellation or
            suspension. Upon any termination, you will destroy all copies of
            India Legal App materials in your possession and cease any access to
            or use of the App. Upon this suspension or termination we may delete
            information, files, and other previously available content to you
            including but not limited to information provided by you. India
            Legal App shall not be liable to you or any third party for any
            termination of your access to the App and/or the Services.
            <br />
            <br />
            The provisions of this Terms & Conditions are severable, and in the
            event any provision hereof is determined to be invalid or
            unenforceable, such invalidity or unenforceability shall not affect
            the validity or enforceability of the remaining provisions, but such
            a provision shall be reformed, if reasonably possible, only to the
            extent necessary to make it enforceable.
            <br />
            <br />
            The failure of India Legal App to exercise or enforce any right or
            provision of these Terms & Conditions shall not constitute a waiver
            of such right or provision. The failure of either party to exercise
            in any respect any right provided for herein shall not be deemed a
            waiver of any further rights hereunder.
            <br />
            <br />
            Your affirmative act of using this App and/or registering for the
            App or the Services constitutes your electronic signature to these
            Terms & Conditions, Privacy Policy that shall be deemed to be a part
            hereof and incorporated herein by reference and your consent to
            enter into agreements with us electronically.
            <br />
            <br />
            I, the User/client
            <br />
            <br />
            Understand the risks and benefits of the online consultation
            <br />
            <br />
            Acknowledge and agree to assume the risk of the above-mentioned
            limitations pursuant to the use of India Legal App. I further
            understand and agree that no warranty or guarantee has been made to
            me concerning any consultation on particular problem, result,
            solution of my query / problem.
            <br />
            <br />
            I understand that the laws that protect privacy and the
            confidentiality of personal and / or Professional Services
            information also apply to India Legal App, and that my information
            will be only used as defined by the India Legal App’s Terms &
            Conditions and Privacy Statement.
            <br />
            <br />
            I understand that the consultation through India Legal App may
            involve electronic communication of my personal information to other
            Lawyers who may be located in other areas, including out of state.
            <br />
            <br />
            Notwithstanding User’s registration with National Do Not Call
            Registry (In Fully or Partly blocked category under National
            Customer Preference Register set up under Telecom Regulatory
            Authority of India), User hereby expresses his interest and accord
            its wilful consent to receive communication (including commercial
            communication) in relation to company name. User further confirms
            that any communication, as mentioned herein above, shall not be
            construed as Unsolicited Commercial Communication under the TRAI
            guidelines and User has specifically opted to receive communication
            in this regard on the telephone number provided by the User.
            <br />
            <br />
            <b>
              If you do not agree to our Terms & Conditions or our Privacy
              Policy we kindly request you REFRAIN USING this App and Website
            </b>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsCondition;

import { base_url } from "./globalVariable"
export const BASE_URL = base_url;
export const toastobj={
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    }
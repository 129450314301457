import React from "react";
import { Box, Typography } from "@mui/material";
import RightArrow from "../assets/image/rightArrow.svg";
import DocumentIcon from "../assets/logo/ra-DocumentCollection.svg";
import Service from "../assets/logo/Service.svg";
import Payment from "../assets/logo/payment.svg";
import Documents from "../assets/logo/required-documents.svg";
import Delivery from "../assets/logo/service-delivery.svg";


const HowItWorks = () => {
  const constantData = [
    {
      id: 1,
      name: "Select the Service",
      img: DocumentIcon,
    },
  ];
  return (
    <Box
      className="flexrow"
      sx={{
        alignItems: "center",
        justifyCentere: "center",
        gap: { lg: "32px", md: "28px", sm: "18px" },
        rowGap:'32px',
        flexWrap:{xs:"wrap", md:'noWrap'}
      }}

    >
      <Box className="flexcol" sx={{gap:"20px", width:{xs:'100%', sm:'auto'}}}>
        <Box sx={{backgroundColor:"rgba(247, 227, 227, 0.25)", width:"86px",height:"86px",borderRadius:"12px",display:"flex",alignItems:"center",justifyContent:"center",margin:"0 auto"}}>
          <img src={Service} alt="icon" />
        </Box>
        <Typography className="font500 p1">Select the Service</Typography>
      </Box>

      <Box sx={{ width: { lg: "32px", md: "28px", sm: "18px" }, display:{xs:'none',sm:'block'}}}>
        <img className="imageSize" src={RightArrow} alt="tick" />
      </Box>
      <Box className="flexcol" sx={{gap:"20px", width:{xs:'100%', sm:'auto'}}}>
        <Box sx={{backgroundColor:"rgba(247, 227, 227, 0.25)", width:"86px",height:"86px",borderRadius:"12px",display:"flex",alignItems:"center",justifyContent:"center",margin:"0 auto"}}>
          <img src={Payment} alt="icon" />
        </Box>
        <Typography className="font500 p1">Make the Payment</Typography>
      </Box>
      <Box sx={{ width: { lg: "32px", md: "28px", sm: "18px" }, display:{xs:'none',sm:'block'} }}>
        <img className="imageSize" src={RightArrow} alt="tick" />
      </Box>
      <Box className="flexcol" sx={{gap:"20px", width:{xs:'100%', sm:'auto'}}}>
        <Box sx={{backgroundColor:"rgba(247, 227, 227, 0.25)", width:"86px",height:"86px",borderRadius:"12px",display:"flex",alignItems:"center",justifyContent:"center",margin:"0 auto"}}>
          <img src={Documents} alt="icon" />
        </Box>
        <Typography className="font500 p1">
          Fill in the required details
        </Typography>
      </Box>
      <Box sx={{ width: { lg: "32px", md: "28px", sm: "18px" }, display:{xs:'none',sm:'block'} }}>
        <img className="imageSize" src={RightArrow} alt="tick" />
      </Box>
      <Box className="flexcol" sx={{gap:"20px", width:{xs:'100%', sm:'auto'}}}>
        <Box sx={{backgroundColor:"rgba(247, 227, 227, 0.25)", width:"86px",height:"86px",borderRadius:"12px",display:"flex",alignItems:"center",justifyContent:"center",margin:"0 auto"}}>
          <img src={Delivery} alt="icon" />
        </Box>
        <Typography className="font500 p1">
          Get your service delivered
        </Typography>
      </Box>
    </Box>
  );
};

export default HowItWorks;

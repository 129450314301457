import React, { useEffect, useState } from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import CommonInput from "../../component/commonInput";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Modal from "../../component/newModal.js";
import { postQuery } from "../../Shared/Services/UserService";
import InfoComponent from "./InfoComponent";
const ContactUs = () => {
  const userData = {
    name: "",
    email: "",
    mobile: "",
    query: "",
  };
  const [disable, setDisable] = useState(true);
  const [data, setData] = useState(userData);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!!data?.name && !!data?.email && !!data?.query) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [data]);

  const fetchData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const reg = new RegExp("^[0-9]+$");
    const val = reg.test(value);
    let check = ["mobile"].includes(name);
    if (check == true && val == false) {
      if (value === "") setData({ ...data, [name]: "" });
      return;
    } else setData({ ...data, [name]: value });
  };
  const handleSubmit = async () => {
    const params = { ...data };
    try {
      const resp = await postQuery(params);
      if (resp.code === 200) {
        setOpen(true);
      } else {
        // console.log(resp)
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <Box
      className="container"
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        mt: { md: "200px", sm: "160px", xs: "112px" },
        mb: { lg: "80px", md: "72px", sm: "64px", xs: "48px" },
        columnGap:{ lg: "175px", md: "145px", sm: "64px", xs: "48px" },
      }}
    >
      <Box
        // className="container"
        // maxWidth={{sm: "480px"}}
        sx={{
          maxWidth: { xs: "300px", sm: "320px", md: "400px", lg: "480px" },
          // ml: { sm: "20px", sm: "30px", md: "40px", lg: "100px", xl: "140px" },
        }}
      >
        <Box
          className="flexcol"
          sx={{
            rowGap: { lg: "72px", md: "64px", sm: "48px", xs: "32px" },
            mb: "40px",
            // alignItems: "center",
          }}
        >
          <Box
            className="flexcol"
            sx={{
              rowGap: "16px",
              //   alignItems: "center",
            }}
          >
            <Typography className="font700 bannerHeading">
              Contact Us
            </Typography>
            <Typography className="font400 p1">
              Feel Free to contact us any time. We will get back to as soon as
              we can!
            </Typography>
          </Box>
          <Box>
            <Box className="flexcol" sx={{ rowGap: "24px" }}>
              <Box className="flexcol" sx={{ gap: "5px" }}>
                <Typography className="font500 p2" sx={{ color: "#272828" }}>
                  {" "}
                  Name*{" "}
                </Typography>
                <CommonInput
                  // placeholder={"Name"}
                  value={data.name}
                  fieldName="name"
                  type="text"
                  onChange={fetchData}
                />
              </Box>
              <Box className="flexcol" sx={{ gap: "5px" }}>
                <Typography className="font500 p2" sx={{ color: "#272828" }}>
                  {" "}
                  Email*{" "}
                </Typography>
                <CommonInput
                  // placeholder={"Email"}
                  fieldName="email"
                  value={data.email}
                  type="text"
                  onChange={fetchData}
                />
              </Box>
              <Box className="flexcol" sx={{ gap: "5px" }}>
                <Typography className="font500 p2" sx={{ color: "#272828" }}>
                  {" "}
                  Mobile No{" "}
                </Typography>
                <CommonInput
                  // placeholder={"Mobile No"}
                  fieldName="mobile"
                  value={data.mobileNo}
                  type="text"
                  onChange={fetchData}
                  maxLength={10}
                />
              </Box>
              <Box className="flexcol" sx={{ gap: "5px" }}>
                <Typography className="font500 p2" sx={{ color: "#272828" }}>
                  {" "}
                  Your query*{" "}
                </Typography>
                <TextField
                  placeholder="Enter your query"
                  multiline
                  rows={5}
                  name="query"
                  value={data.query}
                  onChange={fetchData}
                  sx={{
                    border: " #B7B7B7",
                    borderRadius: "8px",
                    outline: "none",
                    width: "100%",
                    fontSize: { lg: "14px", sm: "12px", xs: "12px" },
                  }}
                  inputProps={{
                    style: {
                      fontFamily: "Mullish !important",
                      color: "#6C6C6C",
                      fontWeight: "font400",
                      fontSize: "16px",
                      lineHeight: "1.4",
                      padding: { lg: "14px", sm: "10px", xs: "8px" },
                    },
                  }}
                />
                {/* <textarea className='font400 p3'
                                    style={{
                                        border: "1px solid #B7B7B7",
                                        borderRadius: "8px",
                                        outline: "none",
                                        width: "100%",
                                        padding: "14px",
                                        fontSize: "16px",
                                        lineHeight: '1.4',
                                        minHeight: '140px'
                                    }}
                                    type='text'
                                    // placeholder='Enter your query'
                                    onChange={e => fetchData(e)}
                                /> */}
              </Box>

              <Button
                className="font700 flexrow"
                sx={{
                  backgroundColor: "#D12E29",
                  color: "#FFFFFF",
                  textTransform: "none",
                  height: { xs: "48px", sm: "36px", md: "48px" },
                  width: { xs: "175px", sm: "140px", md: "175px" },
                  lineHeight: "1.25",
                  fontSize: {
                    xs: "14px",
                    sm: "12px",
                    md: "16px",
                    lg: "16px",
                  },
                  justifyContent: "center",
                  alignItems: "cenyer",
                  "&:hover": {
                    backgroundColor: "#D12E29",
                    borderColor: "#349F6E",
                    color: "#349F6E",
                    border: "1px",
                  },
                }}
                disabled={disable}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
          {open ? (
            <Modal open={open} submitted={true} setOpen={setOpen} />
          ) : null}
        </Box>
      </Box>
      <Box>
        <InfoComponent />
      </Box>
    </Box>
  );
};

export default ContactUs;
